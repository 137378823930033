import styled, { css } from "styled-components";
import { getTransition } from "../../../Styles/utilites";

export const AccordionItem = styled.div`
  color: ${({ theme }) => theme.colors.font};
  text-align: center;
  border-radius: 0.5em;
  border: none;
  width: 100%;
  background: linear-gradient(90deg, #44312a 0%, #41412a 99.42%);
  margin-bottom: 1rem;
`;

type HeadingProps = {
  $isOpen: boolean;
};
export const Heading = styled.div<HeadingProps>`
  font-weight: 500;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: transparent;
  text-align: left;
  border: 0;
  border-radius: 0;
  ${({ $isOpen, theme }) =>
    $isOpen
      ? css`
          color: ${theme.colors.yellow};
        `
      : css`
          color: ${theme.colors.font};
        `};
  ${getTransition()};
  &:hover {
    color: ${({ theme }) => theme.colors.yellow};
    span > div {
      border-color: ${({ theme }) => theme.colors.yellow};
    }
    span > div::after,
    span > div::before {
      background-color: ${({ theme }) => theme.colors.yellow};
    }
  }
`;

export const PlusMinusContainer = styled.span`
  flex: 0 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and ${({ theme }) => theme.media.sm} {
    flex-basis: 2.5rem;
  }
`;

export const PlusMinus = styled.div<HeadingProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 1rem auto 0;
  background-color: ${({ theme }) => theme.colors.bgLayout};
  border: 2px solid
    ${({ $isOpen, theme }) =>
      $isOpen ? theme.colors.yellow : theme.colors.font};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  ${getTransition(300, "border-color")};

  &:after {
    position: absolute;
    content: "";
    background-color: ${({ $isOpen, theme }) =>
      $isOpen ? theme.colors.yellow : theme.colors.font};
    width: 8px;
    height: 2px;
    ${getTransition(300, ["background-color", "transform"])}
  }
  &:before {
    position: absolute;
    content: "";
    background-color: ${({ $isOpen, theme }) =>
      $isOpen ? theme.colors.yellow : theme.colors.font};
    width: 8px;
    height: 2px;
    ${({ $isOpen }) =>
      !$isOpen &&
      css`
        transform: rotate(-90deg);
      `}
    ${getTransition(300, ["background-color", "transform"])}
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    width: 32px;
    height: 32px;
    &:after {
      width: 10px;
    }
    &:before {
      width: 10px;
    }
  }
`;
type BodyContainerProps = {
  $isOpen: boolean;
  $height: number;
};
export const AccBodyContainer = styled.div<BodyContainerProps>`
  opacity: 0;
  visibility: hidden;
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      visibility: visible;
      opacity: 1;
    `};
  height: ${({ $height }) => $height}px;
  overflow: hidden;
  will-change: height;
  text-align: left;
  ${getTransition(300, ["height", "visibility", "opacity"])};
`;

export const BodyText = styled.div`
  padding: 0 1.25rem 0.75rem 3.8rem;
  font-size: 0.85rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    padding-left: 4.1rem;
  }
`;
