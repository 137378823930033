import * as S from "../../../pages/user/Profile/BottomContainer/Convert/ConfirmModal/Styles";
import { useMutation } from "@apollo/client";
import { ModalContainerProps } from "../../../pages/Marketplace/Help/ModalContainer/ModalContainer";
import { CANCEL_ORDER } from "../../../graphql/Mutation";
import { Modal } from "../../Modal/Modal";
import { ModalDescription, ModalHeading } from "../../../Styles/components";

export const ConfirmModal = ({
  isOpen,
  isShow,
  toggle,
  toggleParent
}: ModalContainerProps & { toggleParent: () => void }) => {
  const [cancel, { loading }] = useMutation(CANCEL_ORDER);
  const onConfirm = async () => {
    await cancel({
      onCompleted: (data) => {
        if (data.cancelOutput) {
          toggle();
          toggleParent();
        }
      }
    });
  };
  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        <ModalHeading>Подтверждение отмены</ModalHeading>
        <ModalDescription>
          Нам требуется ваше подтверждение для осуществления данного действия.
        </ModalDescription>
        <S.Text>Вы уверены, что хотите отменить заявку на вывод гемов?</S.Text>
        <S.BtnsContainer>
          <S.ConfirmBtn onClick={onConfirm} disabled={loading}>
            {loading ? "Загрузка" : "Подтвердить"}
          </S.ConfirmBtn>
          <S.CancelBtn onClick={toggle} disabled={loading}>
            Отменить
          </S.CancelBtn>
        </S.BtnsContainer>
      </S.Wrapper>
    </Modal>
  ) : null;
};
