import logo from "../../../../assets/imgs/greyLogo.png";
import title from "../../../../assets/imgs/banner/bantitle.png";
import * as S from "./Styles";
import { ReactComponent as R } from "../../../../assets/icons/R.svg";
import { Heading } from "./Styles";

// type HeadProps = {};
export const Head = () => {
  return (
    <S.Wrapper>
      <S.Heading>brawlween</S.Heading>
      <S.SubHeading>event</S.SubHeading>
    </S.Wrapper>
  );
};
