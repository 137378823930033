import styled from "styled-components";
import { getTransition } from "../../../Styles/utilites";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;
export const Container = styled.div`
  cursor: pointer;
  position: relative;
  user-select: none;
  min-height: 10rem;
  margin: 0 auto;
  width: 100%;
  //min-width: 15rem;
  &:after {
    z-index: ${({ theme }) => theme.order.three};
    position: absolute;
    content: "Купить";
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    font-size: 1.25rem;
    line-height: 1;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    background-color: hsla(${({ theme }) => theme.colors.revertValue}, 0.5);
    ${getTransition(300, ["opacity"])}
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
  @media screen and (min-width: 430px) {
    width: 15rem;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    width: 30%;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    max-width: 27%;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    max-width: 25%;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    width: 15.6666%;
  }
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.colors.revert} 0%,
    rgba(21, 55, 106, 0) 143.33%
  );
  border-radius: 2px;

  &:before {
    z-index: ${({ theme }) => theme.order.one};
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-image: radial-gradient(#6aff39 0%, transparent 65%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }

  ${getTransition(300, ["opacity"])}
`;

export const Price = styled.div`
  z-index: ${({ theme }) => theme.order.three};
  display: block;
  position: absolute;
  top: 0.2em;
  right: 0.6em;
  color: ${({ theme }) => theme.colors.orange};
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Gasoek One", sans-serif;
  -webkit-text-stroke: 2px ${({ theme }) => theme.colors.revert};
  text-shadow: 0 6px 0 ${({ theme }) => theme.colors.revert};
`;

export const ImgContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.order.two};
  width: 100%;
  height: 100%;
  img {
    transform: rotateZ(-10deg);
    min-height: 80px;
    height: auto;
    max-height: 80px;
    margin: 4.5em 0 2em;
  }
`;
