import * as S from "./Styles";
import bonus from "../../../../assets/imgs/nav/present.png";
import subjects from "../../../../assets/imgs/nav/items.png";
import market from "../../../../assets/imgs/nav/market.png";
import subjectsBg from "../../../../assets/imgs/nav/itemsBg.png";
import bonusBg from "../../../../assets/imgs/nav/presentBg.png";
import marketBg from "../../../../assets/imgs/nav/marketBg.png";
import { useNavigate } from "react-router-dom";
import { SignComponent } from "../../../SignComponent/SignComponent";

// type NavigationProps = {};

const items = [
  {
    to: "/bonus",
    img: bonus,
    bg: bonusBg,
    name: "БОНУСЫ"
  },
  {
    to: "/items",
    img: subjects,
    bg: subjectsBg,
    name: "ПРЕДМЕТЫ"
  },
  {
    to: "/marketplace",
    img: market,
    bg: marketBg,
    name: "ТОРГОВАЯ ПЛОЩАДКА"
  }
];
export const Navigation = () => {
  return (
    <S.Wrapper>
      <S.NavContainer>
        {items.map(({ to, img, name, bg }) => (
          <S.NavItem
            key={to}
            $bg={bg}
            //onClick={() => navigate(to)}
            data-testid={to}
            to={to}
          >
            <img src={img} alt={name} />
            <S.Text>{name}</S.Text>
          </S.NavItem>
        ))}
      </S.NavContainer>
      <S.SignContainer>
        <SignComponent />
      </S.SignContainer>
    </S.Wrapper>
  );
};
