import { ModalContainerProps } from "../../pages/Marketplace/Help/ModalContainer/ModalContainer";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription
} from "@apollo/client";
import { LISTEN_ORDER_STATUS } from "../../graphql/Subscription";
import { useFormInput } from "../../hooks/useFormInput";
import { Modal } from "../Modal/Modal";
import {
  FormInput,
  ModalDescription,
  ModalHeading,
  WrapperModal
} from "../../Styles/components";
import * as S from "./Styles";
import { GreenBtn } from "../../Styles/StyleComponents/GreenBtn/GreenBtn";
import { useEffect, useState } from "react";
import { SEND_OUTPUT_CODE } from "../../graphql/Mutation";
import { Relative } from "../../pages/Referral/TopContainer/Styles";
import { Loader } from "../Preloader/Loader/Loader";
import { GET_ME, GET_ORDER_STATUS, GET_ORDER_TIMER } from "../../graphql/Query";
import { useAppContext } from "../AppContext/AppContext";
import { ToastifyVars } from "../Toastify2/Toastify";
import { ErrorNotification } from "../InputModal/Main/Styles";
import Countdown from "react-countdown";
import { CountdownDisplayTimer } from "../CountdownDisplay/CountdownDisplay";
import { useToggleVisible } from "../../hooks/useToggleVisible";
import { ConfirmModal } from "./ConfirmModal/ConfirmModal";

export const OrderModal = ({
  isOpen,
  isShow,
  toggle,
  isPass
}: ModalContainerProps & { isPass?: boolean }) => {
  const [isOpenCancel, isShowCancel, toggleCancel] = useToggleVisible(300);
  const { pushToast } = useAppContext();
  const { data: me } = useQuery(GET_ME, {
    onCompleted: (data) => {
      if (data && data.getMe && data.getMe.orderStatus.status)
        setStatus(data.getMe.orderStatus.status);
    }
  });
  const [status, setStatus] = useState<null | string>(
    me?.getMe?.orderStatus.status || "unconfirmed"
  );
  useQuery(GET_ORDER_STATUS, {
    skip: !isOpen,
    pollInterval: 10_000,
    onCompleted: (data) => {
      console.log(data);
      if (data && data.checkStatusOutput && isOpen && isShow) {
        if (data.checkStatusOutput !== status) {
          setStatus(data.checkStatusOutput);
        }
      }
    }
  });

  useSubscription(LISTEN_ORDER_STATUS, {
    onData: (data) => {
      if (data && data.data && data.data.data) {
        const status = data.data.data.sourceStatus.status;
        if (!status) {
          toggle();
          setStatus(null);
        } else {
          setStatus(status);
        }
      }
    }
  });

  const onClose = () => {
    if (status === "unconfirmed" || status === "confirmed") {
      toggleCancel();
    } else pushToast(ToastifyVars.noClose);
  };

  // useEffect(() => {
  //   if (me && me.getMe && me.getMe.orderStatus.status !== status) {
  //     const status = !!me.getMe.orderStatus.status
  //       ? me.getMe.orderStatus.status
  //       : null;
  //     setStatus(status);
  //   }
  // }, [me]);
  return isOpen ? (
    <>
      <Modal isShow={isShow} onClose={onClose}>
        <WrapperModal>
          <ModalHeading>
            {isPass ? "Покупка Brawl Pass" : "Вывод гемов"}
          </ModalHeading>
          {status === "unconfirmed" && (
            <>
              <ModalDescription>
                Ваша заявка в очереди на исполнение, как только модератор
                подтвердит ее, уведомление появится в данном окне автоматически.
                Ожидайте, пожалуйста.
              </ModalDescription>
              <Loader isSmall={true} />
            </>
          )}
          {status === "confirmed" && (
            <ConfirmedComponent setStatus={setStatus} />
          )}
          {status === "getcode" && (
            <>
              <ModalDescription>
                Ваш код успешно отправлен. Наш модератор уже приступил к
                пополнению вашего игрового баланса, как только он завершит
                операцию, вы получите уведомление в данном окне. Ожидайте,
                пожалуйста.
              </ModalDescription>
              <Loader isSmall={true} />
            </>
          )}
          {status === "cancel" && (
            <>
              <ModalDescription>
                Ваша заявка отменена. Если вы не инициировали отмену заявки, то
                создайте новую через Торговую площадку сайта.
              </ModalDescription>
              <S.Content>
                <GreenBtn
                  $width100={false}
                  $notUpper={true}
                  $borderWidth={1}
                  onClick={toggle}
                >
                  Закрыть окно
                </GreenBtn>
              </S.Content>
            </>
          )}
          {status === "success" && (
            <>
              <ModalDescription>
                Ваша заявка успешно выполнена и закрыта.{" "}
                {isPass
                  ? "Brawl Pass уже доступен на вашем аккаунте"
                  : "На ваш игровой баланс уже поступили гемы"}
                . Приятной игры!
              </ModalDescription>
              <S.Content>
                <GreenBtn
                  $width100={false}
                  $notUpper={true}
                  $borderWidth={1}
                  onClick={toggle}
                >
                  Закрыть окно
                </GreenBtn>
              </S.Content>
            </>
          )}
        </WrapperModal>
      </Modal>
      <ConfirmModal
        isOpen={isOpenCancel}
        isShow={isShowCancel}
        toggle={toggleCancel}
        toggleParent={toggle}
      />
    </>
  ) : null;
};

type ConfirmedComponentProps = {
  setStatus: (arg: string) => void;
};
const ConfirmedComponent = ({ setStatus }: ConfirmedComponentProps) => {
  const [isError, setError] = useState(false);
  const [values, reset] = useFormInput({
    code: ""
  });

  const [timer, setTimer] = useState<number>(0);
  const [getTimer] = useLazyQuery(GET_ORDER_TIMER);
  const [sendCode, { loading }] = useMutation(SEND_OUTPUT_CODE, {
    onCompleted: (data) => {
      if (data.outputCode) {
        setStatus("getcode");
      } else {
        setError(true);
      }
    },
    onError: (err) => {
      console.log(err);
      setError(true);
    }
  });
  const onSend = async () => {
    if (!values.value.code) return;
    await sendCode({
      variables: {
        code: values.value.code
      }
    });
  };

  const getTime = () => {
    return timer > 0 ? timer : Date.now() + 1000 * 60 * 5;
  };

  useEffect(() => {
    getTimer().then((time) => {
      if (time.data) {
        setTimer(
          new Date(time.data.getOrderTimer).getTime() +
            60 * 60 * 1000 * 3 +
            1000 * 60 * 5
        );
      }
    });
  }, []);

  return (
    <>
      <ModalDescription>
        Ваша заявка успешно подтверждена, вам на почту отправлен код для доступа
        к аккаунту. Введите свой код до истечения времени таймера для
        продолжения совершения операции.
      </ModalDescription>
      <ModalDescription>Введите код до истечения таймера:</ModalDescription>
      <Countdown
        renderer={CountdownDisplayTimer}
        date={getTime()}
        onComplete={() => setStatus("cancel")}
      />
      <S.Content>
        <Relative>
          <FormInput
            name={"code"}
            id={"code"}
            placeholder={"Введите код из Email"}
            value={values.value.code}
            onChange={values.onChange}
          />
          <ErrorNotification $show={isError}>
            {isError && "Произошла непредвиденная ошибка, попробуйте позже"}
          </ErrorNotification>
        </Relative>
        <GreenBtn
          $width100={true}
          $notUpper={true}
          $borderWidth={1}
          onClick={onSend}
          disabled={loading || !values.value.code}
        >
          {loading ? "Загрузка" : "Подтвердить"}
        </GreenBtn>
      </S.Content>
    </>
  );
};
