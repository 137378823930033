import styled from "styled-components";
import bg from "../../../assets/imgs/hell/bg.png";

type WrapperProps = {
  $isEventPage?: boolean;
};
export const Wrapper = styled.div<WrapperProps>`
  display: ${({ $isEventPage }) => ($isEventPage ? "flex" : "none")};
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 2rem;
  background-image: url(${() => bg});
  background-color: rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: color;
  height: auto;
  @media screen and ${({ theme }) => theme.media.sm} {
    display: flex;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    padding: 1rem;
  }
`;
