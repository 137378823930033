import { User } from "./User";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ME, GET_USER, IS_LOGGED_IN } from "../../graphql/Query";
import { Loader } from "../../Components/Preloader/Loader/Loader";
import { Error404 } from "../404/Error404";
import { User as UserType } from "../../gql/graphql";
import { useLogout } from "../../hooks/useLogout";
import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import { Helmet } from "react-helmet-async";
import { useLayoutEffect } from "react";

//type UserContainerProps = {};
const UserContainer = () => {
  const { data: isAuth } = useQuery(IS_LOGGED_IN);
  const { id } = useParams();
  const logout = useLogout();
  const { data: meData, loading: meLoad } = useQuery(GET_ME, {
    onError: (err) => {
      if (err.message === "Unauthorized") {
        logout();
      }
    }
  });
  const { data, loading } = useQuery(GET_USER, {
    variables: {
      id: id!
    }
  });

  useLayoutEffect(() => {
    document.title = "Brawl Cases | Аккаунт";
  }, []);
  useReloadScrollTop();

  if (loading || meLoad) return <Loader />;
  if (!data || !data.getinfoUser) return <Error404 />;
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Brawl Cases | Аккаунт</title>
        <meta
          name="description"
          content="Личный кабинет сайта brawlcases.com. Пополнение баланса, вывод гемов."
        />
        <meta
          property="og:title"
          content="Личный кабинет. Аккаунт Brawl Cases."
        />
      </Helmet>
      <User
        isMine={
          !!isAuth?.isLoggedIn && !!meData?.getMe?.id && meData.getMe.id === id
        }
        currentUser={
          !!isAuth?.isLoggedIn && !!meData?.getMe?.id && meData.getMe.id === id
            ? ({
                ...meData.getMe,
                best_drop: data.getinfoUser.best_drop,
                favorite_case: data.getinfoUser.favorite_case
              } as UserType)
            : (data.getinfoUser as UserType)
        }
      />
    </>
  );
};

export default UserContainer;
