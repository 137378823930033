import { Modal } from "../../../../Components/Modal/Modal";
import * as S from "./Styles";
import { useState } from "react";
import { CloseButton } from "../../../user/Profile/BottomContainer/Output/OutputModal/Styles";
import { Choice } from "./Styles";

export type ModalContainerProps = {
  isOpen: boolean;
  isShow: boolean;
  toggle: () => void;
};

const rules = [
  "Выберите количество гемов, которое хотите вывести",
  "При наведении на гемы, появится надпись 'Вывести', при нажатии на гемы, откроется окно вывода",
  "Введите свой email и немного подождите, когда модератор примет вашу заявку",
  "Введите код с почты Supercell, чтобы модератор вошел на ваш аккаунт и купил нужное количество гемов",
  "После чего вы получите уведомление. Готово! Наслаждайтесь игрой"
];

const rulesTag = [
  "Выберите количество гемов, которое хотите вывести",
  "При наведении на гемы, появится надпись 'Вывести', при нажатии на гемы, откроется окно вывода",
  "Введите свой игровой тэг",
  "После успешной отправки заявки вы получите уведомление.",
  "Наш модератор в разумный срок отправит вам гемы. Готово! Наслаждайтесь игрой"
];
export const ModalContainer = ({
  isOpen,
  isShow,
  toggle
}: ModalContainerProps) => {
  const [type, setType] = useState("");
  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        <S.Heading>Торговая площадка</S.Heading>
        <S.Description>
          Внимательно изучите правила использования торговой площадки, перед
          совершением действий
        </S.Description>
        {type === "" && (
          <div>
            <S.Choice>Выберите способ вывода:</S.Choice>
            <div>
              <CloseButton onClick={() => setType("tag")}>
                По Тэгу BrawlStars
              </CloseButton>
            </div>
            <div>
              <CloseButton onClick={() => setType("email")}>
                По Email
              </CloseButton>
            </div>
          </div>
        )}
        {type === "tag" && (
          <>
            <S.Rules>
              {rulesTag.map((r, i) => (
                <li key={i}>{r}</li>
              ))}
            </S.Rules>
            <CloseButton onClick={() => setType("")}>Назад</CloseButton>
          </>
        )}
        {type === "email" && (
          <>
            <S.Rules>
              {rules.map((r, i) => (
                <li key={i}>{r}</li>
              ))}
            </S.Rules>
            <CloseButton onClick={() => setType("")}>Назад</CloseButton>
          </>
        )}
      </S.Wrapper>
    </Modal>
  ) : null;
};
