import styled, { css } from "styled-components";
import { getTransition } from "../../../Styles/utilites";

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`;
export const Payments = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-bottom: 1rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin-bottom: 2rem;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    margin-top: 1rem;
  }
`;

type PaymentProps = {
  $isActive?: boolean;
};
export const Payment = styled.li<PaymentProps>`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
  height: 4rem;
  border-radius: 1rem;
  img {
    transform: scale(0.8);
  }
  border: 1px solid ${({ theme }) => theme.colors.grey58};
  background-color: ${({ $isActive, theme }) =>
    $isActive ? 'rgb(20,20,32)' : "rgba(24, 28, 40, 1)"};
  &:hover {
    background-color: ${({ theme }) => 'rgb(20,20,32)'};
  }
  ${getTransition()}
  @media screen and ${({ theme }) => theme.media.sm} {
    padding: 1.5rem;
    width: 100%;
    height: 4rem;
    img {
      transform: scale(1);
    }
  }
`;

export const TextPayment = styled.div`
  font-size: 1rem;
  margin-left: 1rem;
`;

export const PayType = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  padding: 2px 6px;
  font-size: 0.65rem;
  line-height: 1;
  color: ${({ theme }) => theme.colors.font};
  background: hsl(0, 0%, 9%);
  border-radius: 0.35rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    top: 7px;
    right: 7px;
    padding: 5px 9px;
    font-size: 0.65rem;
  }
`;

export const Text = styled.div`
  margin-top: 1rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin: 1rem 2rem 0;
  }
`;
export const InputSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.font};
  font-weight: bold;
  text-align: center;
  border-radius: 0.5em;
  border: 1px solid ${({ theme }) => theme.colors.grey45};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  //width: 100%;
  max-width: 30px;
  padding: 0.5em 1.5em;
  background: transparent;
`;

type InputProps = {
  $isPrice?: boolean;
};
export const InputPromo = styled.input<InputProps>`
  color: ${({ theme }) => theme.colors.font};
  font-weight: bold;
  text-align: center;
  border-radius: 0.5em;
  border: 1px solid ${({ theme }) => theme.colors.grey45};

  padding: 0.5em 1.5em;
  background: transparent;
  width: 100%;
  ${({ $isPrice }) =>
    $isPrice &&
    css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    `}
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  &:focus-visible {
    outline: none;
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.grey37};
    ~ svg {
      opacity: 1;
      transform: scale(0.8);
      cursor: initial;
      path {
        fill: ${({ theme }) => theme.colors.greenGrad};
      }
      &:hover {
        opacity: 1;
        transform: scale(0.8);
      }
    }
  }
  ${getTransition()}
`;

type NotificationProps = {
  $show: boolean;
  $isLight?: boolean;
};
export const Notification = styled.div<NotificationProps>`
  position: absolute;
  bottom: -1.5rem;
  font-weight: bold;
  font-size: 0.85rem;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  width: 100%;
  ${({ $isLight }) =>
    $isLight &&
    css`
      color: ${({ theme }) => theme.colors.grey58};
    `}

  ${getTransition(300, "opacity")}
`;
export const ErrorNotification = styled(Notification)`
  color: ${({ theme }) => theme.colors.red};
`;

export const AgreementCheck = styled.div`
  align-self: flex-start;
  line-height: 1.1;
  margin: 2rem 0 1rem;
  margin-left: 1px;
  font-size: 0.9rem;
  &:hover {
    span {
      box-shadow: 0 0 2px 2px ${({ theme }) => theme.colors.yellow};
    }
  }
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  input {
    display: none;
  }
  button {
    text-decoration: underline;
    background: transparent;
    padding: 0;
    border: none;
    color: ${({ theme }) => theme.colors.orange};
    font-weight: 400;
    ${getTransition()}
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: 1rem;
    line-height: 1;
    margin-top: 2rem;
  }
`;

type CheckMarkProps = {
  $isSelected: boolean;
};
export const Checkmark = styled.span<CheckMarkProps>`
  height: 16px;
  width: 16px;
  display: inline-block;
  position: relative;
  background-color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.orange : "transparent"};
  border: 1px solid
    ${({ theme, $isSelected }) =>
      $isSelected ? theme.colors.orange : theme.colors.grey58};
  border-radius: 0.2em;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.85rem;
  }
  &:hover {
    box-shadow: 0 0 3px 2px ${({ theme }) => theme.colors.yellow};
  }
  ${getTransition(300, ["box-shadow", "background-color", "border-color"])}
`;

export const FlexDivider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  @media screen and ${({ theme }) => theme.media.sm} {
    flex-direction: row;
  }
`;
export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
`;
export const ConfirmBtn = styled.button`
  color: ${({ theme }) => theme.colors.font};
  font-weight: bold;
  text-align: center;
  border-radius: 0.5em;
  border: none;
  width: 100%;
  padding: 0.5em 1.5em;
  background-color: ${({ theme }) => theme.colors.greenGrad};
  &:hover {
    opacity: 0.6;
  }
  &:disabled {
    opacity: 0.6;
  }
  ${getTransition(300, "opacity")}
`;
