import { Section } from "./Section/Section";
import * as S from "./Styles";
import { ReactComponent as AutoNav } from "../../../assets/icons/casenav/autoNav.svg";
import { ReactComponent as ComboNav } from "../../../assets/icons/casenav/comboNav.svg";
import { ReactComponent as GemNav } from "../../../assets/icons/casenav/gemNav.svg";
import { ReactComponent as LegendNav } from "../../../assets/icons/casenav/legendNav.svg";
import { ReactComponent as LevelNav } from "../../../assets/icons/casenav/levelNav.svg";
import { ReactComponent as PersNav } from "../../../assets/icons/casenav/persNav.svg";
import { ReactComponent as TrofNav } from "../../../assets/icons/casenav/trofNav.svg";
import { useQuery } from "@apollo/client";
import { GET_ALL_CASES } from "../../../graphql/Query";
import { Loader } from "../../../Components/Preloader/Loader/Loader";
import { Error404 } from "../../404/Error404";
import * as React from "react";
import _ from "lodash";
import { Case } from "../../../gql/graphql";
import test from "../../../assets/imgs/test-stand.svg";
import { useEffect } from "react";

// type CasesProps = {};

const withouFreeCases = [
  {
    img: <PersNav />,
    title: "Кейсы персонажей",
    to: "persCases",
    type: "pers"
  },
  {
    img: <LevelNav />,
    title: "Кейсы уровней",
    to: "levelsCases",
    type: "levels"
  },
  {
    img: <LegendNav />,
    title: "Легендарные кейсы",
    to: "legendsCases",
    type: "legend"
  },
  {
    img: <ComboNav />,
    title: "Сборки кейсов",
    to: "combineCases",
    type: "combine"
  },
  {
    img: <AutoNav />,
    title: "Авто кейсы",
    to: "autoCases",
    type: "auto"
  },
  {
    img: <TrofNav />,
    title: "Трофейные кейсы",
    to: "trofCases",
    type: "trof"
  },
  {
    img: <GemNav />,
    title: "Гем кейсы",
    to: "gemCases",
    type: "gem"
  }
];

export const Cases = () => {
  const { data, loading, error } = useQuery(GET_ALL_CASES, {
    fetchPolicy: "cache-first"
  });

  // useEffect(() => {
  //   if (!data) return;
  //   console.log(data);
  // }, [data]);


  if (loading) return <Loader />;
  if (!data || !data.getallCase.length) return <Error404 />;
  if (!!error)
    return (
      <S.Wrapper>
        Произошла ошибкапри попытке получения данных: {error.message}
      </S.Wrapper>
    );

  return (
    <S.Wrapper>
      <Section
        id={"snowCases"}
        heading={"Event"}
        cases={data.getallCase.filter(({ type }) => type === "snow") as Case[]}
      />
      <Section
        id={"freeCases"}
        heading={"Бесплатные кейсы"}
        cases={data.getallCase.filter(({ type }) => type === "free") as Case[]}
      />
      <S.MainNav>
        {withouFreeCases.map(({ to, img, title }) => (
          <S.NavItem key={to}>
            <a href={"#" + to}>
              {img} {title}
            </a>
          </S.NavItem>
        ))}
      </S.MainNav>
      <S.ListContainer>
        {_.chain(data.getallCase)
          .filter(({ type }) => type !== "free" && type !== "snow")
          .groupBy("type")
          .values()
          .value()
          .map((arr, i) => ({
            ...withouFreeCases[i],
            cases: arr as Case[]
          }))
          .map(({ cases, title, to }, i) =>
            to === "combineCases" ? (
              <li key={to + i}>
                <Section id={to} heading={title} cases={cases.slice(0, 5)} />
                <Section
                  id={to + "farm"}
                  heading={""}
                  cases={cases.slice(5, 7)}
                />
              </li>
            ) : (
              <li key={to + i}>
                <Section id={to} heading={title} cases={cases} />
              </li>
            )
          )}
      </S.ListContainer>
    </S.Wrapper>
  );
};
