import pass from "../../../assets/imgs/gems/brawlpass.png";
import * as S from "./Styles";
import { GreenBtn } from "../../../Styles/StyleComponents/GreenBtn/GreenBtn";
import { ModalContainer } from "../GemCase/ModalContainer/ModalContainer";
import { useQuery } from "@apollo/client";
import { GET_ME, IS_LOGGED_IN } from "../../../graphql/Query";
import { useAppContext } from "../../../Components/AppContext/AppContext";
import { useToggleVisible } from "../../../hooks/useToggleVisible";
import { useTimeout } from "../../../hooks/useTimeout";
import { ToastifyVars } from "../../../Components/Toastify2/Toastify";
import { OrderModal } from "../../../Components/OrderModal/OrderModal";

const passObj = {
  id: "passBrawl",
  img: pass,
  price: 809
};
export const BrawlPass = () => {
  const { data } = useQuery(IS_LOGGED_IN);
  const { data: me } = useQuery(GET_ME);
  const { pushToast } = useAppContext();
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  const [isOpenOrder, isShowOrder, toggleOrder] = useToggleVisible(300);
  const [set] = useTimeout(toggleOrder, 310);
  const onClick = () => {
    pushToast(ToastifyVars.noOut);
    return;
    // const hours = new Date().getUTCHours();
    // if (
    //   (hours < 9 || hours > 16) &&
    //   me &&
    //   me.getMe &&
    //   me.getMe.id !== "1" &&
    //   me.getMe.id !== "5"
    // )
    //   pushToast(ToastifyVars.outClose);
    // else if (!data?.isLoggedIn || !me || !me.getMe)
    //   pushToast(ToastifyVars.noAuth);
    // else if (me.getMe.balance < passObj.price) pushToast(ToastifyVars.noMoney);
    // else {
    //   toggle();
    // }
  };
  return (
    <>
      <S.Wrapper>
        <S.Container onClick={onClick}>
          <S.Divider>
            <S.Price>Brawl Pass</S.Price>
            <S.ImgContainer>
              <img
                src={passObj.img}
                alt={"brawl pass билет"}
                loading={"lazy"}
                decoding={"async"}
              />
            </S.ImgContainer>
            <GreenBtn size={"small"} $isMarket={true} $width100={true}>
              {passObj.price},00 ₽
            </GreenBtn>
          </S.Divider>
        </S.Container>
      </S.Wrapper>
      <ModalContainer
        isOpen={isOpen}
        isShow={isShow}
        toggle={toggle}
        toggleOrder={set}
        gemCount={1}
        isPass={true}
      />
      <OrderModal
        isShow={isShowOrder}
        isOpen={isOpenOrder}
        toggle={toggleOrder}
        isPass={true}
      />
    </>
  );
};
