import { Route, Routes } from "react-router-dom";
import { Main } from "./Main/Main";
import { Error404 } from "./404/Error404";
import { Case } from "./Case/Case";
import Items from "./Items/Items";
import Marketplace from "./Marketplace/Marketplace";
import { Referral } from "./Referral/Referral";
import Bonus from "./Bonus/Bonus";
import { Agreement } from "./agreement/Agreement";
import Faq from "./faq/Faq";
import UserContainer from "./user/UserContainer";
import { Partners } from "./Partners/Partners";
import { Navigate } from "react-router-dom";
import { Login } from "./Login/Login";
import { Guarantees } from "./Guarantees/Guarantees";
import { Event } from "./Event/Event";
export const Pages = () => {
  const main = <Main />;
  const caseComp = <Case />;
  const items = <Items />;
  const marketplace = <Marketplace />;
  const user = <UserContainer />;
  const referral = <Referral />;
  const bonus = <Bonus />;
  const agreement = <Agreement />;
  const faq = <Faq />;
  const partners = <Partners />;
  const login = <Login />;
  const guarantees = <Guarantees />;
  const event = <Event />;
  const error404 = <Error404 />;
  return (
    <Routes>
      <Route index={true} path={"/"} element={main} />
      <Route path={"/case/:id"} element={caseComp} />
      <Route path={"/items"} element={items} />
      <Route path={"/marketplace"} element={marketplace} />
      <Route path={"/user/:id"} element={user} />
      <Route path={"/referral"} element={referral} />
      <Route path={"/bonus"} element={bonus} />
      <Route path={"/agreement"} element={agreement} />
      <Route path={"/contacts"} element={agreement} />
      <Route path={"/offer"} element={agreement} />
      <Route path={"/privacy-policy"} element={agreement} />
      <Route path={"/faq"} element={faq} />
      <Route path={"/partners"} element={partners} />
      <Route path={"/guarantees"} element={guarantees} />
      <Route path={"/authorization/login"} element={login} />
      <Route path={"/event"} element={event} />
      <Route path={"/404"} element={error404} />
      <Route path={"/*"} element={noMatch} />
    </Routes>
  );
};

const NoMatch = () => {
  return <Navigate to={"/404"} replace state={{ status: 404 }} />;
};

const noMatch = <NoMatch />;
