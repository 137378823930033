import styled from "styled-components";

export const Wrapper = styled.div`
  text-align: center;
  padding: 1rem;
  @media screen and ${({ theme }) => theme.media.md} {
    padding: 0;
  }
`;

export const Heading = styled.div`
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 31.612812042236328px #ffa53d,
    0px 0px 40px rgba(255, 165, 61, 0.5), 0px 0px 442.5793762207031px #ffa53d;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.7);
  font-family: "Graffi Think";
  background: -webkit-linear-gradient(90deg, #fa3c00 0%, #fab400 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0;
  @media screen and ${({ theme }) => theme.media.xl} {
    font-size: 6.25rem;
  }
`;

export const SubHeading = styled.h5`
  color: #fff;
  font-family: Graffi Think;
  text-shadow: 0px 0px 31.612812042236328px #ffa53d,
    0px 0px 40px rgba(255, 165, 61, 0.5), 0px 0px 442.5793762207031px #ffa53d;
  //   0px 0px 758.70751953125px #ffa53d, 0px 0px 1327.7381591796875px #ffa53d;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.7);
  background: -webkit-linear-gradient(90deg, #fa0000 0%, #fa1d00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;

  @media screen and ${({ theme }) => theme.media.xl} {
    font-size: 3rem;
    margin-top: -0.5rem;
    line-height: 1;
  }
`;
