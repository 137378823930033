//IP
// export const admin =
//   "ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ БЕЛЯКОВ КОНСТАНТИН ВАДИМОВИЧ";
// export const urAddress =
//   "170026, РОССИЯ, ТВЕРСКАЯ ОБЛ, Г ТВЕРЬ, УЛ МУСОРГСКОГО, Д 17, КВ 7";
// export const inn = "695208886126";
// export const ogrn = "323695200003511";
// export const raschet = "40802810500004196329";
// export const bank = "АО ТИНЬКОФФ БАНК";
// export const bankInn = "7710140679";
// export const bankBik = "044525974";
// export const bankCoresp = "30101810145250000974";
// export const bankUrAddress =
//   "Москва, 127287, ул. Хуторская 2-я, д. 38А, стр. 26";

//OOO
export const admin = "ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ MRK GROUP";
export const urAddress =
  "170100, РОССИЯ, ТВЕРСКАЯ ОБЛ, Г ТВЕРЬ, УЛ АНДРЕЯ ДЕМЕНТЬЕВА, Д 5Б, ОФИС 1";
export const inn = "6900003460";
export const ogrn = "1236900013074";
export const raschet = "40702810310001538034";
export const bank = "АО ТИНЬКОФФ БАНК";
export const bankInn = "7710140679";
export const bankBik = "044525974";
export const bankCoresp = "30101810145250000974";
export const bankUrAddress =
  "Москва, 127287, ул. Хуторская 2-я, д. 38А, стр. 26";
