import { PropsWithChildren, useEffect, useState } from "react";
import { createContainer, Portal } from "../Portal/Portal";
import { Close, FullScreenModalWrapper } from "../../Styles/components";
import * as S from "./Styles";
import { useLockBodyScroll } from "../../hooks/useLockBodyScroll";
import { FaRegTimesCircle } from "react-icons/fa";

const MODAL_CONTAINER_ID = "modal-container-id";

type ModalProps = {
  onClose?: () => void;
  isShow: boolean;
  specialStyles?: boolean;
};
export const Modal = ({
  onClose,
  isShow,
  specialStyles,
  children
}: PropsWithChildren<ModalProps>) => {
  const [isMounted, setMounted] = useState(false);

  useLockBodyScroll();

  useEffect(() => {
    createContainer({ id: MODAL_CONTAINER_ID });
    setMounted(true);
  }, []);

  useEffect(() => {
    const handleEscapePress = (event: KeyboardEvent) => {
      if (event.key === "Escape" && onClose) {
        onClose();
      }
    };
    window.addEventListener("keydown", handleEscapePress);
    return () => {
      window.removeEventListener("keydown", handleEscapePress);
    };
  }, [onClose]);

  return isMounted ? (
    <Portal id={MODAL_CONTAINER_ID}>
      <FullScreenModalWrapper
        onClick={onClose}
        $isShow={isShow}
        data-testid={"wrap"}
      >
        <S.Content
          onClick={(e) => e.stopPropagation()}
          $isShow={isShow}
          $specialStyles={specialStyles}
          data-testid={"modal-content"}
        >
          <Close onClick={onClose}>
            <FaRegTimesCircle />
          </Close>
          <div>{children}</div>
        </S.Content>
      </FullScreenModalWrapper>
    </Portal>
  ) : null;
};
