import * as S from "./Styles";
import { CardVars } from "./Styles";
import { Link } from "react-router-dom";
import { memo } from "react";

type ItemProps = {
  type: S.CardVars;
  img: string;
  price: number;
  name: string;
  caseImg?: string;
  user?: {
    id: string;
    name: string;
  };
  isWrap?: boolean;
  isWin?: boolean;
  isFast?: boolean;
  isDrop?: boolean;
  isSide?: boolean;
  isNext?: boolean;
  idx?: number;
  speed?: number;
};
export const Item = ({
  type,
  name,
  img,
  price,
  caseImg,
  user,
  isWrap,
  isWin,
  isFast,
  isDrop,
  isSide,
  isNext,
  idx
}: ItemProps) => {
  //useEffect(() => console.log("item rerender"));
  return (
    <S.Wrapper
      type={type}
      $isWrap={isWrap}
      $isWin={isWin}
      $isFast={isFast}
      $isDrop={isDrop}
      $isSide={isSide}
      $isNext={isNext}
      $idx={idx}
      // speed={speed}
      data-testid={"sidebarItem"}
    >
      {user ? (
        <Link to={`/user/${user?.id}`}>
          {type !== CardVars.snow && (
            <S.Price $isWrap={isWrap}>{price}₽</S.Price>
          )}
          <S.Container $isWrap={isWrap}>
            <img src={img} alt={name} decoding="async" />
            {caseImg && (
              <S.CaseImg src={caseImg} alt={"Кейс"} decoding="async" />
            )}
            <S.Span>
              <S.Name $isWrap={isWrap}>{name}</S.Name>
              <S.UserName>{user.name}</S.UserName>
            </S.Span>
          </S.Container>
        </Link>
      ) : (
        <>
          {type !== CardVars.snow && (
            <S.Price $isWrap={isWrap}>{price}₽</S.Price>
          )}
          <S.Container $isWrap={isWrap}>
            <img src={img} alt={name} decoding="async" />
            {caseImg && (
              <S.CaseImg src={caseImg} alt={"Кейс"} decoding="async" />
            )}
            <S.Span>
              <S.Name $isWrap={isWrap}>{name}</S.Name>
            </S.Span>
          </S.Container>
        </>
      )}
    </S.Wrapper>
  );
};

export const PureItem = memo(Item, () => true);
