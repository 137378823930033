import { GreenBtn } from "../../../../Styles/StyleComponents/GreenBtn/GreenBtn";
import * as S from "./Styles";
import { Case } from "../../../../gql/graphql";

type SectionProps = {
  id: string;
  heading: string;
  cases: Case[];
};
export const Section = ({ heading, id, cases }: SectionProps) => {
  return (
    <S.Wrapper id={id} $isSpecial={heading === ""}>
      {heading !== "" && (
        <S.Heading $isSpecial={id === "snowCases"}>{heading}</S.Heading>
      )}
      <S.CasesContainer>
        {cases.map(({ id: caseId, image, name, price }) => (
          <S.CaseElt
            key={caseId}
            // onClick={() => navigate(`case/${id}`)}
            to={`case/${caseId}`}
            $isSolo={cases.length === 1}
            $isSpecial={id === "snowCases"}
          >
            <img
              height={"200px"}
              width={"200px"}
              src={process.env.REACT_APP_URI + image}
              alt={name}
              loading={"lazy"}
              decoding="async"
            />
            <S.Name>{name}</S.Name>
            <GreenBtn $isSpecial={id === "snowCases"} size={"small"}>
              {price} ₽
            </GreenBtn>
          </S.CaseElt>
        ))}
      </S.CasesContainer>
    </S.Wrapper>
  );
};
