import styled from "styled-components";

export const Wrapper = styled.div`
  text-align: center;
  margin: 2rem 0.5rem;
  max-height: 85vh;
  overflow-y: scroll;
  @media screen and ${({ theme }) => theme.media.sm} {
    max-height: 100vh;
    overflow: visible;
  }
`;
