import { ModalHeading } from "../../../Styles/components";
import * as S from "./Styles";
import { FaArrowLeft } from "react-icons/fa";
import { PrivacyBody } from "../../../pages/agreement/PrivacyBody/PrivacyBody";

type AgreementProps = {
  back: () => void;
};
export const Agreement = ({ back }: AgreementProps) => {
  return (
    <>
      <ModalHeading>Политика конфиденциальности</ModalHeading>
      <S.Back onClick={back}>
        <FaArrowLeft />
        Назад
      </S.Back>

      <S.Content>
        <PrivacyBody />
      </S.Content>
    </>
  );
};
