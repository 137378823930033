import * as S from "./Styles";
import present from "../../assets/imgs/hell/present.png";
import { useQuery } from "@apollo/client";
import { GET_EVENT_SUM, GET_ME, IS_LOGGED_IN } from "../../graphql/Query";
import { Loader } from "../../Components/Preloader/Loader/Loader";
import { useToggleVisible } from "../../hooks/useToggleVisible";
import { InputModal } from "../../Components/InputModal/InputModal";
import { useState } from "react";
import snow from "../../assets/imgs/snow/snow-img.png";
// import snowSolo from "../../assets/imgs/snow/snow.png";

import pumpkin from "../../assets/imgs/hell/pumpkin.png";
import { PrizeModal } from "./PrizeModal/PrizeModal";

const payTask = [
  {
    sum: 100,
    description: (
      <S.Text>
        Увеличь шансы <S.BlueText>x1.5</S.BlueText>
      </S.Text>
    )
  },
  {
    sum: 200,
    description: (
      <S.Text>
        Увеличь шансы <S.BlueText>x2</S.BlueText>
      </S.Text>
    )
  },
  {
    sum: 300,
    description: (
      <S.Text>
        Получить <S.BlueText>тыкву</S.BlueText>
      </S.Text>
    )
  }
];
const snowTasks = [
  {
    count: 3,
    description: (
      <S.Text>
        <S.BlueText>100 ₽</S.BlueText> на баланс
      </S.Text>
    )
  },
  {
    count: 5,
    description: (
      <S.Text>
        шанс <S.BlueText>x2</S.BlueText> на 24 часа
      </S.Text>
    )
  },
  {
    count: 20,
    description: (
      <S.Text>
        золотой <S.BlueText>леон</S.BlueText>
      </S.Text>
    )
  },
  {
    count: 25,
    description: (
      <S.Text>
        <S.BlueText>10 000 ₽</S.BlueText>
      </S.Text>
    )
  }
];
export const Second = () => {
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  const [isOpenPrize, isShowPrize, togglePrize] = useToggleVisible(300);
  const [snowCount, setSnowCount] = useState<number>();
  const [isLeftActive, setLeftActive] = useState(true);
  const { data: isAuth } = useQuery(IS_LOGGED_IN);
  const { data: me } = useQuery(GET_ME);
  const { data, loading } = useQuery(GET_EVENT_SUM, {
    fetchPolicy: "cache-and-network"
  });

  const onClick = async (count: number) => {
    setSnowCount(count);
    togglePrize();
  };

  return (
    <S.Content id={"market"}>
      <S.Heading>Увеличь свои шансы</S.Heading>
      <S.SubHeading>
        Пополняй баланс и увеличь шансы на выпадение снежинки
      </S.SubHeading>
      <S.SecondDivider>
        <S.Quests>
          <S.LeftQuest
            $isActive={isLeftActive}
            onClick={() => setLeftActive(true)}
          >
            {/*<img src={snow} alt={"Снег"} />*/}
            квесты пополнения
          </S.LeftQuest>
          <S.RightQuest
            $isActive={!isLeftActive}
            onClick={() => setLeftActive(false)}
          >
            {/*<img src={snow} alt={"Снег"} />*/}
            магазин ивента
          </S.RightQuest>
        </S.Quests>
      </S.SecondDivider>
      {isLeftActive ? (
        <S.IncomesDivider>
          {loading ? (
            <Loader isSmall={true} />
          ) : data ? (
            payTask.map(({ sum, description }) => (
              <S.Income key={sum}>
                <S.IncomeSnow>
                  {/*<img src={snow} alt={"Снег"} />*/}
                </S.IncomeSnow>
                <S.LeftFlexDiv $isMarket={true}>
                  <S.Present>
                    <img src={present} alt={"Подарок"} />
                  </S.Present>
                  <S.RowFlex>
                    <S.TextDivider>
                      <S.Text>
                        Пополнить на <S.R>₽</S.R>
                      </S.Text>
                      <S.Count $isLight={!isAuth?.isLoggedIn}>
                        {isAuth?.isLoggedIn ? data.getSumPaymentsPumpkin : 0}
                        .00/
                        {sum}.00
                      </S.Count>
                    </S.TextDivider>
                    <S.FlexProgress>{description}</S.FlexProgress>
                  </S.RowFlex>
                </S.LeftFlexDiv>
                <S.PayButton
                  onClick={toggle}
                  disabled={
                    !isAuth?.isLoggedIn || sum <= data.getSumPaymentsPumpkin
                  }
                >
                  {isAuth?.isLoggedIn && sum <= data.getSumPaymentsPumpkin
                    ? "выполнено"
                    : "пополнить"}
                </S.PayButton>
              </S.Income>
            ))
          ) : (
            <div>Ошибка при получении данных.</div>
          )}
        </S.IncomesDivider>
      ) : (
        <S.IncomesDivider>
          {loading ? (
            <Loader isSmall={true} />
          ) : data ? (
            snowTasks.map(({ count, description }) => (
              <S.Income key={count}>
                <S.IncomeSnow>
                  {/*<img src={snow} alt={"Снег"} />*/}
                </S.IncomeSnow>
                <S.LeftFlexDiv $isMarket={true}>
                  <S.Present>
                    <img src={present} alt={"Подарок"} />
                  </S.Present>
                  <S.RowFlex>
                    <S.TextDivider $mobileRow={true} $isMarket={true}>
                      <img src={pumpkin} alt={"Снежинка"} />
                      <S.Text style={{ marginRight: "0.35rem" }}>
                        X{count} =
                      </S.Text>
                      {description}
                    </S.TextDivider>
                    <S.FlexProgress>
                      <S.Text>{me && me.getMe ? me.getMe.snow : 0}</S.Text>
                      <S.Progress>
                        <S.Bar
                          $width={Math.min(
                            (me && me.getMe ? me.getMe.snow : 0) / count,
                            1
                          )}
                        />
                      </S.Progress>
                      <S.Text>{count}</S.Text>
                    </S.FlexProgress>
                  </S.RowFlex>
                </S.LeftFlexDiv>
                <S.PayButton
                  $isMarket={true}
                  onClick={() => onClick(count)}
                  disabled={
                    !isAuth?.isLoggedIn ||
                    (me && me.getMe ? me.getMe.snow : 0) < count
                  }
                >
                  Получить
                </S.PayButton>
              </S.Income>
            ))
          ) : (
            <div>Ошибка при получении данных.</div>
          )}
        </S.IncomesDivider>
      )}

      <InputModal isOpen={isOpen} isShow={isShow} toggle={toggle} />
      <PrizeModal
        isOpen={isOpenPrize}
        isShow={isShowPrize}
        toggle={togglePrize}
        count={snowCount}
      />
    </S.Content>
  );
};
