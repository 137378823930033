import styled from "styled-components";

type ContentProps = {
  $height?: number;
};
export const Content = styled.div<ContentProps>`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: ${({ $height }) => ($height ? $height : 10)}rem;
  overflow-y: scroll;
  margin-top: 1rem;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    height: ${({ $height }) => ($height ? $height : 12)}rem;
  }
`;
