import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import {
  PageHeading,
  PageMarginSection,
  PageWrapper
} from "../../Styles/components";
import * as S from "./Styles";
import { Helmet } from "react-helmet-async";
import { useLayoutEffect } from "react";

export const Partners = () => {
  useLayoutEffect(() => {
    document.title = "Brawl Cases | Партнерство";
  }, []);
  useReloadScrollTop();
  return (
    <PageWrapper>
      <Helmet prioritizeSeoTags>
        <title>Brawl Cases | Партнерство</title>
        <meta
          name="description"
          content="Реклама для блогеров, сотрудничество и партнерство по вопросам продвижения и рекламы."
        />
        <link rel="canonical" href="https://brawlcases.com/partners" />
        <meta
          property="og:title"
          content="Сотрудничество по бравл старс. Лучшие условия партнерства brawl stars."
        />
      </Helmet>
      <PageMarginSection>
        <PageHeading>Партнерство</PageHeading>
        <S.Wrapper>
          <S.TextDivider>
            Вы можете обратится по вопросам сотрудничества к нам по почте:
          </S.TextDivider>
          <S.Link href={"mailto:support@brawlcases.com"}>
            support@brawlcases.com
          </S.Link>
          <S.TextDivider>Или через Телеграмм:</S.TextDivider>
          <S.Link href={"https://t.me/Pam_s0"}>Telegram</S.Link>
        </S.Wrapper>
      </PageMarginSection>
    </PageWrapper>
  );
};
