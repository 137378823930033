import { ModalContainerProps } from "../../../../../Marketplace/Help/ModalContainer/ModalContainer";
import { Modal } from "../../../../../../Components/Modal/Modal";
import * as S from "../../Convert/ConfirmModal/Styles";
import * as C from "../../../../../../Components/InputModal/Main/Styles";
import { ErrorNotification } from "../../../../../../Components/InputModal/Main/Styles";
import {
  ModalDescription,
  ModalHeading
} from "../../../../../../Styles/components";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import {
  ButtonDivider,
  CardContainer,
  CloseButton,
  Content,
  MaxButton
} from "./Styles";
import { useFormInput } from "../../../../../../hooks/useFormInput";

import { GreenBtn } from "../../../../../../Styles/StyleComponents/GreenBtn/GreenBtn";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ME } from "../../../../../../graphql/Query";
import { MAKE_PAYOUT } from "../../../../../../graphql/Mutation";
import { Loader } from "../../../../../../Components/Preloader/Loader/Loader";
import {
  Messages,
  validation as getValidation
} from "../../../../../../functions/validation";

const MIN_SUM = 50;
const MAX_SUM = 15_000;
const FEES = 0.02;

const getCount = (num: string) => {
  const str = num.toString();
  const chars = [...str];
  const strWithSpaces = chars.reduce((acc, char, i) => {
    const spaceOrNothing = i % 4 === 0 ? " " : "";
    return acc + spaceOrNothing + char;
  }, "");

  return strWithSpaces[0] === " " ? strWithSpaces.slice(1) : strWithSpaces;
};

export const OutputModal = ({
  isOpen,
  isShow,
  toggle
}: ModalContainerProps) => {
  const { data } = useQuery(GET_ME);
  const [values, reset, hardSet] = useFormInput({
    card: "",
    price: ""
  });
  const [validMessages, setValidMessages] = useState<Messages | null>(null);
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState<string | null>(null);
  const [send, { loading, client }] = useMutation(MAKE_PAYOUT);

  const validation = useMemo(
    () =>
      getValidation({
        optional: []
      }),
    []
  );

  const onType = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.value.replace(/\s/g, "").length > 16 ||
      /\D/.test(e.target.value.replace(/\s/g, ""))
    )
      return;
    e.target.value = getCount(e.target.value.replace(/\s/g, ""));
    values.onChange(e);
  };

  const onMax = () => {
    if (!data || !data.getMe) return;
    const max =
      data.getMe.ref_balance - Math.ceil(data.getMe.ref_balance * FEES);
    hardSet("price", (max > MAX_SUM ? MAX_SUM : max).toString());
  };

  const onSend = async () => {
    if (
      !data ||
      !data.getMe ||
      !values.value.price ||
      Number(values.value.price) < MIN_SUM ||
      Number(values.value.price) > MAX_SUM ||
      loading ||
      !values.value.card
    )
      return;
    if (
      Number(values.value.price) >
      data.getMe.ref_balance - Math.ceil(data.getMe.ref_balance * FEES)
    ) {
      setError("Недостаточно средств, с учетом транзакционной комиссии.");
      return;
    }
    const valid = validation({
      card: values.value.card
    });
    setValidMessages(valid);
    if (!valid.isValid) return;
    await send({
      variables: {
        card: values.value.card,
        sum: values.value.price
      },
      onError: (err) => {
        console.log(err);
        setError(
          "Ваша заявка на вывод завершилась с ошибкой. Проверьте введенные данные."
        );
      },
      onCompleted: (data1) => {
        if (data1.makePayout) {
          setSuccess(true);
          if (!data || !data.getMe) return;
          const newData = {
            getMe: {
              ...data.getMe,
              ref_balance: data.getMe.ref_balance - Number(values.value.price)
            }
          };
          client.writeQuery({ query: GET_ME, data: newData });
        } else {
          console.log("return false");
          setError(
            "Ваша заявка на вывод завершилась с ошибкой. Проверьте введенные данные."
          );
        }
      }
    });
  };

  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        <ModalHeading>Вывод реферального баланса</ModalHeading>
        {isSuccess ? (
          <>
            <ModalDescription>Заявка успешно отправлена.</ModalDescription>
            <Content>
              <div>
                Ваша заявка нами получена. Когда она будет обработана, средства
                будут зачислены на ваш счет. Ожидайте, пожалуйста.
              </div>
              <div style={{ marginTop: "2rem" }}>
                <GreenBtn
                  $width100={false}
                  $notUpper={true}
                  $borderWidth={1}
                  onClick={() => {
                    toggle();
                    reset();
                    setError(null);
                    setSuccess(false);
                  }}
                >
                  Хорошо
                </GreenBtn>
              </div>
            </Content>
          </>
        ) : (
          <>
            <ModalDescription>
              Укажите реквизиты и сумму для вывода вашего реферального баланса.
            </ModalDescription>
            <Content>
              <C.InputContainer style={{ marginBottom: "2rem" }}>
                <C.InputPromo
                  name={"card"}
                  id={"card"}
                  type={"text"}
                  placeholder={"Номер карты"}
                  value={values.value.card}
                  onChange={onType}
                />
                {validMessages &&
                  validMessages.card &&
                  !validMessages.isValid && (
                    <ErrorNotification
                      $show={
                        !!validMessages &&
                        !!validMessages.card &&
                        !validMessages.isValid
                      }
                    >
                      {validMessages.card}
                    </ErrorNotification>
                  )}
              </C.InputContainer>
              <C.InputContainer>
                <C.InputSpan>₽</C.InputSpan>
                <C.InputPromo
                  name={"price"}
                  id={"price"}
                  type={"number"}
                  placeholder={"Сумма вывода"}
                  value={values.value.price}
                  onChange={values.onChange}
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", "."].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  $isPrice={true}
                />
                <MaxButton onClick={onMax}>МАКС</MaxButton>

                {isError ? (
                  <ErrorNotification $show={true}>{isError}</ErrorNotification>
                ) : (
                  <C.Notification $show={true} $isLight={true}>
                    <div>
                      Вывод от {MIN_SUM}₽. Комиссия {FEES * 100}%
                    </div>
                  </C.Notification>
                )}
              </C.InputContainer>
              <ButtonDivider>
                <GreenBtn
                  $width100={true}
                  $notUpper={true}
                  $borderWidth={1}
                  disabled={
                    !data ||
                    !data.getMe ||
                    !values.value.price ||
                    Number(values.value.price) < MIN_SUM ||
                    Number(values.value.price) > MAX_SUM ||
                    loading
                  }
                  onClick={onSend}
                >
                  {loading ? "Загрузка" : "Подтвердить"}
                </GreenBtn>
                <CloseButton
                  disabled={loading}
                  onClick={() => {
                    reset();
                    setError(null);
                    toggle();
                  }}
                >
                  Сбросить данные и закрыть
                </CloseButton>
              </ButtonDivider>
            </Content>
          </>
        )}
      </S.Wrapper>
    </Modal>
  ) : null;
};

const YukassaOutputModal = ({
  isOpen,
  isShow,
  toggle
}: ModalContainerProps) => {
  const { data } = useQuery(GET_ME);
  const [values, reset, hardSet] = useFormInput({
    payout_token: "",
    price: ""
  });
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  const [isLoading, setLoading] = useState(true);
  const [errorPayout, setErrorPayout] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState<string | null>(null);
  const [send, { loading, client }] = useMutation(MAKE_PAYOUT);

  const onMax = () => {
    if (!data || !data.getMe) return;
    const max =
      data.getMe.ref_balance - Math.ceil(data.getMe.ref_balance * FEES);
    hardSet("price", (max > MAX_SUM ? MAX_SUM : max).toString());
  };

  const onSend = async () => {
    if (
      !data ||
      !data.getMe ||
      !values.value.price ||
      Number(values.value.price) < MIN_SUM ||
      Number(values.value.price) > MAX_SUM ||
      loading ||
      !values.value.payout_token
    )
      return;
    if (
      Number(values.value.price) >
      data.getMe.ref_balance - Math.ceil(data.getMe.ref_balance * FEES)
    ) {
      setError("Недостаточно средств, с учетом транзакционной комиссии.");
      return;
    }
    console.log(values.value);
    await send({
      variables: {
        card: values.value.payout_token,
        sum: values.value.price
      },
      onError: (err) => {
        console.log(err);
        setError(
          "Ваша заявка на вывод завершилась с ошибкой. Проверьте введенные данные."
        );
      },
      onCompleted: (data1) => {
        if (data1.makePayout) {
          setSuccess(true);
          if (!data || !data.getMe) return;
          const newData = {
            getMe: {
              ...data.getMe,
              ref_balance: data.getMe.ref_balance - Number(values.value.price)
            }
          };
          client.writeQuery({ query: GET_ME, data: newData });
        } else {
          console.log("return false");
          setError(
            "Ваша заявка на вывод завершилась с ошибкой. Проверьте введенные данные."
          );
        }
      }
    });
  };

  useEffect(() => {
    if (!ref.current) return;
    //Инициализация виджета. Все параметры обязательные.
    //@ts-ignore
    const payoutsData = new window.PayoutsData({
      type: "payout",
      account_id: "504517", //Идентификатор шлюза (agentId в личном кабинете)
      customization: {
        //Настройка цветовой схемы, минимум один параметр, значения цветов в HEX
        colors: {
          control_primary: "#04B401", // Базовый цвет кнопки Дальше и других акцентных элементов
          control_primary_content: "#FFF",
          background: "#35245A"
        }
      },
      success_callback(data: any) {
        console.log(data);
        hardSet("payout_token", data["payout_token"]);
        //Обработка ответа с токеном карты
      },
      error_callback(error: any) {
        console.log(error);
        setErrorPayout(true);
        setError(
          "Ваша заявка на вывод завершилась с ошибкой. Проверьте введенные данные."
        );
        //Обработка ошибок инициализации
      },
      lang: "ru_RU"
    });

    //Отображение формы в контейнере
    payoutsData
      .render("payout-form")
      //Метод возвращает Promise, исполнение которого говорит о полной загрузке формы сбора данных (можно не использовать).
      .then(() => {
        console.log("form loaded");
        setLoading(false);
        //Код, который нужно выполнить после отображения формы.
      });
  }, [ref.current]);

  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        <ModalHeading>Вывод реферального баланса</ModalHeading>
        {isSuccess ? (
          <>
            <ModalDescription>Заявка успешно отправлена.</ModalDescription>
            <Content>
              <div>
                Ваша заявка нами получена. Когда она будет обработана, средства
                будут зачислены на ваш счет. Ожидайте, пожалуйста.
              </div>
              <div style={{ marginTop: "2rem" }}>
                <GreenBtn
                  $width100={false}
                  $notUpper={true}
                  $borderWidth={1}
                  onClick={() => {
                    toggle();
                    reset();
                    setError(null);
                    setSuccess(false);
                    setLoading(true);
                  }}
                >
                  Хорошо
                </GreenBtn>
              </div>
            </Content>
          </>
        ) : (
          <>
            <ModalDescription>
              Укажите реквизиты и сумму для вывода вашего реферального баланса.
            </ModalDescription>
            {values.value.payout_token ? (
              <Content>
                <C.InputContainer>
                  <C.InputSpan>₽</C.InputSpan>
                  <C.InputPromo
                    name={"price"}
                    id={"price"}
                    type={"number"}
                    placeholder={"Сумма вывода"}
                    value={values.value.price}
                    onChange={values.onChange}
                    $isPrice={true}
                  />
                  <MaxButton onClick={onMax}>МАКС</MaxButton>

                  {isError ? (
                    <ErrorNotification $show={true}>
                      {isError}
                    </ErrorNotification>
                  ) : (
                    <C.Notification $show={true} $isLight={true}>
                      <div>
                        Вывод от {MIN_SUM}₽. Комиссия {FEES * 100}%
                      </div>
                    </C.Notification>
                  )}
                </C.InputContainer>
                <ButtonDivider>
                  <GreenBtn
                    $width100={true}
                    $notUpper={true}
                    $borderWidth={1}
                    disabled={
                      !data ||
                      !data.getMe ||
                      !values.value.price ||
                      Number(values.value.price) < MIN_SUM ||
                      Number(values.value.price) > MAX_SUM ||
                      loading
                    }
                    onClick={onSend}
                  >
                    {loading ? "Загрузка" : "Подтвердить"}
                  </GreenBtn>
                  <CloseButton
                    disabled={loading}
                    onClick={() => {
                      reset();
                      setError(null);
                      toggle();
                      setLoading(true);
                    }}
                  >
                    Сбросить данные и закрыть
                  </CloseButton>
                </ButtonDivider>
              </Content>
            ) : errorPayout ? (
              <CardContainer>
                <div style={{ marginBottom: "2rem" }}>{isError}</div>
                <div>
                  <GreenBtn
                    $width100={false}
                    $notUpper={true}
                    $borderWidth={1}
                    onClick={() => {
                      toggle();
                      reset();
                      setError(null);
                      setErrorPayout(false);
                      setLoading(true);
                    }}
                  >
                    Закрыть
                  </GreenBtn>
                </div>
              </CardContainer>
            ) : (
              <>
                <CardContainer>
                  {isLoading && <Loader isSmall={true} />}
                  <div id="payout-form" ref={ref} />
                </CardContainer>
              </>
            )}
          </>
        )}
      </S.Wrapper>
    </Modal>
  ) : null;
};
