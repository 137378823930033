import * as S from "../Styles";
import first from "../../../assets/imgs/snow/first.png";
// import second from "../../../assets/imgs/snow/second.png";
import third from "../../../assets/imgs/snow/3.png";

import second from "../../../assets/imgs/hell/2.png";

const items = [
  {
    img: first,
    content:
      "Открывайте мистические Хэллоуинские кейсы с ярким оранжевым ценником, чтобы выбить Бравл Тыквы"
  },
  {
    img: second,
    content:
      "Пополняйте свой баланс в разделе ивента, чтобы удвоить свои шансы на редкую Бравл Тыкву."
  },
  {
    img: third,
    content:
      "Собрав 3 Бравл Тыквы, вы автоматически становитесь обладателем 10.000₽ или эксклюзивного золотого Эль Примо на вашем аккаунте!"
  }
];
export const First = () => {
  return (
    <S.Content>
      <S.Heading>Готовы к захватывающему Хэллоуинскому приключению?
      </S.Heading>
      <S.SubHeading>
        Не упустите возможность окунуться в атмосферу магии и удачи!
      </S.SubHeading>
      <S.FirstDivider>
        {items.map(({ img, content }, i) => (
          <S.Item key={i}>
            <S.ImgContainer>
              <img src={img} alt={"Бонусы в честь нового года"} />
            </S.ImgContainer>
            <S.ItemText>{content}</S.ItemText>
          </S.Item>
        ))}
      </S.FirstDivider>
    </S.Content>
  );
};
