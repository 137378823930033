import * as S from "./Styles";
import rightPers from "../../../../assets/imgs/hell/rightPers.png";
import leftPers from "../../../../assets/imgs/hell/leftPers.png";
import { useQuery } from "@apollo/client";
import { GET_ME, IS_LOGGED_IN } from "../../../../graphql/Query";
import { Pumpkin } from "../Pumpkin/Pumpkin";
import { GetMeQuery } from "../../../../gql/graphql";

type FooterProps = {
  me?: GetMeQuery;
  isEventPage?: boolean;
};
export const Footer = ({ isEventPage, me }: FooterProps) => {
  const { data } = useQuery(IS_LOGGED_IN);

  return (
    <S.Wrapper $isEventPage={isEventPage}>
      {!isEventPage && (
        <S.Relative>
          <S.Button to={"/event"}>Страница ивента</S.Button>
          {data?.isLoggedIn && me && me.getMe && (
            <S.PumpkinWrapper>
              <Pumpkin pumpkinCount={me.getMe.pumpkin} />
            </S.PumpkinWrapper>
          )}
        </S.Relative>
      )}

      <S.RightPerson
        src={rightPers}
        alt={"Right Person"}
        $isEventPage={isEventPage}
      />
      <S.LeftPerson
        src={leftPers}
        alt={"Left Person"}
        $isEventPage={isEventPage}
      />
    </S.Wrapper>
  );
};
