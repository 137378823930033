import * as S from "./Styles";
import { FirstPanel } from "./FirstPanel/FirstPanel";
import { SecondPanel } from "./SecondPanel/SecondPanel";
import TopSiteLinkContainer from "./TopSiteLink/TopSiteLinkContainer";

export const Footer = () => {
    return (
        <S.Footer>
            <hr />
            <S.Wrapper>
                <FirstPanel />
                <SecondPanel />
                <TopSiteLinkContainer />
            </S.Wrapper>
            <S.Text>
                INTERNATIONAL BUSINESS SYSTEMS S.R.L.<br/>
                Reg. number:3-102-693823 Beneficiary’s address:
                SAN JOSE-SANTA ANA,<br/>THREE HUNDRED AND FIFTY METERS OF THE RESTAURANT CEVICHE DEL REY, COSTA-RICA
            </S.Text>
        </S.Footer>
    );
};