import { Accordion, Items } from "../../../Components/Accordion/Accordion";
import * as S from "./Styles";

// type QuestionsProps = {};
export const items: Items = [
  {
    title: "Как получить бонус за подписку?",
    body: "Выберите соц.сеть и нажмите на кнопку подписаться. Вы будете перенаправлены на нашу страницу в соц.сети, далее подпишитесь на наш канал или группу и вы сразу получите бонус на сайте."
  },
  {
    title: "Как получить бесплатный бонусный кейс?",
    body: "Выполните условия для получения бесплатного бонусного кейса, указанные на странице кейса. Вы можете открыть неограниченное количество бесплатных кейсов!"
  },
  {
    title: "Как мне получить бонус за приглашение?",
    body: "Бонусы начисляются автоматически, когда приглашенный вами пользователь пополняет баланс на сайте. Вы можете после использовать их по своему усмотрению — вывести на карту или воспользоваться ими на нашем сайте."
  },
  {
    title: "Где мне взять промокод?",
    body: "Подписывайтесь на наши группы в соц.сетях, там регулярно мы выкладываем новые промокоды с большими бонусами! А также получайте промокоды от наших партнеров."
  }
];
export const Questions = () => {
  return (
    <S.Wrapper>
      <S.Title>Вопросы по бонусам</S.Title>
      <S.Container>
        <Accordion items={items} />
      </S.Container>
    </S.Wrapper>
  );
};
