import React, { useEffect } from "react";
import GlobalStyles from "./Styles/global";
import { Layout } from "./Components/Layout/Layout";
import { Pages } from "./pages";
import { AppContextProvider } from "./Components/AppContext/AppContext";
import { Preloader } from "./Components/Preloader/Preloader";
import src from "./assets/imgs/logo480.png";
import { v4 } from "uuid";
import { useMutation } from "@apollo/client";
import { SEND_USER_DATA } from "./graphql/Mutation";

function App() {
  const [sendData] = useMutation(SEND_USER_DATA, {
    onCompleted: (data) => console.log(data)
  });
  const getIp = async () => {
    const data = await fetch("https://api.ipify.org?format=json");
    const res = await data.json();
    return res.ip as string;
  };
  useEffect(() => {
    let clientId = localStorage.getItem("clientId");
    if (!clientId) {
      const id = v4();
      clientId = id;
      localStorage.setItem("clientId", id);
    }
    let clientIp: string;
    getIp()
      .then((ip) => {
        //console.log(ip);
        clientIp = ip;
        localStorage.setItem("clientIp", ip);
      })
      .then(() => {
        //console.log(clientId, clientIp);
        sendData({
          variables: {
            clientId: clientId as string,
            clientIp: clientIp as string
          }
        });
      });
  }, []);

  return (
    <div>
      <GlobalStyles />
      <Preloader />
      <AppContextProvider>
        <img src={src} alt={"logoCached"} style={{ display: "none" }} />
        <Layout>
          <Pages />
        </Layout>
      </AppContextProvider>
    </div>
  );
}

export default App;
