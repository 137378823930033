import { ITheme } from "../interfaces/styled";

export const baseTheme: ITheme = {
  colors: {
    secretGradLeft: "hsl(0,52%,51%)", //#C24040
    secret: "hsl(0,80%,61%)", //#EB4B4B
    gold: "hsl(51,95%,48%)", //#F0CB06
    goldGradLeft: "hsl(51,95%,41%)", //#CAAB05
    items: "hsl(163,57%,38%)", //#2A997A
    itemsGradLeft: "hsl(163,92%,26%)", //#05805E
    darklyBlue: "hsl(201,46%,42%)", //#3A7A9D
    simple: "hsl(217,80%,50%)", //#1B6AE5
    simpleGradLeft: "hsl(216,68%,64%)", //#6596E2
    brightBlue: "hsl(245,99%,55%)", //#2C19FE
    brightBlueValue: "245,99%,55%", //#2C19FE
    epic: "hsl(262,100%,64%)", //#8947FF
    epicGradLeft: "hsl(261,72%,45%)", //#5C20C8
    brightPurple: "hsl(302,87%,34%)", //#A30B9D
    brightPurpleValue: "302,87%,34%",

    red: "hsl(351,100%,54%)", //#FF1337
    brown: "hsl(15,35%,23%)", //#4F3026
    brightOrange: "hsl(17,99%,55%)", //#FE5819
    orange: "hsl(29,88%,56%)", //#F28C2C
    orangeValue: "29,88%,56%",
    yellow: "hsl(43,91%,57%)", //#F5BC2D
    yellowValue: "43,91%,57%",
    gems: "hsl(84,100%,71%)", //#C4FF6C
    border: "hsl(162,100%,50%)", //#00FFB2
    greenGrad: "hsl(119,99%,35%)", //#04B401
    fillBtn: "hsl(183,61%,26%)", //#1A696D
    tgColor: "hsl(209,72%,62%)", //#57A0E4

    grey37: "hsl(0, 0%, 37%)", //#5E5E5E
    grey37Value: "0, 0%, 37%",
    grey45: "hsl(0,0%,45%)", //#737373
    grey58: "hsl(0,0%,58%)", //#959595
    grey85: "hsl(0,0%,85%)", //#D9D9D9
    bgLayout: "hsl(225,25%,9%)", //#1E222F новый - #12151E
    bgLayoutValue: "225,25%,9%", //#1E222F
    // bgColor: "hsl(259,42%,25%)", //#35245A
    bgColor: "hsl(225,25%,15%)", //#35245A
    font: "hsl(0, 0%, 99%)",
    fontValue: "0, 0%, 99%",
    revert: "hsl(0, 0%, 0%)",
    revertValue: "0, 0%, 0%"
  },
  media: {
    xxl: "(min-width: 1400px)",
    xl: "(min-width: 1200px)",
    lg: "(min-width: 992px)",
    md: "(min-width: 768px)",
    sm: "(min-width: 576px)",
    minAnimate: "(prefers-reduced-motion: reduce)"
  },
  durations: {},
  order: {
    minus: -1,
    one: 1,
    two: 2,
    three: 3,
    fixed: 20,
    height: 1000,
    action: 1100
  },
  opacity: {
    main: 0.66
  }
};
