import { graphql } from "../gql/gql";

export type IsLoggedIn = {
  isLoggedIn: boolean;
};
export const IS_LOGGED_IN = graphql(`
  query IsLoggedIn @client {
    isLoggedIn
  }
`);

export const GET_ALL_CASES = graphql(`
  query GetAllCases {
    getallCase {
      ...CaseFragment
    }
  }
`);

export const GET_CASE = graphql(`
  query GetCase($id: ID!) {
    case(id: $id) {
      ...CaseFragment
      items {
        ...ItemFragment
      }
    }
  }
`);

export const GET_SUM_TO_OPEN_CASE = graphql(`
  query GetSumToOpenCase {
    getSumOpenedCases
  }
`);

export const GET_ALL_ITEMS = graphql(`
  query GetAllItems {
    getallItems {
      ...ItemFragment
    }
  }
`);

export const GET_TOTAL_PRICE = graphql(`
  query GetTotalPrice {
    getAllPrice
  }
`);

export const CaseFragment = graphql(`
  fragment CaseFragment on Case {
    id
    price
    name
    type
    image
  }
`);
export const ItemFragment = graphql(`
  fragment ItemFragment on CardData {
    id
    price
    name
    type
    image
  }
`);
export const UserFragment = graphql(`
  fragment UserFragment on User {
    id
    first_name
    avatar
  }
`);
export const GET_ME = graphql(`
  query GetMe {
    getMe {
      ...UserFragment
      ref_balance
      invite_code
      balance
      bonus_one {
        vk
        youtube
        discord
        tiktok
        telegram
        elPrimo
      }
      timer {
        first_case
        second_case
        third_case
        fourth_case
      }
      isOld
      isDoubleProbTimer
      bonus500
      isPayment
      orderStatus {
        status
      }
      snow
      pumpkin
    }
  }
`);

export const GET_ORDER_STATUS = graphql(`
  query GetOrderStatus {
    checkStatusOutput
  }
`);

export const GET_USER = graphql(`
  query GetUser($id: ID!) {
    getinfoUser(id: $id) {
      ...UserFragment
      best_drop {
        ...ItemFragment
      }
      favorite_case {
        ...CaseFragment
      }
    }
  }
`);

export const GET_USER_ITEMS = graphql(`
  query GetUserItems($id: ID!, $cursor: Int) {
    getItemsUser(id: $id, cursor: $cursor) {
      cursor
      hasNextPage
      items {
        ...ItemFragment
      }
    }
  }
`);

export const GET_USER_COLLECTION = graphql(`
  query GetCollection($id: ID!) {
    uniqueItems(id: $id)
  }
`);

export const GET_LAST_ITEMS = graphql(`
  query GetLastItems($flow: String!) {
    latestItems(flow: $flow) {
      date
      item {
        ...ItemFragment
      }
      case {
        image
      }
      user {
        id
        first_name
      }
    }
  }
`);

export const GET_ORDER_TIMER = graphql(`
  query GetOrderTimer {
    getOrderTimer
  }
`);

export const GET_NEWS = graphql(`
  query GetNews {
    getNews {
      id
      date
      title
      description
      link
      linkName
    }
  }
`);

export const GET_STATS = graphql(`
  query GetStats {
    statistic {
      cases
      gems
      online
      users
    }
  }
`);

export const GET_PROMOTION = graphql(`
  query GetPromotion {
    getPromotion
  }
`);

export const GET_MY_REFS = graphql(`
  query GetMyRefs {
    myReff {
      firstname
      get
      in
    }
  }
`);

export const GET_EVENT_SUM = graphql(`
  query GetEventSum {
    getSumPaymentsPumpkin
  }
`);
