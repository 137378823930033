import styled from "styled-components";

export const Payments = styled.ul`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-top: 3rem;
  max-width: 700px;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin-top: 1rem;
  }
`;

export const PayElt = styled.li`
  margin-top: 1.5rem;
  margin-right: 2rem;
`;
