import { useQuery } from "@apollo/client";
import { IS_LOGGED_IN } from "../../graphql/Query";
import { useNavigate } from "react-router-dom";
import { useEffect, useLayoutEffect } from "react";
import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import {
  PageHeading,
  PageMarginSection,
  PageWrapper
} from "../../Styles/components";
import { Helmet } from "react-helmet-async";
import { Form } from "./Form/Form";

export const Login = () => {
  const { data } = useQuery(IS_LOGGED_IN);
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.isLoggedIn) navigate("/");
  }, [data]);
  useReloadScrollTop();

  return (
    <PageWrapper>
      <Helmet prioritizeSeoTags>
        <title>Brawl Cases | Вход в личный кабинет</title>
        <meta
          name="description"
          content="Лучший сайт по открытию кейсов бравл старс."
        />
        <meta
          property="og:title"
          content="Лучший сайт по открытию кейсов бравл старс."
        />
      </Helmet>
      <PageMarginSection>
        <PageHeading>Вход в личный кабинет</PageHeading>
        <Form />
      </PageMarginSection>
    </PageWrapper>
  );
};
