import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { getTransition } from "../../Styles/utilites";
import snow from "../../assets/imgs/snow/snow-img.png";

export const Wrapper = styled.section`
  text-align: center;
  background: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.bgLayout} 98%,
    transparent 100%
  );
  margin: 0;
  padding: 4rem 2rem 8rem;
  // @media screen and ${({ theme }) => theme.media.xl} {
  //   padding: 4rem 6rem 8rem;
  // }
  // @media screen and ${({ theme }) => theme.media.xxl} {
  //   padding: 4rem 10rem 8rem;
  // }
`;

export const SpecialWrapper = styled.div`
  position: relative;
  z-index: 5;
  margin-top: -6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

type SpecialContainerProps = {
  $justifyCenter?: boolean;
};
export const SpecialContainer = styled.div<SpecialContainerProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ $justifyCenter }) =>
    $justifyCenter ? "center" : "space-between"};

  background-color: ${({ theme }) => theme.colors.bgLayout};
  border: 1px solid #F2442C;
  border-image: linear-gradient(-45deg, #0085ff, #0085ff26) 30;

  padding: 1rem;
  width: 90vw;
  > div {
    margin-bottom: 0;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
    > div {
      margin-bottom: 0.5rem;
    }
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    width: 70vw;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    width: 60vw;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    width: 48vw;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    width: 45vw;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    width: 36vw;
  }
`;

export const Button = styled.button`
  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  border: none;
  font-size: 0.85rem;
  // border-image: linear-gradient(90deg, #f2442c, #fad200) 20;
  background: linear-gradient(to right, #F2442C, #FAD200);
  border-radius: 0.5rem;
  padding: 0.5rem 2rem;
  color: ${({ theme }) => theme.colors.font};
  font-weight: 800;
  &:disabled {
    color: #b1ada8;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    padding: 0.5rem 3rem;
  }
`;

export const Content = styled.div`
  margin-top: 2rem;
  @media screen and ${({ theme }) => theme.media.md} {
    margin-top: 4rem;
  }
`;
export const Heading = styled.h2`
  font-size: 1rem;
  color: #FFA53D;
  font-weight: 600;
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: 1.5rem;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    font-size: 2rem;
  }
`;

export const SubHeading = styled.div`
  font-size: 0.85rem;
  font-weight: 400;
  margin-top: 0.75rem;
`;

export const FirstDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  @media screen and ${({ theme }) => theme.media.md} {
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: row;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  @media screen and ${({ theme }) => theme.media.md} {
    max-width: 180px;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    max-width: 250px;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    max-width: 350px;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0;
  min-height: 150px;

  img {
    object-fit: cover;
    width: auto;
    height: auto;
    max-width: 300px;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    img {
      max-width: 220px;
      max-height: 180px;
    }
    height: 200px;
    width: auto;
    margin: initial;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    img {
      max-width: 300px;
    }
  }
`;
export const ItemText = styled.div`
  font-size: 0.85rem;
`;

export const SecondDivider = styled.div`
  margin-top: 2rem;
`;

export const Quests = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
type QuestPros = {
  $isActive: boolean;
};
const Quest = styled.div<QuestPros>`
  position: relative;
  width: 50%;
  cursor: pointer;
  padding: 1rem 0.5rem;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.7rem;
  color: ${({ theme }) => theme.colors.font};
  img {
    position: absolute;
    top: -2px;
    width: 40px;
  }
  ${getTransition(300, ["background", "border-color"])};
  @media screen and (max-width: 420px) {
    font-size: 0.6rem;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: 0.75rem;
    padding: 1rem;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: 1rem;
    padding: 1.5rem;
    img {
      width: 60px;
    }
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    img {
      width: 80px;
      top: -3px;
    }
  }
`;

export const LeftQuest = styled(Quest)`
  text-align: right;
  ${({ $isActive }) =>
    $isActive
      ? css`
          background: linear-gradient(
            90deg,
            rgba(250, 60, 0, 0) 0%,
            rgba(250, 123, 0, 0.5) 45.34%,
            rgba(250, 180, 0, 0.9) 100.06%
          );
          border-bottom: 2px solid rgba(255, 137, 0, 1);
        `
      : css`
          background: linear-gradient(
            90deg,
            rgba(30, 34, 47, 0) 0%,
            #1e222f 100%
          );
          border-bottom: 2px solid #1e222f;
        `}
  img {
    right: 15px;
  }
`;

export const RightQuest = styled(Quest)`
  text-align: left;
  ${({ $isActive }) =>
    $isActive
            ? css`
                background: linear-gradient(
                        90deg,
                        rgba(250, 60, 0, 0) 0%,
                        rgba(250, 123, 0, 0.5) 45.34%,
                        rgba(250, 180, 0, 0.9) 100.06%
                );
                border-bottom: 2px solid rgba(255, 137, 0, 1);
            `
            : css`
                background: linear-gradient(
                        90deg,
                        rgba(30, 34, 47, 0) 0%,
                        #1e222f 100%
                );
                border-bottom: 2px solid #1e222f;
            `}
  img {
    left: 15px;
  }
`;

export const IncomesDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
  @media screen and ${({ theme }) => theme.media.md} {
    margin-left: 20px;
  }
`;

export const IncomeSnow = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  > img {
    position: absolute;
    top: -3px;
    width: 80px;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    > img {
      transform: translateX(-20px);
    }
  }
`;
export const Income = styled.div`
  width: 100%;
  position: relative;
  //  background: ${({ theme }) => theme.colors.bgColor};
  background: linear-gradient(
    90deg,
    #1e222f 0%,
    rgba(30, 34, 47, 0) 51.04%,
    #1e222f 100%
  );
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 0.75rem 0;
  @media screen and ${({ theme }) => theme.media.md} {
    background: linear-gradient(
      90deg,
      #1e222f 0%,
      rgba(30, 34, 47, 0) 51.04%,
      #1e222f 100%
    );
    flex-direction: row;
    height: 78px;
    justify-content: space-between;
  }
`;
type LeftFlexDivProps = {
  $isMarket?: boolean;
};
export const LeftFlexDiv = styled.div<LeftFlexDivProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and ${({ theme }) => theme.media.md} {
    flex-direction: row;
  }
  ${({ $isMarket }) =>
    $isMarket &&
    css`
      @media screen and ${({ theme }) => theme.media.xl} {
        width: 100%;
      }
    `}
`;

export const Present = styled.div`
  width: 33.941px;
  height: 33.941px;
  transform: rotate(-45deg);
  border-radius: 1.2px;
  border: 2.4px solid #3b4258;
  background: #12151e;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 24px;
    width: auto;
    transform: rotate(45deg);
  }
  @media screen and ${({ theme }) => theme.media.md} {
    margin-left: -34px;
    width: 56.569px;
    height: 56.569px;
    margin-right: 2rem;
    img {
      height: 40px;
    }
  }
`;

type TextDividerProps = {
  $mobileRow?: boolean;
  $isMarket?: boolean;
};
export const TextDivider = styled.div<TextDividerProps>`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ $mobileRow }) => ($mobileRow ? "row" : "column")};
  margin-top: 1rem;
  > img {
    width: 24px;
    height: auto;
    margin-right: 0.75rem;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: 16px;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    flex-direction: row;
    margin-top: 0;
  }
  ${({ $isMarket }) =>
    $isMarket &&
    css`
      @media screen and ${({ theme }) => theme.media.xl} {
        width: 275px;
        justify-content: flex-start;
      }
    `}
`;
export const Text = styled.div`
  text-transform: uppercase;
  font-weight: 700;
`;

export const BlueText = styled.span`
  color: #ffa53d;
`;

export const R = styled.span`
  color: #ffa53d;
  font-weight: 500;
`;

type CountProps = {
  $isLight: boolean;
};
export const Count = styled.div<CountProps>`
  font-weight: 700;
  ${({ $isLight }) =>
    $isLight &&
    css`
      opacity: 0.3;
    `}
  ${getTransition(300, "opacity")}
  @media screen and ${({ theme }) => theme.media.md} {
    margin-left: 0.5rem;
  }
`;

export const RowFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and ${({ theme }) => theme.media.md} {
    align-items: flex-start;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    align-items: center;
    flex-direction: row;
    flex-grow: 1;
  }
`;
export const FlexProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  @media screen and ${({ theme }) => theme.media.xl} {
    margin-top: 0;
    margin: 0 auto;
  }
`;
export const Progress = styled.div`
  width: 200px;
  border-radius: 0.25rem;
  height: 5px;
  margin: 0 5px;
  background-color: #1d212e;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
`;

type BarProps = {
  $width: number;
};
export const Bar = styled.div<BarProps>`
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  top: 0;

  background: linear-gradient(
    90deg,
    #00fff0 0.01%,
    #00c2ff 45.31%,
    #0085ff 100%
  );
  width: ${({ $width }) => $width * 100}%;
`;

type PayButtonProps = {
  $isMarket?: boolean;
};
export const PayButton = styled.button<PayButtonProps>`
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  background: transparent;
  border: none;
  font-size: 0.85rem;
  background: linear-gradient(90deg, #F2442C 0%, #FAD200 100%);
  flex-shrink: 0;
  border-radius: 0.5rem;
  padding: 0.5rem 2rem;
  color: ${({ theme }) => theme.colors.font};
  font-weight: 800;
  margin-top: 1rem;
  &:disabled {
    border: 1px solid transparent;
    color: #b1ada8;
    background: linear-gradient(${({ theme }) => theme.colors.bgLayout} 0 0)
        padding-box,
      /*this is your grey background*/
        linear-gradient(to right, #F2442C, #FAD200) border-box;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    margin-top: 0;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    margin-right: 5rem;
    padding: 0.5rem 3rem;
  }
  ${({ $isMarket }) =>
    $isMarket &&
    css`
      @media screen and ${({ theme }) => theme.media.xl} {
        margin-left: 6rem;
      }
    `}
`;
