import { Modal } from "../../../../Components/Modal/Modal";
import * as S from "./Styles";
import { GreenBtn } from "../../../../Styles/StyleComponents/GreenBtn/GreenBtn";
import {
  FormInput,
  ModalDescription,
  ModalHeading,
  WrapperModal
} from "../../../../Styles/components";
import { useMutation } from "@apollo/client";
import {
  SEND_OUTPUT_DATA,
  SEND_OUTPUT_TAG
} from "../../../../graphql/Mutation";
import { useFormInput } from "../../../../hooks/useFormInput";
import { useMemo, useState } from "react";
import {
  Messages,
  validation as getValidation
} from "../../../../functions/validation";
import { ErrorNotification } from "../../../../Components/InputModal/Main/Styles";
import { Relative } from "../../../Referral/TopContainer/Styles";
import { CloseButton } from "../../../user/Profile/BottomContainer/Output/OutputModal/Styles";

export type ModalContainerProps = {
  isOpen: boolean;
  isShow: boolean;
  toggle: () => void;
};

export const ModalContainer = ({
  isOpen,
  isShow,
  toggle,
  gemCount,
  toggleOrder,
  isPass
}: ModalContainerProps & {
  gemCount: number;
  toggleOrder: () => void;
  isPass?: boolean;
}) => {
  const [outType, setOutType] = useState("");
  const [values, reset] = useFormInput({
    email: "",
    tag: ""
  });
  const [isError, setError] = useState(false);
  const [sendOrder, { loading }] = useMutation(SEND_OUTPUT_DATA, {
    onCompleted: (data) => {
      if (data.outputEmailSum) {
        toggle();
        toggleOrder();
        reset();
      } else {
        setError(true);
      }
    },
    onError: (err) => {
      console.log(err);
      setError(true);
    }
  });
  const [sendTag, { loading: tagLoad }] = useMutation(SEND_OUTPUT_TAG, {
    onCompleted: (data) => {
      if (data.outputTagSum) {
        setOutType("success");
      } else setError(true);
    },
    onError: (error) => {
      console.log(error);
      setError(true);
    }
  });
  const [validMessages, setValidMessages] = useState<Messages | null>(null);

  const validation = useMemo(
    () =>
      getValidation({
        optional: []
      }),
    []
  );

  const onBack = () => {
    setOutType("");
    setError(false);
  };
  const onClick = async () => {
    const valid = validation({
      email: values.value.email
    });
    setValidMessages(valid);
    if (valid.isValid) {
      //console.log(gemCount);
      await sendOrder({
        variables: {
          sum: gemCount,
          email: values.value.email
        }
      });
    }
  };

  const onClickTag = async () => {
    const valid = validation({
      tag: values.value.tag
    });
    setValidMessages(valid);
    if (valid.isValid) {
      await sendTag({
        variables: {
          sum: gemCount,
          tag: values.value.tag
        }
      });
    }
  };

  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <WrapperModal>
        {isPass ? (
          <>
            <ModalHeading>Покупка Brawl Pass</ModalHeading>
            <ModalDescription>
              Купите Brawl Pass по самой выгодной цене!
            </ModalDescription>
          </>
        ) : (
          <>
            <ModalHeading>Вывод гемов</ModalHeading>
            <ModalDescription>
              Вывод {gemCount} гемов.
              {/*+{gemCount / 10} гемов вы получите в подарок!*/}
            </ModalDescription>
          </>
        )}
        {outType === "" && (
          <>
            <ModalDescription>Выберите нужный тип:</ModalDescription>
            <S.Content>
              {/*<GreenBtn*/}
              {/*  $width100={true}*/}
              {/*  $notUpper={true}*/}
              {/*  $borderWidth={1}*/}
              {/*  onClick={() => setOutType("tag")}*/}
              {/*>*/}
              {/*  По Тэгу BrawlStars*/}
              {/*</GreenBtn>*/}
              <GreenBtn
                $width100={true}
                $notUpper={true}
                $borderWidth={1}
                onClick={() => setOutType("email")}
              >
                По Email
              </GreenBtn>
            </S.Content>
          </>
        )}
        {outType === "email" && (
          <>
            <ModalDescription>
              Введите свои данные для совершения операции
            </ModalDescription>
            <S.Content>
              <Relative>
                <FormInput
                  name={"email"}
                  id={"email"}
                  placeholder={"Введите Email"}
                  value={values.value.email}
                  onChange={values.onChange}
                />
                <ErrorNotification
                  $show={
                    (!!validMessages && validMessages.email !== "isValid") ||
                    isError
                  }
                >
                  {validMessages && validMessages.email !== "isValid"
                    ? validMessages.email
                    : ""}
                  {isError && "Произошла непредвиденная ошибка"}
                </ErrorNotification>
              </Relative>
              <GreenBtn
                $width100={true}
                $notUpper={true}
                $borderWidth={1}
                onClick={onClick}
                disabled={loading || !values.value.email}
              >
                {loading ? "Загрузка" : "Подтвердить"}
              </GreenBtn>
              <CloseButton onClick={onBack}>Назад</CloseButton>
            </S.Content>
          </>
        )}
        {outType === "tag" && (
          <>
            <ModalDescription>
              Введите свой игровой тэг в формате #17QY262GQ
            </ModalDescription>
            <S.Content>
              <Relative>
                <FormInput
                  name={"tag"}
                  id={"tag"}
                  placeholder={"Введите Свой Тэг из игры"}
                  value={values.value.tag}
                  onChange={values.onChange}
                />
                <ErrorNotification
                  $show={
                    (!!validMessages && validMessages.tag !== "isValid") ||
                    isError
                  }
                >
                  {validMessages && validMessages.tag !== "isValid"
                    ? validMessages.tag
                    : ""}
                  {isError && "Произошла непредвиденная ошибка"}
                </ErrorNotification>
              </Relative>
              <GreenBtn
                $width100={true}
                $notUpper={true}
                $borderWidth={1}
                onClick={onClickTag}
                disabled={tagLoad || !values.value.tag}
              >
                {tagLoad ? "Загрузка" : "Подтвердить"}
              </GreenBtn>
              <CloseButton onClick={onBack}>Назад</CloseButton>
            </S.Content>
          </>
        )}
        {outType === "success" && (
          <>
            <ModalDescription style={{ marginTop: "1rem" }}>
              Ваша заявка успешно отправлена модератору. В ближайшее время на
              ваш игровой баланс поступят гемы. Приятной игры!
            </ModalDescription>
            <S.Content $height={8}>
              <GreenBtn
                $width100={false}
                $notUpper={true}
                $borderWidth={1}
                onClick={() => {
                  toggle();
                  setOutType("");
                }}
              >
                Отлично
              </GreenBtn>
            </S.Content>
          </>
        )}
      </WrapperModal>
    </Modal>
  ) : null;
};
