import styled from "styled-components";
import { getTransition } from "../../../Styles/utilites";

export const Text = styled.div`
  text-align: justify;
  margin-top: 3rem;
`;

export const Paragraph = styled.p`
  margin-bottom: 1rem;
`;

export const Heading = styled.div`
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: left;
  margin-top: 3rem;
`;

export const Link = styled.a`
  &:hover {
    color: ${({ theme }) => theme.colors.orange};
  }
  ${getTransition()}
`;
