import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

type WrapperProps = {
  $isEventPage?: boolean;
};
export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    justify-content: left;
    ${({ $isEventPage }) =>
      $isEventPage &&
      css`
        margin-bottom: 2rem;
      `}
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    margin-bottom: 0.8rem;
  }
`;

export const Relative = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Button = styled(Link)`
  padding: 0.9em 7em;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.font};
  border: none;
  background: linear-gradient(90deg, #f2442c 0%, #fad200 100%), #d9d9d9;
  border-radius: 0.9em;
  @media screen and ${({ theme }) => theme.media.xl} {
    padding: 0.9em 10em;
  }
`;

export const PumpkinWrapper = styled.div`
  position: static;
  margin-top: 6rem;
  @media screen and ${({ theme }) => theme.media.xl} {
    position: absolute;
    top: -30px;
    right: -200px;
    margin-top: 0;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    right: -220px;
  }
`;

export const TopDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #b1ada8;
  font-weight: 600;
`;
export const Question = styled.div`
  cursor: pointer;
  color: #000;
  text-align: center;
  font-family: Graffi Think;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  width: 16px;
  height: 16px;
  background: linear-gradient(90deg, #f2442c 0%, #fad200 100%), #a8a49f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    padding-left: 1px;
    padding-top: 1px;
  }
`;

export const BottomDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type PumpkinProps = {
  $isActive?: boolean;
};
export const Pumpkin = styled.img<PumpkinProps>`
  width: 60px;
  height: auto;
  filter: ${({ $isActive }) => ($isActive ? "none" : "grayscale()")};
`;

type PersonProps = {
  $isEventPage?: boolean;
};

export const RightPerson = styled.img<PersonProps>`
  z-index: ${({ theme }) => theme.order.two};
  transform: rotateY(180deg) rotateZ(10deg) scale(0.8);
  position: absolute;
  right: calc(-320px + 25vw);
  bottom: calc(-250px + 10vw);
  ${({ $isEventPage }) =>
    $isEventPage &&
    css`
      display: none;
    `}
  @media screen and ${({ theme }) => theme.media.lg} {
    right: calc(-320px + 25vw);
    display: block;
    //bottom: -100px;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    right: calc(-240px + 10vw);
    transform: rotateY(180deg) rotateZ(10deg) scale(0.8);
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    right: calc(-230px + 10vw);
    transform: rotateY(180deg) rotateZ(10deg) scale(1.05);
  }
`;

export const LeftPerson = styled.img<PersonProps>`
  position: absolute;
  transform: scale(0.4);
  left: calc(-420px + 15vw);
  bottom: calc(-320px + 10vw);
  ${({ $isEventPage }) =>
    $isEventPage &&
    css`
      display: none;
    `}
  @media screen and ${({ theme }) => theme.media.lg} {
    display: block;
    transform: scale(0.5);
    left: calc(-380px + 10vw);
    right: initial;
    bottom: -300px;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    transform: scale(0.6);
    bottom: -250px;
  }
`;
