import _ from "lodash";
import { PureSpinner } from "./Spinner";
import { useCallback, useEffect, useRef, useState } from "react";
import * as S from "./Styles";
import { useTimeout } from "../../../hooks/useTimeout";
import { FaAngleDoubleLeft, FaDollarSign, FaRedoAlt } from "react-icons/fa";
import { useAppContext } from "../../../Components/AppContext/AppContext";
import { ToastifyVars } from "../../../Components/Toastify2/Toastify";
import {
  CardData,
  OpenCaseMutation,
  OpenCaseMutationVariables,
  User
} from "../../../gql/graphql";
import { FetchResult, MutationTuple, useMutation } from "@apollo/client";
import { Loader } from "../../../Components/Preloader/Loader/Loader";
import { isSlowOpeningVar } from "../../../graphql/Cache";
import { SELL_LAST_ITEMS } from "../../../graphql/Mutation";
import { GET_ME } from "../../../graphql/Query";

type SpinnerContainerProps = {
  price: number;
  activeMult: number;
  items: CardData[];
  isFast: boolean;
  isFree: boolean;
  retry: () => void;
  me?: User;
  openCase: MutationTuple<OpenCaseMutation, OpenCaseMutationVariables>[0];
};

export type SpinArray = {
  index: number;
  transform: number;
  items: CardData[];
}[];

export const generate = (num?: number): string => {
  let result = "";
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  const len = num || 16;
  for (let i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const getRandomTranslate = () => {
  const start = -3521;
  const rand = Math.random() * 178;
  return start - rand;
};

// получение перемешанных элементов содержимого кейса,
// 47 эл-т всегда будет выигрышным из 54 случйных
const getRandomItems = (items: CardData[]) => {
  const mult = Math.ceil(54 / items.length);
  return _.chain(_.range(mult).flatMap(() => items))
    .shuffle()
    .slice(0, 54)
    .map((item) => ({
      ...item,
      id: `${item.id}/${generate(6)}`
    }))
    .value();
};

const getSpinArray = (
  activeMult: number,
  items: CardData[],
  res: CardData[]
): SpinArray =>
  [...Array(activeMult)].map((_, i) => ({
    index: i,
    transform: getRandomTranslate(),
    items: getRandomItems(items).map((item, j) => (j === 46 ? res[i] : item))
  }));

export const SpinnerContainer = ({
  price,
  activeMult,
  retry,
  isFast,
  isFree,
  items,
  me,
  openCase
}: SpinnerContainerProps) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const { pushToast } = useAppContext();
  const stopOpen = useRef(false);
  const [run, setRun] = useState(false);
  const [isEnd, setEnd] = useState(false);
  const [setTimeout] = useTimeout(() => setEnd(true), isFast ? 800 : 11000);
  const [sellItems, { loading }] = useMutation(SELL_LAST_ITEMS, {
    variables: {
      multBy: activeMult
    },
    onCompleted: (res) => {
      if (res.sellAllItems) setTotalPrice(0);
    },
    onError: (error) => console.log(error),
    refetchQueries: [{ query: GET_ME }]
  });

  const [spinArray, setSpinArray] = useState<null | SpinArray>(null);

  const onResult = useCallback(
    (res: FetchResult<OpenCaseMutation>) => {
      if (!res || !res.data) return;
      let price = 0;
      for (let item of res.data.opencase) {
        price += Math.round(item.price * 100) / 100;
      }
      setTotalPrice(Math.round(price * 100) / 100);
      setSpinArray(getSpinArray(activeMult, items, res.data.opencase));
      stopOpen.current = false;
    },
    [activeMult, items]
  );

  const onClick = useCallback(() => {
    if (loading) return;
    if (me!.balance < activeMult * price) {
      pushToast(ToastifyVars.noMoney);
      return;
    }
    if (stopOpen.current) return;
    stopOpen.current = true;
    setSpinArray(null);
    setEnd(false);
    if (!isFast) isSlowOpeningVar(true);
    openCase().then((res) => onResult(res));
    setRun(false);
  }, [loading, me, activeMult, price, stopOpen.current, isFast]);

  const memoSetRun = useCallback(() => {
    setRun(true);
  }, []);

  useEffect(() => {
    if (stopOpen.current) return;
    stopOpen.current = true;
    if (!isFast) isSlowOpeningVar(true);
    openCase().then((res) => onResult(res));
  }, []);

  // useEffect(() => console.log("refresh"));

  if (!spinArray) return <Loader />;
  return (
    <S.Wrapper>
      <PureSpinner
        isFast={isFast}
        spinArray={spinArray}
        activeMult={activeMult}
        setTimeout={setTimeout}
        run={run}
        setRun={memoSetRun}
        isEnd={isEnd}
      />
      <S.BtnDivider $isEnd={isEnd}>
        <S.BackBtn
          onClick={() => {
            if (loading) return;
            retry();
          }}
        >
          <FaAngleDoubleLeft />
          Назад к кейсу
        </S.BackBtn>
        {!isFree && (
          <S.RetryBtn onClick={onClick}>
            <FaRedoAlt />
            Попробовать ещё
          </S.RetryBtn>
        )}
        <S.SellBtn
          onClick={async () => {
            if (loading || totalPrice === 0) return;
            await sellItems();
          }}
        >
          {totalPrice > 0 ? (
            <>
              <FaDollarSign />
              {loading ? "Загрузка" : `Продать (${totalPrice}₽)`}
            </>
          ) : (
            <>Успешно продано</>
          )}
        </S.SellBtn>
      </S.BtnDivider>
    </S.Wrapper>
  );
};
