import styled, { css } from "styled-components";
import { getTransition } from "../../utilites";

type GreenBtnProps = {
  size?: "small";
  $isMarket?: boolean;
  $notUpper?: boolean;
  $width100?: boolean;
  $borderWidth?: number;
  $isSpecial?: boolean;
};
export const GreenBtn = styled.button<GreenBtnProps>`
  position: relative;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.font};
  padding: 0.3em 1em;
  border: ${({ $borderWidth }) => ($borderWidth ? $borderWidth + "px" : "2px")}
    solid
    ${({ theme, $isSpecial }) => ($isSpecial ? "#FFA53D" : theme.colors.border)};
  background-color: ${({ $isMarket, theme }) =>
    $isMarket ? theme.colors.fillBtn : "transparent"};
  border-radius: ${({ size }) =>
    size && size === "small" ? "0.15em" : "0.5em"};
  ${({ $notUpper }) =>
    !$notUpper &&
    css`
      text-transform: uppercase;
    `}
  ${({ $width100 }) =>
    $width100 &&
    css`
      width: 100%;
    `};
  &:hover {
    background-color: ${({ theme, $isSpecial }) =>
      $isSpecial
        ? `hsla(${theme.colors.brightBlueValue}, 0.5)`
        : theme.colors.fillBtn};
  }
  ${getTransition()}

  @media screen and ${({ theme }) => theme.media.sm} {
    padding: ${({ size }) =>
      size && size === "small" ? "0.25em 1em" : "0.75em 2em"};
  }
`;
