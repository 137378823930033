import styled from "styled-components";

export const Wrapper = styled.div`
  text-align: center;
  margin: 2rem 1rem;

  @media screen and ${({ theme }) => theme.media.lg} {
    margin: auto 1rem;
  }
`;

export const Heading = styled.h5`
  font-weight: bold;
  line-height: 1;
  margin-bottom: 1rem;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.grey45};
  font-weight: bold;
`;

export const Choice = styled.div`
  margin-top: 1rem;
  font-weight: bold;
`;

export const Rules = styled.ol`
  text-align: left;

  margin-top: 1rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin: 1rem 2rem 0;
  }
`;
