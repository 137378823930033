import * as S from "./Styles";
import { GradButton } from "../../../Styles/StyleComponents/GradButton/GradButton";
import { FaPlus } from "react-icons/fa";
import { InputModal } from "../../InputModal/InputModal";
import { useToggleVisible } from "../../../hooks/useToggleVisible";
import { AvaAndPopover } from "./AvaAndPopover/AvaAndPopover";
import { useQuery } from "@apollo/client";
import { GET_ME } from "../../../graphql/Query";
import { User } from "../../../gql/graphql";
import { useLogout } from "../../../hooks/useLogout";

// type MiniProfileProps = {};
export const MiniProfile = () => {
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  const logout = useLogout();
  const { data, loading } = useQuery(GET_ME, {
    onError: (err) => {
      if (err.message === "Unauthorized") {
        console.log("Unauthorized");
        logout();
      }
    }
  });

  if (loading) return <div>Загрузка</div>;
  if (!data || !data.getMe) return <div>Ошибка получения данных.</div>;
  return (
    <S.Wrapper>
      <S.BalanceWrapper>
        <GradButton $variant={"green"} $isSquare={true} onClick={toggle}>
          <div>
            <FaPlus />
          </div>
        </GradButton>
        <S.Balance $isSmall={data.getMe.balance.toString().length > 3}>
          {data.getMe.balance}₽
        </S.Balance>
      </S.BalanceWrapper>
      <AvaAndPopover me={data.getMe as User} />
      <InputModal isOpen={isOpen} isShow={isShow} toggle={toggle} />
    </S.Wrapper>
  );
};
