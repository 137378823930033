import { Content } from "../../../Items/Content/Content";
import * as S from "./Styles";
import { Heading } from "../../Styles";
import { useEffect, useState } from "react";
import { CardData } from "../../../../gql/graphql";
import { Toggle } from "../../Toggle/Toggle";
import { ModalContainer } from "./ModalContainer/ModalContainer";
import { useToggleVisible } from "../../../../hooks/useToggleVisible";
import { useTimeout } from "../../../../hooks/useTimeout";
import { SellButton } from "./SellButton/SellButton";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

type ItemsProps = {
  uniqueItems: string[];
  items: CardData[];
  allItems: CardData[];
  isMine: boolean;
  hasNextPage: boolean;
  isLoadingMore: boolean;
  loadMore: () => void;
  userId: string;
};

const COLLECTION_ITEMS = [
  "1",
  "3",
  "4",
  "5",
  "8",
  "13",
  "14",
  "19",
  "21",
  "42",
  "49", //Бо-меха
  "28", //золотой спайк
  ..._.range(73, 127).map((i) => i.toString())
];
export const Items = ({
  uniqueItems,
  allItems,
  items,
  isMine,
  hasNextPage,
  isLoadingMore,
  loadMore,
  userId
}: ItemsProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  const [isCol, setCol] = useState(false);
  const [setToggle] = useTimeout(toggle, 2000);

  useEffect(() => {
    if (!isMine || !isCol || !!localStorage.getItem("dontShowColModal")) return;
    setToggle();
  }, [isCol, isMine, setToggle]);

  useEffect(() => {
    if (location.search.match(/collection=/)) {
      const isCollection =
        location.search.replace("?collection=", "") === "true";
      if (isCollection) {
        setCol(true);
        navigate(`/user/${userId}`, { replace: true });
      }
    }
  }, [location]);

  return (
    <section id={"items"}>
      <S.HeadDivider>
        <Heading>Предметы</Heading>
        {isMine && <SellButton items={items} userId={userId} />}
      </S.HeadDivider>
      <Toggle isCol={isCol} setCol={setCol} />
      <S.ContentWrapper>
        {isCol ? (
          <Content
            uniqueItems={uniqueItems}
            items={allItems.filter(
              (item) => COLLECTION_ITEMS.indexOf(item.id) >= 0
            )}
            noThings={
              "Данные не были получены, попробуйте перезагрузить страницу."
            }
            hasMore={false}
            loadMore={() => {}}
            isLoadingMore={false}
          />
        ) : (
          <Content
            items={
              items.length
                ? items
                : // .filter(({ isSold }) => !isMine || (isMine && !isSold))
                  // .map(({ item }) => item)
                  []
            }
            noThings={
              isMine
                ? "Начните открывать кейсы, чтобы получить предметы."
                : "Список предметов пуст."
            }
            hasMore={hasNextPage}
            loadMore={loadMore}
            isLoadingMore={isLoadingMore}
          />
        )}
        <ModalContainer isOpen={isOpen} isShow={isShow} toggle={toggle} />
      </S.ContentWrapper>
    </section>
  );
};
