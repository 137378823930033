import { CardData } from "../../../gql/graphql";
import { Items } from "../Profile/Items/Items";
import { useQuery } from "@apollo/client";
import {
  GET_ALL_ITEMS,
  GET_USER_COLLECTION,
  GET_USER_ITEMS
} from "../../../graphql/Query";
import { Loader } from "../../../Components/Preloader/Loader/Loader";
import { useEffect, useState } from "react";

type UserItemsProps = {
  userId: string;
  isMine: boolean;
};
export const UserItems = ({ userId, isMine }: UserItemsProps) => {
  const { data, loading, fetchMore } = useQuery(GET_USER_ITEMS, {
    variables: {
      id: userId
    },
    fetchPolicy: "cache-and-network"
  });
  const { data: collection, loading: colLoad } = useQuery(GET_USER_COLLECTION, {
    variables: {
      id: userId
    }
  });
  const { data: allItems, loading: allLoad } = useQuery(GET_ALL_ITEMS, {
    fetchPolicy: "cache-first"
  });
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const loadMore = async () => {
    setIsLoadingMore(true);
    await fetchMore({
      variables: {
        id: userId,
        cursor: data?.getItemsUser?.cursor
      }
    });
    setIsLoadingMore(false);
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  if (!data && loading) return <Loader />;
  return (
    <Items
      uniqueItems={
        collection && collection.uniqueItems && collection.uniqueItems.length
          ? (collection.uniqueItems as string[])
          : []
      }
      allItems={allItems && allItems.getallItems ? allItems.getallItems : []}
      items={
        data && data.getItemsUser ? (data.getItemsUser.items as CardData[]) : []
      }
      isMine={isMine}
      hasNextPage={
        !!data && !!data.getItemsUser && data.getItemsUser.hasNextPage
      }
      loadMore={loadMore}
      isLoadingMore={isLoadingMore}
      userId={userId}
    />
  );
};
