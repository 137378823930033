import { useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ME, IS_LOGGED_IN } from "../../../graphql/Query";
import { ACCEPT_BONUS_CODE, ACCEPT_REFERRAL } from "../../../graphql/Mutation";

type UseReferral = (
  toggleAuth: () => void,
  toggleBonus: () => void,
  toggle: () => void
) => void;
export const useReferral: UseReferral = (toggleAuth, toggleBonus, toggle) => {
  const { data: isAuth } = useQuery(IS_LOGGED_IN);
  const { data: me, loading: meLoad } = useQuery(GET_ME, {
    fetchPolicy: "cache-first"
  });
  const [sendBonusCode, { loading: bonusLoad }] = useMutation(
    ACCEPT_BONUS_CODE,
    {
      variables: {
        code: localStorage.getItem("bonusCode") as string
      },
      onCompleted: (data) => {
        console.log("bonus complete");
        console.log(data);
        if (data.ref_bonus) {
          toggleBonus();
          const me = client.readQuery({ query: GET_ME });
          if (me && me.getMe) {
            const newData = {
              ...me,
              getMe: {
                ...me.getMe,
                isOld: true,
                bonus500: true,
                isDoubleProbTimer: new Date(
                  1000 * 60 * 60 * 24 + Date.now()
                ).toISOString()
              }
            };
            client.writeQuery({
              query: GET_ME,
              data: newData
            });
          }
          localStorage.removeItem("bonusCode");
        }
      },
      onError: (err) => console.log(err)
    }
  );
  const [send, { client, loading }] = useMutation(ACCEPT_REFERRAL, {
    variables: {
      code: localStorage.getItem("referralCode") as string
    },
    onCompleted: (data) => {
      console.log("invite complete");
      // console.log(data);
      if (data.checkInviteCode) {
        toggle();
        const me = client.readQuery({ query: GET_ME });
        if (me && me.getMe) {
          const newData = {
            ...me,
            getMe: {
              ...me.getMe,
              isOld: true
            }
          };
          client.writeQuery({
            query: GET_ME,
            data: newData
          });
        }
        localStorage.removeItem("referralCode");
      }
    },
    onError: (err) => console.log(err)
  });

  useEffect(() => {
    if (
      (localStorage.getItem("referralCode") ||
        localStorage.getItem("bonusCode")) &&
      !isAuth?.isLoggedIn &&
      !meLoad
    )
      toggleAuth();
  }, [meLoad]);

  useEffect(() => {
    //console.log(me);
    if (
      loading ||
      !localStorage.getItem("referralCode") ||
      !isAuth?.isLoggedIn ||
      !me ||
      !me.getMe ||
      me.getMe.isOld ||
      me.getMe.invite_code.toLowerCase() ===
        localStorage.getItem("referralCode")
    )
      return;
    send();
  }, [me]);

  useEffect(() => {
    if (
      bonusLoad ||
      !localStorage.getItem("bonusCode") ||
      !isAuth?.isLoggedIn ||
      !me ||
      !me.getMe ||
      me.getMe.isOld ||
      me.getMe.bonus500
    )
      return;
    sendBonusCode();
  }, [me]);

  useEffect(() => {
    if (
      localStorage.getItem("referralCode") &&
      me &&
      me.getMe &&
      me.getMe.isOld
    )
      localStorage.removeItem("referralCode");
  }, [me]);
};
