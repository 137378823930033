import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: hsl(0, 0%, 28%);
  color: ${({ theme }) => theme.colors.grey85};
  list-style: none;
  padding: 0.5rem 1.25rem 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  > div {
    margin-bottom: 1rem;
  }
  > div:last-child {
    margin-bottom: 0;
  }
`;

export const Container = styled.div`
  a {
    text-decoration: none;
  }
  button {
    padding: 0.5rem 1.5rem;
  }
`;
export const Date = styled.div`
  font-size: 0.85rem;
  font-weight: bolder;
  line-height: 1;
`;

export const Heading = styled.h5`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.yellow};
  margin: 0.5rem 0;
  line-height: 1;
  font-size: 1.2rem;
`;

export const Paragraph = styled.p`
  line-height: 1.1;
  font-size: 0.85rem;
`;
