import { useLayoutEffect } from "react";
import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import * as S from "./Styles";
import { SpecialContainer } from "./SpecialContainer/SpecialContainer";
import { First } from "./First/First";
import { Second } from "./Second";
import {HellBanner} from "../Main/HellBanner/Banner";

export const Event = () => {
  useLayoutEffect(() => {
    document.title = "Brawl Cases | NewYear event";
  }, []);
  useReloadScrollTop();

  return (
    <>
      <HellBanner isEventPage={true} />
      <S.Wrapper>
        <SpecialContainer />
        <First />
        <Second />
      </S.Wrapper>
    </>
  );
};
