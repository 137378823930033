import * as S from "./Styles";
import { Logo } from "../../../Logo/Logo";
import { SocialLinks } from "../../../SocialLinks/SocialLinks";
import { SignComponent } from "../../../SignComponent/SignComponent";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import { useQuery } from "@apollo/client";
import { IS_LOGGED_IN } from "../../../../graphql/Query";
import { useAppContext } from "../../../AppContext/AppContext";
import { ToastifyVars } from "../../../Toastify2/Toastify";

export const TopContainer = () => {
  const width = useWindowWidth();
  const { pushToast } = useAppContext();
  const { data } = useQuery(IS_LOGGED_IN);
  const onClick = (e: React.MouseEvent) => {
    if (data && data.isLoggedIn) return;
    e.preventDefault();
    pushToast(ToastifyVars.noAuth);
  };
  return (
    <S.Wrapper>
      <S.LogoWrapper>
        <Logo isHeader={true} />
      </S.LogoWrapper>
      <S.Links>
        <S.Support>
          <S.FaqLink to={"/faq"}>FAQ</S.FaqLink>
          {width > 576 && (
            <S.FaqLink to={"/referral"} onClick={onClick}>
              Заработок
            </S.FaqLink>
          )}
          <S.StyledLink to={"/contacts"}>Контакты</S.StyledLink>
        </S.Support>
        <SocialLinks />
      </S.Links>
      <S.SignContainer>
        <SignComponent />
      </S.SignContainer>
    </S.Wrapper>
  );
};
