import { useEffect, useLayoutEffect } from "react";
import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import {
  PageHeading,
  PageMarginSection,
  PageWrapper
} from "../../Styles/components";
import { Bonuses } from "./Bonuses/Bonuses";
import { Questions } from "./Questions/Questions";
import { GET_ME } from "../../graphql/Query";
import { useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// type BonusProps = {};
const Bonus = () => {
  const { data: me, client } = useQuery(GET_ME);
  const navigate = useNavigate();
  const location = useLocation();
  useLayoutEffect(() => {
    document.title = "Brawl Cases | Бонусы";
  }, []);
  useReloadScrollTop();
  useEffect(() => {
    if (!me || !me.getMe) return;
    if (location.search.match(/isMember=/)) {
      const member = location.search.replace("?isMember=", "");
      console.log(member);
      if (member === "vk") {
        const user = me.getMe;
        if (!user.bonus_one.vk) {
          console.log("set vk bonus");
          const newData = {
            ...user,
            balance: user.balance + 2,
            bonus_one: {
              ...user.bonus_one,
              vk: true
            }
          };
          client.writeQuery({
            query: GET_ME,
            data: { getMe: newData }
          });
        }
        navigate("/bonus", { replace: true });
      }
    }
  }, [me, location]);
  return (
    <PageWrapper>
      <Helmet prioritizeSeoTags>
        <title>Brawl Cases | Бонусы</title>
        <meta
          name="description"
          content="Бесплатные кейсы, промокоды, баланс за подписку в соц сетях. Пригласи друга и получи бонус!"
        />
        <link rel="canonical" href="https://brawlcases.com/bonus" />
        <meta
          property="og:title"
          content="Бонусы каждый день на сайте brawlcases.com! Получи бесплатно гемы Brawl Stars!"
        />
      </Helmet>
      <PageMarginSection>
        <PageHeading>Бонусы</PageHeading>
        <Bonuses />
        <Questions />
      </PageMarginSection>
    </PageWrapper>
  );
};
export default Bonus;
