import master from "../assets/imgs/payments/master.png";
import btc from "../assets/imgs/payments/btc.png";
import tink from "../assets/imgs/payments/tink.png";
import mir from "../assets/imgs/payments/mir.png";
import qiwi from "../assets/imgs/payments/qiwi.png";
import sber from "../assets/imgs/payments/sber.png";
import tele2 from "../assets/imgs/payments/tele2.png";
import visa from "../assets/imgs/payments/visa.png";
import youmoney from "../assets/imgs/payments/youmoney.png";
import ether from "../assets/imgs/payments/ether.png";
import tether from "../assets/imgs/payments/tether.png";
import tron from "../assets/imgs/payments/tron.png";
import sbp from "../assets/imgs/payments/sbp.png";
import steam from "../assets/imgs/payments/steam.png";

type Payment = {
  name: string;
  img: string;
  type: string;
  text:string;
};
export const paymentsData: Payment[] = [
  {
    name: "master",
    type: "card",
    img: master,
    text:'МИР, Visa, Mastercard',
  },
  {
    name: "visa",
    type: "card",
    img: visa,
    text:'МИР, Visa, Mastercard, СБП',
  },
  {
    name: "mir",
    type: "card",
    img: mir,
    text:'МИР (любой банк), СБП, Яндекс Pay',
  },
  {
    name: "sbp",
    type: "Bitcoin",
    img: sbp,
    text:'Система быстрых платежей',
  },
];
