import { ModalContainerProps } from "../../Marketplace/Help/ModalContainer/ModalContainer";
import { ModalHeading, WrapperModal } from "../../../Styles/components";
import { Modal } from "../../../Components/Modal/Modal";
import * as S from "./styles";
import gold from "../../../assets/imgs/snow/10.png";
import leon from "../../../assets/imgs/snow/leon.png";
import x2 from "../../../assets/imgs/snow/second.png";
import balance from "../../../assets/imgs/snow/100.png";
import { useMutation } from "@apollo/client";
import { SNOW_PRIZE } from "../../../graphql/Mutation";
import { useEffect, useState } from "react";
import { ErrorNotification } from "../../../Components/InputModal/Main/Styles";
import { GET_ME } from "../../../graphql/Query";

type PrizeModalProps = ModalContainerProps & {
  count?: number;
};

const rewards = [
  {
    countId: 3,
    name: "На ваш баланс",
    img: balance
  },
  {
    countId: 5,
    name: "Увеличенный шанс на выпадение снежинки на 24 часа",
    img: x2
  },
  {
    countId: 20,
    name: "Золотой Леон",
    img: leon
  },
  {
    countId: 25,
    name: "10 000₽ на карту",
    img: gold
  }
];

export const PrizeModal = ({
  isOpen,
  isShow,
  toggle,
  count
}: PrizeModalProps) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [send, { loading, client }] = useMutation(SNOW_PRIZE, {
    onError: (err) => {
      setError(true);
      console.log(err);
    },
    onCompleted: (res) => {
      if (res.getSnowPrize && count) {
        setSuccess(true);
      } else setError(true);
    },
    refetchQueries: [{ query: GET_ME }]
  });

  const reward = rewards.find(({ countId }) => countId === count);

  const onClick = async () => {
    if (!count) return;
    await send({
      variables: {
        snow: count
      }
    });
  };

  useEffect(() => {
    if (!isOpen) {
      setError(false);
      setSuccess(false);
    }
  }, [isOpen]);

  return isOpen && !!reward ? (
    <Modal isShow={isShow} onClose={toggle} specialStyles={true}>
      <WrapperModal>
        <ModalHeading style={{ color: "#0085FF" }}>Награда</ModalHeading>
        {success ? (
          <S.SuccessDivider>
            <S.Describe>Ваш подарок успешно получен!</S.Describe>
            <S.PrizeBtn onClick={toggle}>Отлично!</S.PrizeBtn>
          </S.SuccessDivider>
        ) : (
          <S.Wrapper>
            <S.MoneyDivider>
              <img src={reward.img} alt="Приз" />
              <S.Describe>{reward.name}</S.Describe>
              {reward.countId === 25 ? (
                <>
                  <S.Link href={"https://t.me/Pam_s0"} target={"_blank"}>
                    Telegram
                  </S.Link>
                  <S.Info>получить приз можно тут</S.Info>
                </>
              ) : (
                <S.PrizeBtn onClick={onClick} disabled={loading}>
                  {loading ? "Загрузка..." : "Забрать приз"}
                </S.PrizeBtn>
              )}

              <ErrorNotification $show={error}>
                {error && <div>Не удалось выполнить запрос</div>}
              </ErrorNotification>
            </S.MoneyDivider>
          </S.Wrapper>
        )}
      </WrapperModal>
    </Modal>
  ) : null;
};
