import { useFormInput } from "../../../hooks/useFormInput";
import { ModalHeading } from "../../../Styles/components";
import * as S from "./Styles";
import { FaCheck } from "react-icons/fa";
import { paymentsData } from "../../../data/paymentsData";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { SET_PROMOCODE } from "../../../graphql/Mutation";
import { ReactComponent as Check } from "../../../assets/icons/referral/check.svg";
import { Relative } from "../../../pages/Referral/TopContainer/Styles";
import { Phone } from "./Phone";
import { debounce } from "lodash";
import {
  Messages,
  validation as getValidation
} from "../../../functions/validation";
import { useAppContext } from "../../AppContext/AppContext";
import { ToastifyVars } from "../../Toastify2/Toastify";
import {TextPayment} from "./Styles";

type MainProps = {
  onAgreement: () => void;
  promo?: string;
};

const freeType = ["ERC20", "TRC20", "Bitcoin", "Ethereum", "Tron"];

const MINIMUM = 20;
const MAXIMUM = 50_000;
const MINIMUM_FREE = 200;

export const Main = ({ onAgreement, promo }: MainProps) => {
  const { pushToast } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [codeConfirmed, setConfirm] = useState<null | string>(null);
  const [formValue] = useFormInput({
    checkbox: "true",
    promocode: promo || "",
    price: 200,
    phone: ""
  });
  const [setCode] = useMutation(SET_PROMOCODE, {
    variables: {
      code: formValue.value.promocode
    },
    onCompleted: (data) => {
      if (data.promocode) setConfirm("TRUE");
    },
    onError: () => setConfirm("ERROR")
  });
  const [selected, setSelected] = useState<null | number>(null);
  const [type, setType] = useState<null | string>(null);

  const [validMessages, setValidMessages] = useState<Messages | null>(null);

  const validation = useMemo(
    () =>
      getValidation({
        optional: []
      }),
    []
  );
  const checkValid = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const valid = validation({
      [e.target.name]: e.target.value
    });
    setValidMessages(valid);
  }, []);
  const debouncedFn = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        checkValid(e);
      }, 500),
    []
  );
  const onType = (e: React.ChangeEvent<HTMLInputElement>) => {
    formValue.onChange(e);
    debouncedFn(e);
  };
  const onClick = async () => {
    if (!formValue.value.promocode.length || codeConfirmed === "TRUE") return;
    await setCode();
  };
  const makePayment = async () => {
    const minLocal =
      type && freeType.indexOf(type) >= 0 ? MINIMUM_FREE : MINIMUM;
    if (
      formValue.value.price < minLocal ||
      formValue.value.checkbox !== "true" ||
      !type ||
      formValue.value.price > MAXIMUM
    )
      return;
    const body: {
      price: number;
      type_pay: string;
      promocode?: string;
      phone?: string;
    } = {
      price: formValue.value.price,
      type_pay: type
    };
    if (formValue.value.promocode && codeConfirmed)
      body.promocode = formValue.value.promocode;
    if (type === "mobile_balance" || type === "qiwi")
      body.phone = formValue.value.phone;
    setLoading(true);

    try {
      const resp = await fetch(process.env.REACT_APP_URI + "payment", {
        method: "post",
        credentials: "include",
        body: JSON.stringify(body),
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          clientId: localStorage.getItem("clientId") || "",
          clientIp: localStorage.getItem("clientIp") || ""
        }
      });
      const data = await resp.json();
      console.log(data);
      if (data.url) {
        window.location.href = data.url;
      } else if (data === "notavailable") {
        pushToast(ToastifyVars.noPayMethod);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    //  if(freeType.indexOf(type) < 0) {
    // } else {
    //   //  pushToast(ToastifyVars.noPayMethod);
    //   //   setLoading(false);
    //   try {
    //     const resp = await fetch(
    //       process.env.REACT_APP_URI + "freekassa/payment_link",
    //       {
    //         method: "post",
    //         credentials: "include",
    //         body: JSON.stringify(body),
    //         mode: "cors",
    //         headers: {
    //           "Content-Type": "application/json",
    //           clientId: localStorage.getItem("clientId") || "",
    //           clientIp: localStorage.getItem("clientIp") || ""
    //         }
    //       }
    //     );
    //     const data = await resp.json();
    //     console.log(data);
    //     if (data.url) {
    //       window.location.href = data.url;
    //     } else if (data === "notavailable") {
    //       pushToast(ToastifyVars.noPayMethod);
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   } finally {
    //     setLoading(false);
    //   }
    // }
  };

  const onSelect = (i: number, type: string) => {
    setSelected(i);
    setType(type);
  };

  useEffect(() => {
    if (promo) {
      setCode();
    }
  }, []);
  return (
    <>
      <ModalHeading>Пополнение баланса</ModalHeading>
      <S.Content>
        <S.Payments>
          {paymentsData
            .filter(({ type }) => type !== "qiwi")
            .map(({ name, img, type,text }, i) => (
              <S.Payment
                key={name}
                $isActive={i === selected}
                onClick={() => onSelect(i, type)}
              >
                <img src={img} alt={name} />
                <S.TextPayment>{text}</S.TextPayment>
              </S.Payment>
            ))}
        </S.Payments>
        <Relative>
          <S.InputPromo
            name={"promocode"}
            id={"promocode"}
            type={"text"}
            placeholder={"Промокод"}
            value={formValue.value.promocode}
            onChange={formValue.onChange}
            disabled={codeConfirmed === "TRUE"}
          />
          <Check onClick={onClick} />
          <S.Notification $show={codeConfirmed === "TRUE"}>
            {codeConfirmed && codeConfirmed === "TRUE" && (
              <div>Промокод применён</div>
            )}
          </S.Notification>
          <S.ErrorNotification $show={codeConfirmed === "ERROR"}>
            {codeConfirmed && codeConfirmed === "ERROR" && (
              <div>Промокод не действителен</div>
            )}
          </S.ErrorNotification>
        </Relative>
        {(type === "mobile_balance" || type === "qiwi") && (
          <Relative>
            <Phone value={formValue.value.phone} change={onType} />
            <span>+7</span>
            <S.Notification
              $show={!validMessages || validMessages.phone === "isValid"}
            >
              {!validMessages ? (
                <div>Введите номер телефона</div>
              ) : (
                <div>Корректный номер телефона</div>
              )}
            </S.Notification>
            <S.ErrorNotification
              $show={!!validMessages && validMessages.phone !== "isValid"}
            >
              {!!validMessages && validMessages.phone !== "isValid" && (
                <div>{validMessages.phone}</div>
              )}
            </S.ErrorNotification>
          </Relative>
        )}
        <S.AgreementCheck>
          <label htmlFor={"agreement"}>
            <input
              type={"checkbox"}
              id={"agreement"}
              name={"checkbox"}
              value={formValue.value.checkbox}
              onChange={formValue.onChange}
            />
            <S.Checkmark $isSelected={formValue.value.checkbox === "true"}>
              {formValue.value.checkbox === "true" && <FaCheck />}
            </S.Checkmark>
            Даю согласие на
            <button onClick={onAgreement}>обработку персональных данных</button>
          </label>
        </S.AgreementCheck>
        <S.FlexDivider>
          {typeof selected === "number" && (
            <S.InputContainer>
              <S.InputSpan>₽</S.InputSpan>
              <S.InputPromo
                name={"price"}
                id={"price"}
                type={"number"}
                placeholder={"Сумма пополнения"}
                value={formValue.value.price}
                onChange={formValue.onChange}
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-", "."].includes(evt.key) &&
                  evt.preventDefault()
                }
                $isPrice={true}
                //disabled={codeConfirmed === "TRUE"}
              />
              <S.Notification $show={true} $isLight={true}>
                <div>
                  Пополнение от{" "}
                  {type && freeType.indexOf(type) >= 0 ? MINIMUM_FREE : MINIMUM}
                  ₽
                </div>
              </S.Notification>
            </S.InputContainer>
          )}

          <S.ConfirmBtn
            disabled={
              typeof selected !== "number" ||
              formValue.value.checkbox === "false" ||
              loading ||
              ((type === "mobile_balance" || type === "qiwi") &&
                (!validMessages || !validMessages.isValid))
            }
            onClick={makePayment}
          >
            {loading ? "Загрузка" : "Пополнить"}
          </S.ConfirmBtn>
        </S.FlexDivider>
      </S.Content>
    </>
  );
};
