import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 415px) {
    button {
      padding: 0.25em 0.25em;
    }
  }
`;

export const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 380px) {
    flex-direction: column;
    gap: 0.25rem;
    > button {
      order: 2;
    }
  }
`;

type BalanceProps = {
  $isSmall: boolean;
};
export const Balance = styled.div<BalanceProps>`
  color: ${({ theme }) => theme.colors.border};
  font-size: ${({ $isSmall }) => ($isSmall ? "0.85rem" : "1.1rem")};
  font-weight: 600;
  margin: 0 0.25rem;
  @media screen and (min-width: 415px) {
    margin: 0 0.5rem;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    margin: 0 1rem;
    font-size: 1.1rem;
  }
`;
