import * as S from "./Styles";
import { GradButton } from "../../../../../../Styles/StyleComponents/GradButton/GradButton";
import { useQuery } from "@apollo/client";
import { GET_ME, GET_NEWS } from "../../../../../../graphql/Query";
import { Link } from "react-router-dom";
import { News as NewsType } from "../../../../../../gql/graphql";
import { useMemo } from "react";

type NewsProps = {
  toggle: () => void;
};

export const News = ({ toggle }: NewsProps) => {
  const { data: me } = useQuery(GET_ME);
  const { data } = useQuery(GET_NEWS);
  const news = useMemo(() => {
    return data ? (data.getNews as NewsType[]) : null;
  }, [data]);

  return (
    <S.Wrapper>
      {news && news.length
        ? news.map(({ id, linkName, description, title, date, link }) => (
            <S.Container key={id}>
              <S.Date>{new Date(date).toLocaleDateString()}</S.Date>
              <S.Heading>{title}</S.Heading>
              <S.Paragraph>{description}</S.Paragraph>
              <Link
                to={
                  link === "/user/"
                    ? link + me?.getMe?.id + "?collection=true"
                    : link
                }
              >
                <GradButton
                  $variant={"orange"}
                  style={{ width: "100%" }}
                  onClick={toggle}
                >
                  {linkName}
                </GradButton>
              </Link>
            </S.Container>
          ))
        : null}
    </S.Wrapper>
  );
};
