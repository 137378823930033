import styled from "styled-components";
import { getTransition } from "../../../Styles/utilites";

export const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
  label {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
`;
export const Input = styled.input`
  color: ${({ theme }) => theme.colors.font};
  font-weight: bold;
  text-align: center;
  border-radius: 0.5em;
  border: 1px solid ${({ theme }) => theme.colors.grey45};
  margin-bottom: 1rem;
  padding: 0.5em 1.5em;
  background: ${({ theme }) => theme.colors.bgLayout};
  width: 100%;
  max-width: 400px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  &:focus-visible {
    outline: none;
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.grey37};
    ~ svg {
      opacity: 1;
      transform: scale(0.8);
      cursor: initial;
      path {
        fill: ${({ theme }) => theme.colors.greenGrad};
      }
      &:hover {
        opacity: 1;
        transform: scale(0.8);
      }
    }
  }
  ${getTransition()}
`;

export const ConfirmBtn = styled.button`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.font};
  font-weight: bold;
  text-align: center;
  border-radius: 0.5em;
  border: none;
  width: 100%;
  max-width: 400px;
  padding: 0.5em 1.5em;
  background-color: ${({ theme }) => theme.colors.greenGrad};
  &:hover {
    opacity: 0.6;
  }
  &:disabled {
    opacity: 0.6;
  }
  ${getTransition(300, "opacity")}
`;

export const ErrorDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 400px;
`;
