import styled from "styled-components";
import { getTransition } from "../../../../Styles/utilites";
import { DividerStyle } from "../Styles";

export const FirstContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  @media screen and ${({ theme }) => theme.media.lg} {
    flex-basis: 40%;
    margin-bottom: 0;
  }
`;

export const SecondContainer = styled(FirstContainer)`
  margin-bottom: 0;
  @media screen and ${({ theme }) => theme.media.lg} {
    flex-basis: 60%;
    margin-left: 1rem;
  }
`;

type AvaDividerProps = {
  $isMine: boolean;
};
export const AvaDivider = styled.div<AvaDividerProps>`
  margin: auto;
  display: flex;
  width: ${({ $isMine }) => ($isMine ? "initial" : "100%")};
  align-items: center;
  justify-content: center;
  height: 11rem;
  border: 1em solid ${({ theme }) => theme.colors.bgLayout};
  border-radius: 2em;
  background-color: ${({ theme }) => theme.colors.bgLayout};
  img {
    object-fit: cover;
    border-radius: 1.5em;
    max-width: calc(4rem + 15vw);
    min-width: calc(4rem + 15vw);
    max-height: calc(4rem + 15vw);
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    height: 11rem;
    img {
      max-width: 10rem;
      min-width: 10rem;
      max-height: 10rem;
    }
  }
`;

export const BtnsContainer = styled.div`
  height: 11rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin-left: 1rem;
`;
export const Exit = styled.div`
  cursor: pointer;
  font-size: 1.25rem;
  display: flex;
  flex-basis: 50%;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1em solid ${({ theme }) => theme.colors.bgLayout};
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.bgLayout};
  color: ${({ theme }) => theme.colors.grey58};
  font-weight: 600;
  svg {
    margin-right: 0.5em;
  }
  path {
    stroke: ${({ theme }) => theme.colors.grey58};
    ${getTransition(300, ["stroke", "fill"])}
  }
  &:hover {
    color: ${({ theme }) => theme.colors.font};
    path {
      stroke: ${({ theme }) => theme.colors.font};
    }
  }
  ${getTransition()}
`;

export const Referral = styled.div`
  display: flex;
  width: 100%;
  padding: 0.75rem;
  flex-basis: 50%;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.bgLayout};
  margin-bottom: 1rem;
`;

export const BestDrop = styled.div`
  display: flex;
  overflow: hidden;
  ${DividerStyle};
  padding: 0.75rem 0;
  flex-basis: 50%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  list-style: none;
`;

export const FavoriteCase = styled.div`
  display: flex;
  ${DividerStyle};
  flex-basis: 50%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 1rem;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    top: 10px;
    bottom: auto;
    z-index: ${({ theme }) => theme.order.one};
    left: auto;
    right: auto;
    height: 13rem;
  }
`;

export const Title = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.order.three};
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0.5em;
`;

export const CaseName = styled.div`
  position: absolute;
  bottom: -1px;
  z-index: ${({ theme }) => theme.order.two};
  left: 0;
  right: 0;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 2rem;
  text-align: center;
  background-image: linear-gradient(
    to top,
    ${({ theme }) => theme.colors.revert} 0%,
    transparent 100%
  );
  div {
    position: relative;
    bottom: -1rem;
  }
`;
