import { ModalContainerProps } from "../../../Marketplace/Help/ModalContainer/ModalContainer";
import { Modal } from "../../../../Components/Modal/Modal";
import * as S from "./Styles";
import { ReactComponent as Check } from "../../../../assets/icons/referral/check.svg";
import { ModalDescription, ModalHeading } from "../../../../Styles/components";
import { Content } from "../ModalContainer/Styles";
import { Relative } from "../../../Referral/TopContainer/Styles";
import { GreenBtn } from "../../../../Styles/StyleComponents/GreenBtn/GreenBtn";
import { CloseButton } from "../../../user/Profile/BottomContainer/Output/OutputModal/Styles";
import { EventWithTarget } from "../../../../hooks/useFormInput";
import {
  ErrorNotification,
  InputPromo,
  Notification
} from "../../../../Components/InputModal/Main/Styles";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { SET_PROMOBONUS } from "../../../../graphql/Mutation";
import { GET_ME } from "../../../../graphql/Query";

export const StartModal = ({
  isOpen,
  isShow,
  toggle,
  onPay,
  values
}: ModalContainerProps & {
  onPay: () => void;
  values: {
    value: {
      promocode: string;
    };
    onChange: (e: React.ChangeEvent<HTMLElement> & EventWithTarget) => void;
  };
}) => {
  const [sendCode, { loading }] = useMutation(SET_PROMOBONUS);
  const [codeConfirmed, setConfirm] = useState<null | string>(null);
  const onClick = async () => {
    if (!values.value.promocode) return;
    await sendCode({
      variables: {
        code: values.value.promocode
      },
      onCompleted: (data) => {
        if (data.promoBonus) setConfirm("TRUE");
        else setConfirm("ERROR");
      },
      onError: () => setConfirm("ERROR"),
      refetchQueries: [{ query: GET_ME }]
    });
  };
  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        <ModalHeading>Ввод промокода</ModalHeading>
        <ModalDescription>
          Получите свой бонус, введя промокод ниже. <br />
          После ввода промокода, вы сразу же получите бонусы за его применение.
          Никаких дополнительных действий от вас не требуется! Просто введите
          промокод в поле ниже и получите максимум удовольствия от игры!
        </ModalDescription>
        <Content>
          <Relative>
            <InputPromo
              name={"promocode"}
              id={"promocode"}
              type={"text"}
              placeholder={"Промокод"}
              value={values.value.promocode}
              onChange={values.onChange}
              disabled={loading}
            />
            {/*<Check onClick={onClick} />*/}
            <Notification $show={codeConfirmed === "TRUE"}>
              {codeConfirmed && codeConfirmed === "TRUE" && (
                <div>Промокод применён</div>
              )}
            </Notification>
            <ErrorNotification $show={codeConfirmed === "ERROR"}>
              {codeConfirmed && codeConfirmed === "ERROR" && (
                <div>Промокод не действителен</div>
              )}
            </ErrorNotification>
          </Relative>
          <GreenBtn onClick={onClick} style={{ marginTop: "0.5rem" }}>
            Применить
          </GreenBtn>

          <S.SpecialDivider $isVisible={codeConfirmed === "ERROR"}>
            <S.SpecialDescription>
              Если промокод не подошел, возможно, его можно задействовать только
              при пополнении.
            </S.SpecialDescription>
            <S.SpecialButton onClick={onPay}>
              Пополнить c этим промокодом
            </S.SpecialButton>
          </S.SpecialDivider>
        </Content>
      </S.Wrapper>
    </Modal>
  ) : null;
};

// export const OldStartModal = ({
//                              isOpen,
//                              isShow,
//                              toggle,
//                              onPay
//                            }: ModalContainerProps & { onPay: () => void }) => {
//   const { pushToast } = useAppContext();
//   const onClick = (text: string) => {
//     navigator.clipboard.writeText(text);
//     pushToast(ToastifyVars.copy);
//   };
//   return isOpen ? (
//     <Modal isShow={isShow} onClose={toggle}>
//       <S.Wrapper>
//         <ModalHeading>Стартовый бонус!</ModalHeading>
//         <ModalDescription>
//           Получите свой стартовый бонус введя промокод ниже. <br />
//           При пополнении от 500 рублей вам будет доступно 10 открытий любых
//           кейсов с вероятностью x2 в течении 24 часов. Воспользуйтесь
//           промокодом, вставте его в поле "Промокод" при пополнении баланса и
//           нажмите на кнопку в правой части поля ввода.
//         </ModalDescription>
//         <Content>
//           <Relative>
//             <FormInput
//               name={"promoCode"}
//               id={"promoCode"}
//               type={"text"}
//               placeholder={"Промокод"}
//               value={"DOUBLE10"}
//               readOnly
//             />
//             <Copy onClick={() => onClick("DOUBLE10")} />
//           </Relative>
//           <GreenBtn onClick={onPay}>К пополнению</GreenBtn>
//         </Content>
//       </S.Wrapper>
//     </Modal>
//   ) : null;
// };
