import * as S from "./Styles";
import { PureItem } from "../../../Components/Layout/Sidebar/ItemsFlow/Item/Item";
import { memo, useEffect, useState } from "react";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { SpinArray } from "./SpinnerContainer";
import { CardVars } from "../../../Components/Layout/Sidebar/ItemsFlow/Item/Styles";
import { CardData } from "../../../gql/graphql";
import { useTimeout } from "../../../hooks/useTimeout";

type SpinnerProps = {
  activeMult: number;
  isFast: boolean;
  spinArray: SpinArray;
  setTimeout: () => void;
  run: boolean;
  setRun: () => void;
  isEnd: boolean;
};

export const Spinner = ({
  activeMult,
  isFast,
  spinArray,
  setTimeout,
  run,
  setRun,
  isEnd
}: SpinnerProps) => {
  const width = useWindowWidth();
  const [localRun, setLocalRun] = useState(false);
  const [set, clear] = useTimeout(() => setLocalRun(run), 0);
  // const [array, setArray] = useState<SpinArray>([]);
  //
  // useEffect(() => {
  //   console.log(spinArray);
  //   setArray(spinArray);
  // }, [spinArray]);

  useEffect(() => {
    console.log("set run, spinArray trigger");
    setRun();
    setTimeout();
  }, [spinArray]);

  useEffect(() => {
    if (width > 576) {
      if (activeMult > 3) window.scrollTo(0, 200);
      else window.scrollTo(0, 0);
    }
  }, [run]);

  useEffect(() => {
    set();
  }, [run]);

  useEffect(() => {
    return () => clear();
  }, []);

  return (
    <S.Spins>
      {spinArray.map(({ index, items, transform }) => (
        <PureSpin
          key={index}
          items={items}
          transform={transform}
          isFast={isFast}
          run={localRun}
          isEnd={isEnd}
        />
      ))}
    </S.Spins>
  );
};

export const PureSpinner = memo(
  Spinner,
  (prevProps, nextProps) =>
    prevProps.isEnd === nextProps.isEnd &&
    prevProps.run === nextProps.run &&
    prevProps.isFast === prevProps.isFast
);

type SpinProps = {
  items: CardData[];
  transform: number;
  isEnd: boolean;
  isFast: boolean;
  run: boolean;
};
const Spin = ({ items, transform, run, isFast, isEnd }: SpinProps) => {
  return (
    <S.FixedWidthDivider data-testid={"spinnerDiv"}>
      <S.LeftBorder />
      <S.RightBorder />
      <S.SpinContainer>
        <S.SpinRow
          $isEnd={isEnd}
          transform={run ? transform : undefined}
          $isFast={isFast}
        >
          {items.map(({ type, image, price, name, id }, i) => (
            <PureItem
              type={type as CardVars}
              img={process.env.REACT_APP_URI + image}
              price={price}
              name={name}
              isWrap={true}
              key={id}
              isWin={i > 38 ? i === 46 : undefined}
              isFast={isFast}
            />
          ))}
        </S.SpinRow>
      </S.SpinContainer>
      <S.SpinIndicator />
    </S.FixedWidthDivider>
  );
};

const PureSpin = memo(
  Spin,
  (prevProps, nextProps) =>
    prevProps.isEnd === nextProps.isEnd && prevProps.run === nextProps.run
);
