import { ModalContainerProps } from "../../../pages/Marketplace/Help/ModalContainer/ModalContainer";
import { Modal } from "../../Modal/Modal";
import * as S from "./Styles";
import {
  FormInput,
  ModalDescription,
  ModalHeading
} from "../../../Styles/components";
import { GradButton } from "../../../Styles/StyleComponents/GradButton/GradButton";
import { GreyButton } from "../../../pages/Case/Header/Styles";
import { useMutation } from "@apollo/client";
import { ACCEPT_REFERRAL } from "../../../graphql/Mutation";
import { useState } from "react";
import { GET_ME } from "../../../graphql/Query";

export const ReferralModal = ({
  isOpen,
  isShow,
  toggle
}: ModalContainerProps) => {
  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        <ModalHeading>Ивайт-код активирован!</ModalHeading>
        <ModalDescription>
          Вы активировали инвайт-код! Теперь при первом пополнении вы получите
          +10% к сумме пополнения. Приглашайте своих знакомых и друзей и
          получайте бонусы за каждое пополнение друга!
        </ModalDescription>
        <S.ActionContainer>
          <GradButton onClick={toggle} $variant={"green"}>
            Отлично
          </GradButton>
        </S.ActionContainer>
      </S.Wrapper>
    </Modal>
  ) : null;
};
