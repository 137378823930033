import gem30 from "../assets/imgs/gems/Гем 30 -.png";
import gem80 from "../assets/imgs/gems/Гем 80.png";
import gem170 from "../assets/imgs/gems/Гем 170.png";
import gem360 from "../assets/imgs/gems/Гем 360.png";
import gem950 from "../assets/imgs/gems/Гем 950 over.png";
import gem2000 from "../assets/imgs/gems/Гем 2000.png";

export type GemCase = {
  id: string;
  img: string;
  price: number;
  count: number;
};
const prices = [179, 419, 839, 1659, 4099, 8199];
const prices2 = [339, 829, 1649, 3289, 8179, 16379];
export const gemsData: GemCase[] = [
  {
    id: "1",
    img: gem30,
    price: prices[0],
    count: 30
  },
  {
    id: "2",
    img: gem30,
    price: prices2[0],
    count: 60
  },
  {
    id: "3",
    img: gem80,
    price: prices[1],
    count: 80
  },
  {
    id: "4",
    img: gem80,
    price: prices2[1],
    count: 160
  },
  {
    id: "5",
    img: gem170,
    price: prices[2],
    count: 170
  },
  {
    id: "6",
    img: gem170,
    price: prices2[2],
    count: 340
  },
  {
    id: "7",
    img: gem360,
    price: prices[3],
    count: 360
  },
  {
    id: "8",
    img: gem360,
    price: prices2[3],
    count: 720
  },
  {
    id: "9",
    img: gem950,
    price: prices[4],
    count: 950
  },
  {
    id: "10",
    img: gem950,
    price: prices2[4],
    count: 1900
  },
  {
    id: "11",
    img: gem2000,
    price: prices[5],
    count: 2000
  },
  {
    id: "12",
    img: gem2000,
    price: prices2[5],
    count: 4000
  }
];
