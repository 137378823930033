import { useParams } from "react-router-dom";
import { CaseVars } from "../../data/casesData";
import * as React from "react";
import { useLayoutEffect, useState } from "react";
import { Header } from "./Header/Header";
import { Content } from "./Content/Content";
import * as S from "./Styles";
import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import { Error404 } from "../404/Error404";
import { SpinnerContainer } from "./Spinner/SpinnerContainer";
import { PageWrapper } from "../../Styles/components";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CASE, GET_ME, IS_LOGGED_IN } from "../../graphql/Query";
import { Loader } from "../../Components/Preloader/Loader/Loader";
import { OPEN_CASE } from "../../graphql/Mutation";
import { useLogout } from "../../hooks/useLogout";
import { User } from "../../gql/graphql";
import { useAppContext } from "../../Components/AppContext/AppContext";
import { ToastifyVars } from "../../Components/Toastify2/Toastify";
import { Helmet } from "react-helmet-async";

// type CaseProps = {};

const getFreeType = (id: string) => {
  if (id === "1") return "first_case";
  else if (id === "2") return "second_case";
  else if (id === "3") return "third_case";
  else return "fourth_case";
};

export const Case = () => {
  const { id } = useParams();
  const { data, loading, error } = useQuery(GET_CASE, {
    variables: {
      id: id!
    }
  });
  const { data: isAuth } = useQuery(IS_LOGGED_IN);
  const logout = useLogout(!!isAuth?.isLoggedIn);
  const { data: me, loading: meLoad } = useQuery(GET_ME, {
    fetchPolicy: "cache-first",
    onError: (err) => {
      console.log(err);
      if (err.message === "Unauthorized") {
        logout();
      }
    }
  });
  const [activeMult, setActive] = useState(1);
  const [isGame, setGame] = useState<null | "fast" | "norm">(null);
  const { pushToast } = useAppContext();
  const [openCase] = useMutation(OPEN_CASE, {
    variables: {
      id: id!,
      multby: activeMult
    },
    onCompleted: (data) => {
      console.log("opencase data", data);
    },
    onError: (err) => {
      console.log(err);
      if (err.message === "DontOpen") {
        pushToast(ToastifyVars.dontOpen);
      }
      if (err.message === "Unauthorized") {
        logout();
      }
    },
    refetchQueries: [{ query: GET_ME }]
  });

  useLayoutEffect(() => {
    document.title = "Brawl Cases | Открыть кейс";
  }, []);
  useReloadScrollTop();

  if (loading || meLoad) return <Loader />;
  if (!data || !data.case || !!error) return <Error404 />;
  return (
    <PageWrapper>
      <Helmet prioritizeSeoTags>
        <title>Brawl Cases | Открыть кейс</title>
        <meta
          name="description"
          content="Открой кейс с вывсокими шансами Brawl Stars! Большие бонусы, высокие шансы и надежность! Открой кейс brawl stars уже сейчас"
        />
        <meta
          property="og:title"
          content="Кейсы brawl stars на сайте brawlcases.com."
        />
      </Helmet>
      <S.HeaderComponent>
        {isGame ? (
          <SpinnerContainer
            price={data.case.price}
            activeMult={activeMult}
            items={data.case.items}
            isFast={isGame === "fast"}
            isFree={data.case.type === CaseVars.free}
            retry={() => setGame(null)}
            me={me && me.getMe ? (me.getMe as User) : undefined}
            openCase={openCase}
          />
        ) : (
          <Header
            type={data.case.type as CaseVars}
            img={data.case.image}
            name={data.case.name}
            price={data.case.price}
            freeType={
              data.case.type === CaseVars.free
                ? getFreeType(id as string)
                : undefined
            }
            activeMult={activeMult}
            setActive={setActive}
            setGame={setGame}
            me={
              isAuth?.isLoggedIn && me && me.getMe
                ? (me.getMe as User)
                : undefined
            }
          />
        )}
      </S.HeaderComponent>
      <Content items={data.case.items} />
    </PageWrapper>
  );
};
