import * as S from "./Styles";
import { PageHeading } from "../../Styles/components";
import { useEffect, useLayoutEffect, useState } from "react";
import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import { AgreementBody } from "./AgreementBody/AgreementBody";
import { Contacts } from "./Contacts/Contacts";
import { PrivacyBody } from "./PrivacyBody/PrivacyBody";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { OfferBody } from "./Offer/OfferBody";

//type AgreementProps = {};

const menuItems = [
  {
    type: "/agreement",
    title: "Пользовательское соглашение"
  },
  {
    type: "/privacy-policy",
    title: "Политика конфиденциальности"
  },
  {
    type: "/offer",
    title: "Оферта"
  },
  {
    type: "/contacts",
    title: "Контакты"
  }
];

const typeToName = {
  "/agreement": "Пользовательское соглашение",
  "/privacy-policy": "Политика конфиденциальности",
  "/offer": "Оферта",
  "/contacts": "Контакты"
};

export const Agreement = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(location.pathname);
  // useLayoutEffect(() => {
  //   document.title = `Brawl Cases | ${
  //     typeToName[location.pathname as keyof typeof typeToName]
  //   }`;
  // }, [location.pathname]);
  useReloadScrollTop([location.pathname]);
  useEffect(() => setActiveItem(location.pathname), [location.pathname]);
  useLayoutEffect(() => {
    document.title = `Brawl Cases | ${
      typeToName[location.pathname as keyof typeof typeToName]
    }`;
  }, [location.pathname]);
  return (
    <S.Wrapper>
      <Helmet prioritizeSeoTags>
        <title>{`Brawl Cases | ${
          typeToName[location.pathname as keyof typeof typeToName]
        }`}</title>
        <meta
          name="description"
          content="Основные правила сайта, пользовательское соглашение, политика конфиденциальности, оферта. Все честно и открыто."
        />
        <link
          rel="canonical"
          href={`https://brawlcases.com${location.pathname}`}
        />
        <meta
          property="og:title"
          content="Открытие кейсов и Покупка гемов бравл старс на сайте brawlcases.com!"
        />
      </Helmet>
      <S.Menu>
        {menuItems.map(({ type, title }) => (
          <S.MenuItem
            key={type}
            $isActive={type === activeItem}
            onClick={() => setActiveItem(type)}
            to={type}
          >
            {title}
          </S.MenuItem>
        ))}
      </S.Menu>
      {activeItem === "/agreement" && (
        <>
          <PageHeading>Пользовательское соглашение</PageHeading>
          <AgreementBody />
        </>
      )}
      {activeItem === "/contacts" && <Contacts />}
      {activeItem === "/privacy-policy" && (
        <>
          <PageHeading>Политика конфиденциальности</PageHeading>
          <PrivacyBody />
        </>
      )}
      {activeItem === "/offer" && <OfferBody />}
    </S.Wrapper>
  );
};
