import styled from "styled-components";
import { getTransition } from "../../../../../../Styles/utilites";

export const CardContainer = styled.div`
  margin-top: 1rem;
  min-height: 383px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
`;

export const ButtonDivider = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 0;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin-top: 4rem;
  }
`;

export const CloseButton = styled.button`
  margin-top: 1rem;
  padding: 0;
  border: none;
  background: transparent;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey58};
  &:hover {
    color: ${({ theme }) => theme.colors.border};
  }
  ${getTransition()}
`;

export const Content = styled.div`
  margin-top: 2rem;

  @media screen and ${({ theme }) => theme.media.sm} {
    margin-top: 4rem;
  }
`;

export const MaxButton = styled.button`
  margin-left: 1rem;
  border: none;
  background: transparent;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.border};
  &:hover {
    color: ${({ theme }) => theme.colors.font};
  }
  ${getTransition()}
`;
