import * as S from "./Styles";
import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import { Helmet } from "react-helmet-async";
import { useLayoutEffect } from "react";

// type Error404Props = {};
export const Error404 = () => {
  useLayoutEffect(() => {
    document.title = "Brawl Cases | 404 - страница не найдена";
  }, []);
  useReloadScrollTop();
  return (
    <S.Wrapper>
      <Helmet>
        <title>Brawl Cases | 404 - страница не найдена</title>
        <meta
          name="description"
          content="Страница не найдена. Проверьте правильность введенного адреса на сайте brawlcases.com"
        />
      </Helmet>
      <h1>Ошибка 404</h1>
      <br />
      <p>Данная страница не существует.</p>
    </S.Wrapper>
  );
};
