import { ModalContainerProps } from "../../../../Marketplace/GemCase/ModalContainer/ModalContainer";
import { Modal } from "../../../../../Components/Modal/Modal";
import * as C from "../../../../Bonus/Bonuses/ModalContainer/Styles";
import { ModalHeading } from "../../../../../Styles/components";
import * as S from "../../../../../Components/SignComponent/ModalContainer/Styles";
import { useNavigate } from "react-router-dom";

export const QuestionModal = ({
  isOpen,
  isShow,
  toggle
}: ModalContainerProps) => {
  const navigation = useNavigate();
  const onClick = () => {
    navigation("/event");
    toggle();
  };
  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <C.Wrapper>
        <ModalHeading>Хэллуин на brawlcases!</ModalHeading>
        <S.Content>
          <div>
            Открывай хэллуиновские кейсы, выбивай бравл тыквы и забирай супер
            призы!
          </div>
          <div>Подробнее на странице ивента!</div>
          <S.Button onClick={onClick}>На страницу ивента!</S.Button>
        </S.Content>
      </C.Wrapper>
    </Modal>
  ) : null;
};
