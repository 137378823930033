import { useEffect } from "react";
import { IS_LOGGED_IN } from "../../graphql/Query";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";

export const useExternalLink = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const client = useApolloClient();
  useEffect(() => {
    if (location.search.match(/isAuth=/)) {
      const isAuth = location.search.replace("?isAuth=", "") === "true";
      if (isAuth) {
        localStorage.setItem("isAuth", "true");
        client.writeQuery({ query: IS_LOGGED_IN, data: { isLoggedIn: true } });
        navigate("/", { replace: true });
      }
    }
  }, []);
  useEffect(() => {
    if (location.search.match(/referral-link=/)) {
      const referral = location.search.replace("?referral-link=", "");
      localStorage.setItem("referralCode", referral);
      navigate("/", { replace: true });
    }
  }, []);
  useEffect(() => {
    if (location.search.match(/special-bonus=/)) {
      const bonusCode = location.search.replace("?special-bonus=", "");
      localStorage.setItem("bonusCode", bonusCode);
      navigate("/", { replace: true });
    }
  }, []);
};
