import * as S from "../Styles";
import { ReactComponent as Market } from "../../../assets/icons/snow/market.svg";
import { ModalContainer } from "../../../Components/SignComponent/ModalContainer/ModalContainer";
import { useToggleVisible } from "../../../hooks/useToggleVisible";
import { useQuery } from "@apollo/client";
import { IS_LOGGED_IN } from "../../../graphql/Query";

type SpecialContainerProps = {};
export const SpecialContainer = ({}: SpecialContainerProps) => {
  const { data: isAuth } = useQuery(IS_LOGGED_IN);
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  return (
    <S.SpecialWrapper>
      <S.SpecialContainer $justifyCenter={true}>
        {isAuth && isAuth.isLoggedIn ? (
          <>
            <S.Button as={"a"} href={"#market"}>
              <Market /> Магазин
            </S.Button>
          </>
        ) : (
          <S.Button onClick={toggle}>Авторизация</S.Button>
        )}
      </S.SpecialContainer>
      <ModalContainer isOpen={isOpen} isShow={isShow} toggle={toggle} />
    </S.SpecialWrapper>
  );
};
