import * as S from "./Styles";
import { TopContainer as Wrapper } from "../Styles";
import { ReactComponent as Exit } from "../../../../assets/icons/profile/exit.svg";
import { GradButton } from "../../../../Styles/StyleComponents/GradButton/GradButton";
import { Item } from "../../../../Components/Layout/Sidebar/ItemsFlow/Item/Item";
import { useNavigate } from "react-router-dom";
import { User as UserType } from "../../../../gql/graphql";
import { CardVars } from "../../../../Components/Layout/Sidebar/ItemsFlow/Item/Styles";
import { useLogout } from "../../../../hooks/useLogout";

type TopContainerProps = {
  user: UserType;
  isMine: boolean;
};

export const TopContainer = ({ user, isMine }: TopContainerProps) => {
  const navigate = useNavigate();
  const logOut = useLogout();
  const exit = async () => {
    //logout get_current_user
    const res = await fetch(process.env.REACT_APP_URI + "logout", {
      method: "POST",
      // method: "GET",
      credentials: "include",
      headers: {
        clientId: localStorage.getItem("clientId") || "",
        clientIp: localStorage.getItem("clientIp") || ""
      }
    });
    console.log(res);
    if (res) logOut();
  };
  return (
    <Wrapper>
      <S.FirstContainer>
        <S.AvaDivider $isMine={isMine}>
          <img src={user.avatar} alt={user.first_name} />
        </S.AvaDivider>
        {isMine && (
          <S.BtnsContainer>
            <S.Referral>
              <GradButton onClick={() => navigate("/referral")}>
                <div>Заработок</div>
              </GradButton>
            </S.Referral>
            <S.Exit onClick={exit}>
              <Exit />
              Выход
            </S.Exit>
          </S.BtnsContainer>
        )}
      </S.FirstContainer>
      <S.SecondContainer>
        <S.BestDrop>
          <S.Title>Лучший дроп</S.Title>
          {user.best_drop && (
            <Item
              type={user.best_drop.type as CardVars}
              img={process.env.REACT_APP_URI + user.best_drop.image}
              price={user.best_drop.price}
              name={user.best_drop.name}
              isWrap={true}
              isDrop={true}
              key={user.best_drop.id}
            />
          )}
        </S.BestDrop>
        <S.FavoriteCase>
          <S.Title>Любимый кейс</S.Title>
          {user.favorite_case && (
            <img
              src={process.env.REACT_APP_URI + user.favorite_case.image}
              alt={"Любимый кейс"}
            />
          )}
          <S.CaseName>
            <div>{user.favorite_case?.name}</div>
          </S.CaseName>
        </S.FavoriteCase>
      </S.SecondContainer>
    </Wrapper>
  );
};
