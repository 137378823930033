import { PageHeading } from "../../../Styles/components";
import * as S from "./Styles";
import { admin, inn, urAddress } from "../../../constants/organization";

export const Contacts = () => {
  return (
    <>
      <PageHeading>Контакты</PageHeading>
      <S.Text>
        <S.Heading>Наименование компании</S.Heading>
        <S.Paragraph>{admin}</S.Paragraph>
        <S.Heading>Юридический адрес организации</S.Heading>
        <S.Paragraph>{urAddress}</S.Paragraph>
        <S.Heading>ИНН</S.Heading>
        <S.Paragraph>{inn}</S.Paragraph>
        {/*<S.Heading>ОГРН</S.Heading>*/}
        {/*<S.Paragraph>323695200003511</S.Paragraph>*/}
        {/*<S.Heading>Расчетный счет</S.Heading>*/}
        {/*<S.Paragraph>40802810500004196329</S.Paragraph>*/}
        {/*<S.Heading>Банк</S.Heading>*/}
        {/*<S.Paragraph>АО "ТИНЬКОФФ БАНК"</S.Paragraph>*/}
        {/*<S.Heading>ИНН банка</S.Heading>*/}
        {/*<S.Paragraph>7710140679</S.Paragraph>*/}
        {/*<S.Heading>БИК банка</S.Heading>*/}
        {/*<S.Paragraph>044525974</S.Paragraph>*/}
        {/*<S.Heading>Корреспондентский счет банка</S.Heading>*/}
        {/*<S.Paragraph>30101810145250000974</S.Paragraph>*/}
        {/*<S.Heading>Юридический адрес банка</S.Heading>*/}
        {/*<S.Paragraph>*/}
        {/*  Москва, 127287, ул. Хуторская 2-я, д. 38А, стр. 26*/}
        {/*</S.Paragraph>*/}
        {/*<S.Heading>Мобильный телефон</S.Heading>*/}
        {/*<S.Paragraph>8 (910) 832-5000</S.Paragraph>*/}
        <S.Heading>
          Вы можете обратится по вопросам сотрудничества к нам по почте:
        </S.Heading>
        <S.Paragraph>
          <S.Link href={"mailto:support@brawlcases.com"}>
            support@brawlcases.com
          </S.Link>
        </S.Paragraph>
        <S.Heading>Или через Телеграмм бота:</S.Heading>
        <S.Paragraph>
          <S.Link href={"https://t.me/Pam_s0"}>Telegram бот</S.Link>
        </S.Paragraph>
      </S.Text>
    </>
  );
};
