import * as S from "./Styles";
import { ReactComponent as Copy } from "../../../assets/icons/referral/copy.svg";
import { FormInput } from "../../../Styles/components";
import { useAppContext } from "../../../Components/AppContext/AppContext";
import { ToastifyVars } from "../../../Components/Toastify2/Toastify";

type TopContainerProps = {
  referral: string;
};
export const TopContainer = ({ referral }: TopContainerProps) => {
  const { pushToast } = useAppContext();
  const link =
    process.env.REACT_APP_URI!.substring(
      0,
      process.env.REACT_APP_URI!.length - 1
    ) +
    "?referral-link=" +
    referral.toLowerCase();
  const onClick = (text: string) => {
    navigator.clipboard.writeText(text);
    pushToast(ToastifyVars.copy);
  };

  return (
    <S.Wrapper>
      <S.Divider>
        <S.Title>Ваш инвайт-код</S.Title>
        <S.InputContainer>
          <S.LimitContainer>
            <S.Relative>
              <FormInput
                name={"inviteCode"}
                id={"inviteCode"}
                type={"text"}
                placeholder={"Инвайт-код"}
                value={referral}
                readOnly
              />
              <Copy onClick={() => onClick(referral)} />
            </S.Relative>
            <S.Description>
              Делитесь своим кодом с друзьями и получайте бонусы за каждое их
              пополнение. Со всех пополнений вы будете получать 10% от суммы
              пополнения вашего приглашенного друга. Ваш друг получит +10₽ на
              свой баланс.
            </S.Description>
          </S.LimitContainer>
        </S.InputContainer>
      </S.Divider>
      <S.SecondDivider>
        <S.Title>Ваша инвайт-ссылка</S.Title>
        <S.InputContainer>
          <S.LimitContainer>
            <S.Relative>
              <FormInput
                name={"inviteLink"}
                id={"inviteLink"}
                type={"text"}
                placeholder={"Инвайт-ссылка"}
                value={link}
                readOnly
              />
              <Copy onClick={() => onClick(link)} />
            </S.Relative>
            <S.Description>
              Делитесь своей ссылкой со всеми везде и получайте бонусы за их
              пополнения. Вы можете разместить свою ссылку где угодно, кроме
              нашей группы, без ограничений.
            </S.Description>
          </S.LimitContainer>
        </S.InputContainer>
      </S.SecondDivider>
    </S.Wrapper>
  );
};
