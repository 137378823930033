import * as S from "./Styles";
import present from "../../../assets/imgs/bonus/present.png";
import ref from "../../../assets/imgs/bonus/ref.png";
import vk from "../../../assets/imgs/bonus/vk.png";
import tickets from "../../../assets/imgs/bonus/tickets.png";
import { ReactComponent as Arrow } from "../../../assets/icons/Arrow.svg";
import { GradButton } from "../../../Styles/StyleComponents/GradButton/GradButton";
import { SocialLinks } from "../../../Components/SocialLinks/SocialLinks";
import { useAppContext } from "../../../Components/AppContext/AppContext";
import { ToastifyVars } from "../../../Components/Toastify2/Toastify";
import { useNavigate } from "react-router-dom";
import { useToggleVisible } from "../../../hooks/useToggleVisible";
import { useCallback } from "react";
import { ModalContainer } from "./ModalContainer/ModalContainer";
import { useQuery } from "@apollo/client";
import { IS_LOGGED_IN } from "../../../graphql/Query";
import { StartModal } from "./StartModal/StartModal";
import { InputModal } from "../../../Components/InputModal/InputModal";
import { useFormInput } from "../../../hooks/useFormInput";

// type BonusesProps = {};

const items = [
  {
    title: "Ввод промокода",
    description: "Есть промокод?\nВведи его и получи дополнительные бонусы!",
    action: "START",
    img: tickets
  },
  {
    title: "Бесплатные кейсы",
    description: "4 бонусных кейса каждый час!",
    action: "MAIN",
    img: present
  },
  {
    title: "Бонусы за подписку на соц.сети",
    description: "соц",
    action: "SUB",
    img: vk
  },
  {
    title: "Реферальные бонусы",
    description: "Приводи друзей и получай до 10% от их пополнения навсегда.",
    action: "REF",
    img: ref
  }
];
export const Bonuses = () => {
  const { data } = useQuery(IS_LOGGED_IN);
  const [values] = useFormInput({
    promocode: ""
  });
  const { pushToast } = useAppContext();
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  const [isOpenStart, isShowStart, toggleStart] = useToggleVisible(300);
  const [isOpenPay, isShowPay, togglePay] = useToggleVisible(300);
  const navigate = useNavigate();
  const onClick = useCallback(
    (action: string) => {
      if (action === "START") {
        if (data?.isLoggedIn) {
          toggleStart();
        } else {
          pushToast(ToastifyVars.noAuth);
        }
      } else if (action === "MAIN") {
        navigate("/");
      } else if (action === "REF") {
        if (data?.isLoggedIn) {
          navigate("/referral");
        } else {
          pushToast(ToastifyVars.noAuth);
        }
      } else if (action === "SUB") {
        if (data?.isLoggedIn) {
          toggle();
        } else {
          pushToast(ToastifyVars.noAuth);
        }
      }
    },
    [pushToast, data, toggle]
  );

  const onPay = () => {
    toggleStart();
    togglePay();
  };

  return (
    <S.Wrapper>
      {items.map(({ title, description, action, img }) => (
        <S.BonusElt key={action} $isTickets={action === "START"}>
          <S.Title>{title}</S.Title>
          <S.Description>
            {description.split("\n").map((str, i) => (
              <div key={i}>
                {action === "SUB" ? <SocialLinks isBonus={true} /> : str}
              </div>
            ))}
          </S.Description>
          <S.BtnContainer>
            <GradButton $variant={"orange"} onClick={() => onClick(action)}>
              <div>{action !== "SUB" ? <Arrow /> : "Подписаться"}</div>
            </GradButton>
          </S.BtnContainer>
          <img src={img} alt={title} />
        </S.BonusElt>
      ))}
      <ModalContainer isOpen={isOpen} isShow={isShow} toggle={toggle} />
      <StartModal
        isOpen={isOpenStart}
        isShow={isShowStart}
        toggle={toggleStart}
        onPay={onPay}
        values={values}
      />
      <InputModal
        isOpen={isOpenPay}
        isShow={isShowPay}
        toggle={togglePay}
        promo={!!values.value.promocode ? values.value.promocode : undefined}
      />
    </S.Wrapper>
  );
};
