import styled from "styled-components";
import { ModalDescription } from "../../../../Styles/components";
import { CloseButton } from "../../../user/Profile/BottomContainer/Output/OutputModal/Styles";
import { getTransition } from "../../../../Styles/utilites";

export const Wrapper = styled.div`
  text-align: center;
  margin: 3rem 1rem;

  @media screen and ${({ theme }) => theme.media.md} {
    margin: auto 1rem;
  }
`;

type SpecialDividerProps = {
  $isVisible?: boolean;
};
export const SpecialDivider = styled.div<SpecialDividerProps>`
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  ${getTransition(300, "opacity")}
`;
export const SpecialDescription = styled(ModalDescription)`
  margin-top: 1rem;
  font-size: 0.85rem;
`;

export const SpecialButton = styled(CloseButton)`
  margin-top: 0.5rem;
  font-size: 0.85rem;
`;
