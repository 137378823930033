import * as S from "./Styles";
import { Item } from "../../../Components/Layout/Sidebar/ItemsFlow/Item/Item";
import { GreyButton } from "../../Case/Header/Styles";
import { CardData } from "../../../gql/graphql";
import { CardVars } from "../../../Components/Layout/Sidebar/ItemsFlow/Item/Styles";
import { URI } from "../../../index";

type ContentProps = {
  items: CardData[];
  uniqueItems?: string[];
  noThings: string;
  hasMore?: boolean;
  loadMore?: () => void;
  isLoadingMore?: boolean;
};
export const Content = ({
  items,
  uniqueItems,
  noThings,
  hasMore,
  loadMore,
  isLoadingMore
}: ContentProps) => {
  const getIsWin = (id: string) => {
    if (!uniqueItems) return undefined;
    return uniqueItems.indexOf(id) >= 0 ? undefined : false;
  };

  return (
    <S.Wrapper>
      <S.ItemContainer>
        {items.length ? (
          items.map(({ type, image, price, name, id }, i) => (
            <Item
              type={type as CardVars}
              img={URI + image}
              price={price}
              name={name}
              isWrap={true}
              key={i + "/" + id}
              isWin={getIsWin(id)}
              isFast={true}
            />
          ))
        ) : (
          <h5>{noThings}</h5>
        )}
      </S.ItemContainer>
      {hasMore && (
        <S.BtnDivider>
          <GreyButton onClick={loadMore}>
            {isLoadingMore ? "Загрузка" : "Загрузить еще"}
          </GreyButton>
        </S.BtnDivider>
      )}
    </S.Wrapper>
  );
};
