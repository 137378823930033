import { PageHeading } from "../../../Styles/components";
import * as S from "../AgreementBody/Styles";
import { admin, inn, ogrn } from "../../../constants/organization";

export const OfferBody = () => {
  return (
    <>
      <PageHeading>Публичная оферта</PageHeading>
      <S.Paragraph>о заключении договора купли-продажи</S.Paragraph>
      <S.Text>
        <S.Paragraph>Обновлено от 20.10.2023</S.Paragraph>
        <S.Heading>1. Общие положения</S.Heading>
        <S.Paragraph>
          В настоящей Публичной оферте содержатся условия заключения Договора
          купли-продажи (далее по тексту - «Договор купли-продажи» и/или
          «Договор»). Настоящей офертой признается предложение, адресованное
          одному или нескольким конкретным лицам, которое достаточно определенно
          и выражает намерение лица, сделавшего предложение, считать себя
          заключившим Договор с адресатом, которым будет принято предложение.
        </S.Paragraph>
        <S.Paragraph>
          Совершение указанных в настоящей Оферте действий является
          подтверждением согласия обеих Сторон заключить Договор купли-продажи
          на условиях, в порядке и объеме, изложенных в настоящей Оферте.
        </S.Paragraph>
        <S.Paragraph>
          Нижеизложенный текст Публичной оферты является официальным публичным
          предложением Продавца, адресованный заинтересованному кругу лиц
          заключить Договор купли-продажи в соответствии с положениями пункта 2
          статьи 437 Гражданского кодекса РФ.
        </S.Paragraph>
        <S.Paragraph>
          Договор купли-продажи считается заключенным и приобретает силу с
          момента совершения Сторонами действий, предусмотренных в настоящей
          Оферте, и, означающих безоговорочное, а также полное принятие всех
          условий настоящей Оферты без каких-либо изъятий или ограничений на
          условиях присоединения.
        </S.Paragraph>
        <S.Heading>Термины и определения:</S.Heading>
        <S.Paragraph>
          Договор – текст настоящей Оферты с Приложениями, являющимися
          неотъемлемой частью настоящей Оферты, акцептованный Покупателем путем
          совершения конклюдентных действий, предусмотренных настоящей Офертой.
        </S.Paragraph>
        <S.Paragraph>
          Конклюдентные действия — это поведение, которое выражает согласие с
          предложением контрагента заключить, изменить или расторгнуть договор.
          Действия состоят в полном или частичном выполнении условий, которые
          предложил контрагент.
        </S.Paragraph>
        <S.Paragraph>
          Сайт Продавца в сети «Интернет» – совокупность программ для
          электронных вычислительных машин и иной информации, содержащейся в
          информационной системе, доступ к которой обеспечивается посредством
          сети «Интернет» по доменному имени и сетевому адресу:{" "}
          {process.env.REACT_APP_URI}
        </S.Paragraph>
        <S.Paragraph>
          Стороны Договора (Стороны) – Продавец и Покупатель.
        </S.Paragraph>
        <S.Paragraph>
          Товар - товаром по договору купли-продажи могут быть любые вещи с
          соблюдением правил, предусмотренных статьей 129 Гражданского кодекса
          РФ.
        </S.Paragraph>
        <S.Heading>2. Предмет Договора</S.Heading>
        <S.Paragraph>
          2.1. По настоящему Договору Продавец обязуется передать вещь (Товар) в
          собственность Покупателя, а Покупатель обязуется принять Товар и
          уплатить за него определенную денежную сумму.
        </S.Paragraph>
        <S.Paragraph>
          2.2. Наименование, количество, а также ассортимент Товара, его
          стоимость, порядок доставки и иные условия определяются на основании
          сведений Продавца при оформлении заявки Покупателем, либо
          устанавливаются на сайте Продавца в сети «Интернет»{" "}
          {process.env.REACT_APP_URI}
        </S.Paragraph>
        <S.Paragraph>
          2.3. Акцепт настоящей Оферты выражается в совершении конклюдентных
          действий, в частности:
        </S.Paragraph>
        <ul>
          <li>
            действиях, связанных с регистрацией учетной записи на Сайте Продавца
            в сети «Интернет» при наличии необходимости регистрации учетной
            записи;
          </li>
          <li>
            путем составления и заполнения заявки на оформление заказа Товара;
          </li>
          <li>
            путем сообщения требуемых для заключения Договора сведений по
            телефону, электронной почте, указанными на сайте Продавца в сети
            «Интернет», в том числе, при обратном звонке Продавца по заявке
            Покупателя;
          </li>
          <li>оплаты Товара Покупателем.</li>
        </ul>
        <S.Paragraph>
          Данный перечень не является исчерпывающим, могут быть и другие
          действия, которые ясно выражают намерение лица принять предложение
          контрагента.
        </S.Paragraph>
        <S.Heading>3. Права и обязанности Сторон</S.Heading>
        <S.Paragraph>3.1. Права и обязанности Продавца:</S.Paragraph>
        <S.Paragraph>
          3.1.1. Продавец вправе требовать оплаты Товаров и их доставки в
          порядке и на условиях, предусмотренных Договором;
        </S.Paragraph>
        <S.Paragraph>
          3.1.2. Отказать в заключении Договора на основании настоящей Оферты
          Покупателю в случае его недобросовестного поведения, в частности, в
          случае:
        </S.Paragraph>
        <ul>
          <li>
            более 2 (Двух) отказов от Товаров надлежащего качества в течение
            года;
          </li>
          <li>
            предоставления заведомо недостоверной персональной информации;
          </li>
          <li>
            возврата испорченного Покупателем Товара или Товара, бывшего в
            употреблении;
          </li>
          <li>
            иных случаях недобросовестного поведения, свидетельствующих о
            заключении Покупателем Договора с целью злоупотребления правами, и
            отсутствии обычной экономической цели Договора — приобретения
            Товара.
          </li>
        </ul>
        <S.Paragraph>
          3.1.3. Продавец обязуется передать Покупателю Товар надлежащего
          качества и в надлежащей упаковке;
        </S.Paragraph>
        <S.Paragraph>
          3.1.4. Передать Товар свободным от прав третьих лиц;
        </S.Paragraph>
        <S.Paragraph>
          3.1.5. Организовать доставку Товаров Покупателю;
        </S.Paragraph>
        <S.Paragraph>
          3.1.6. Предоставить Покупателю всю необходимую информацию в
          соответствии с требованиями действующего законодательства РФ и
          настоящей Оферты;
        </S.Paragraph>
        <S.Paragraph>3.2. Права и обязанности Покупателя:</S.Paragraph>
        <S.Paragraph>
          3.2.1. Покупатель вправе требовать передачи Товара в порядке и на
          условиях, предусмотренным Договором.
        </S.Paragraph>
        <S.Paragraph>
          3.2.2. Требовать предоставления всей необходимой информации в
          соответствии с требованиями действующего законодательства РФ и
          настоящей Оферты;
        </S.Paragraph>
        <S.Paragraph>
          3.2.3. Отказаться от Товара по основаниям, предусмотренным Договором и
          действующим законодательством Российской Федерации.
        </S.Paragraph>
        <S.Paragraph>
          3.2.4. Покупатель обязуется предоставить Продавцу достоверную
          информацию, необходимую для надлежащего исполнения Договора;
        </S.Paragraph>
        <S.Paragraph>
          3.2.5. Принять и оплатить Товар в соответствии с условиями Договора;
        </S.Paragraph>
        <S.Paragraph>
          3.2.6. Покупатель гарантирует, что все условия Договора ему понятны;
          Покупатель принимает условия без оговорок, а также в полном объеме.
        </S.Paragraph>
        <S.Heading>4. Цена и порядок расчетов</S.Heading>
        <S.Paragraph>
          4.1. Стоимость, а также порядок оплаты Товара определяется на
          основании сведений Продавца при оформлении заявки Покупателем, либо
          устанавливаются на сайте Продавца в сети «Интернет»:{" "}
          {process.env.REACT_APP_URI}
        </S.Paragraph>
        <S.Paragraph>
          4.2. Все расчеты по Договору производятся в безналичном порядке.
        </S.Paragraph>
        <S.Heading>5. Обмен и возврат Товара</S.Heading>
        <S.Paragraph>
          5.1. Покупатель вправе осуществить возврат (обмен) Продавцу Товара,
          приобретенный дистанционным способом, за исключением перечня товаров,
          не подлежащих обмену и возврату согласно действующему законодательству
          Российской Федерации. Условия, сроки и порядок возврата Товара
          надлежащего и ненадлежащего качества установлены в соответствии с
          требованиями Гражданского кодекса РФ, Закона РФ от 07.02.1992 N 2300-1
          «О защите прав потребителей»; Правил, утвержденных Постановлением
          Правительства РФ от 31.12.2020 N 2463.
        </S.Paragraph>
        <S.Paragraph>
          5.2. Требование Покупателя об обмене либо о возврате Товара подлежит
          удовлетворению, если Товар не был в употреблении, сохранены его
          потребительские свойства и имеются доказательства приобретения его у
          Продавца.
        </S.Paragraph>

        <S.Heading>6. Конфиденциальность и безопасность</S.Heading>
        <S.Paragraph>
          6.1. При реализации настоящего Договора Стороны обеспечивают
          конфиденциальность и безопасность персональных данных в соответствии с
          актуальной редакцией ФЗ от 27.07.2006 г. № 152-ФЗ «О персональных
          данных» и ФЗ от 27.07.2006 г. № 149-ФЗ «Об информации, информационных
          технологиях и о защите информации».
        </S.Paragraph>
        <S.Paragraph>
          6.2. Стороны обязуются сохранять конфиденциальность информации,
          полученной в ходе исполнения настоящего Договора, и принять все
          возможные меры, чтобы предохранить полученную информацию от
          разглашения.
        </S.Paragraph>
        <S.Paragraph>
          6.3. Под конфиденциальной информацией понимается любая информация,
          передаваемая Продавцом и Покупателем в процессе реализации Договора и
          подлежащая защите, исключения указаны ниже.
        </S.Paragraph>
        <S.Paragraph>
          6.4. Такая информация может содержаться в предоставляемых Продавцом
          локальных нормативных актах, договорах, письмах, отчетах,
          аналитических материалах, результатах исследований, схемах, графиках,
          спецификациях и других документах, оформленных как на бумажных, так и
          на электронных носителях.
        </S.Paragraph>
        <S.Heading>7. Форс-мажор</S.Heading>
        <S.Paragraph>
          7.1. Стороны освобождаются от ответственности за неисполнение или
          ненадлежащее исполнение обязательств по Договору, если надлежащее
          исполнение оказалось невозможным вследствие непреодолимой силы, то
          есть чрезвычайных и непредотвратимых при данных условиях
          обстоятельств, под которыми понимаются: запретные действия властей,
          эпидемии, блокада, эмбарго, землетрясения, наводнения, пожары или
          другие стихийные бедствия.
        </S.Paragraph>
        <S.Paragraph>
          7.2. В случае наступления этих обстоятельств Сторона обязана в течение
          30 (Тридцати) рабочих дней уведомить об этом другую Сторону.
        </S.Paragraph>
        <S.Paragraph>
          7.3. Документ, выданный уполномоченным государственным органом,
          является достаточным подтверждением наличия и продолжительности
          действия непреодолимой силы.
        </S.Paragraph>
        <S.Paragraph>
          7.4. Если обстоятельства непреодолимой силы продолжают действовать
          более 60 (Шестидесяти) рабочих дней, то каждая Сторона вправе
          отказаться от настоящего Договора в одностороннем порядке.
        </S.Paragraph>
        <S.Heading>8. ОТВЕТСТВЕННОСТЬ СТОРОН</S.Heading>
        <S.Paragraph>
          8.1. В случае неисполнения и/или ненадлежащего исполнения своих
          обязательств по Договору, Стороны несут ответственность в соответствии
          с условиями настоящей Оферты.
        </S.Paragraph>
        <S.Paragraph>
          8.2. Сторона, не исполнившая или ненадлежащим образом исполнившая
          обязательства по Договору, обязана возместить другой Стороне
          причиненные такими нарушениями убытки.
        </S.Paragraph>
        <S.Heading>9. Срок действия настоящей Оферты</S.Heading>
        <S.Paragraph>
          9.1. Оферта вступает в силу с момента размещения на Сайте Продавца и
          действует до момента её отзыва Продавцом.
        </S.Paragraph>
        <S.Paragraph>
          9.2. Продавец оставляет за собой право внести изменения в условия
          Оферты и/или отозвать Оферту в любой момент по своему усмотрению.
          Сведения об изменении или отзыве Оферты доводятся до Покупателя по
          выбору Продавца посредством размещения на сайте Продавца в сети
          «Интернет», в Личном кабинете Покупателя, либо путем направления
          соответствующего уведомления на электронный или почтовый адрес,
          указанный Покупателем при заключении Договора или в ходе его
          исполнения.
        </S.Paragraph>
        <S.Paragraph>
          9.3. Договор вступает в силу с момента Акцепта условий настоящей
          Оферты Покупателем и действует до полного исполнения Сторонами
          обязательств по Договору.
        </S.Paragraph>
        <S.Paragraph>
          9.4. Изменения, внесенные Продавцом в Договор и опубликованные на
          сайте в форме актуализированной Оферты, считаются принятыми
          Покупателем в полном объеме.
        </S.Paragraph>
        <S.Heading>10. Дополнительные условия</S.Heading>
        <S.Paragraph>
          10.1. Договор, его заключение и исполнение регулируется действующим
          законодательством Российской Федерации. Все вопросы, не
          урегулированные настоящей Офертой или урегулированные не полностью,
          регулируются в соответствии с материальным правом Российской
          Федерации.
        </S.Paragraph>
        <S.Paragraph>
          10.2. В случае возникновения спора, который может возникнуть между
          Сторонами в ходе исполнения ими своих обязательств по Договору,
          заключенному на условиях настоящей Оферты, Стороны обязаны
          урегулировать спор мирным путем до начала судебного разбирательства.{" "}
        </S.Paragraph>
        <S.Paragraph>
          Судебное разбирательство осуществляется в соответствии с
          законодательством Российской Федерации.{" "}
        </S.Paragraph>
        <S.Paragraph>
          Споры или разногласия, по которым Стороны не достигли договоренности,
          подлежат разрешению в соответствии с законодательством РФ. Досудебный
          порядок урегулирования спора является обязательным.
        </S.Paragraph>
        <S.Paragraph>
          10.3. В качестве языка Договора, заключаемого на условиях настоящей
          Оферты, а также языка, используемого при любом взаимодействии Сторон
          (включая ведение переписки, предоставление требований / уведомлений /
          разъяснений, предоставление документов и т. д.), Стороны определили
          русский язык.
        </S.Paragraph>
        <S.Paragraph>
          10.4. Все документы, подлежащие предоставлению в соответствии с
          условиями настоящей Оферты, должны быть составлены на русском языке
          либо иметь перевод на русский язык, удостоверенный в установленном
          порядке.
        </S.Paragraph>
        <S.Paragraph>
          10.5. Бездействие одной из Сторон в случае нарушения условий настоящей
          Оферты не лишает права заинтересованной Стороны осуществлять защиту
          своих интересов позднее, а также не означает отказа от своих прав в
          случае совершения одной из Сторон подобных либо сходных нарушений в
          будущем.
        </S.Paragraph>
        <S.Paragraph>
          10.6. Если на Сайте Продавца в сети «Интернет» есть ссылки на другие
          веб-сайты и материалы третьих лиц, такие ссылки размещены
          исключительно в целях информирования, и Продавец не имеет контроля в
          отношении содержания таких сайтов или материалов. Продавец не несет
          ответственность за любые убытки или ущерб, которые могут возникнуть в
          результате использования таких ссылок.
        </S.Paragraph>
        <S.Heading>11. Реквизиты Продавца</S.Heading>
        <S.Paragraph>Полное наименование: {admin}</S.Paragraph>
        <S.Paragraph>ИНН: {inn}</S.Paragraph>
        <S.Paragraph>ОГРН/ОГРНИП: {ogrn}</S.Paragraph>
        <S.Paragraph>Контактный телефон: +7 910 832-50-00</S.Paragraph>
        <S.Paragraph>Контактный e-mail: kostyabeliakow@yandex.ru</S.Paragraph>
        <S.Paragraph> INTERNATIONAL BUSINESS SYSTEMS S.R.L. Reg. number:3-102-693823 Beneficiary’s address:SAN JOSE-SANTA ANA,
          THREE HUNDRED AND FIFTY METERS OF THE RESTAURANT CEVICHE DEL REY, COSTA-RICA</S.Paragraph>
      </S.Text>
    </>
  );
};
