import styled, { css } from "styled-components";
import { getTransition } from "../../Styles/utilites";

type ContentProps = {
  $isShow: boolean;
  $specialStyles?: boolean;
};
export const Content = styled.div<ContentProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em 0.25em;
  min-height: 30vh;
  width: 100%;
  max-width: 900px;
  max-height: 90vh;
  margin: 0 1rem 0;
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.bgLayout};
  border: 2px solid ${({ theme }) => theme.colors.grey37};
  ${({ $specialStyles }) =>
    $specialStyles &&
    css`
      border: 3px solid transparent;
      background: linear-gradient(${({ theme }) => theme.colors.bgLayout} 0 0)
          padding-box,
        /*this is your grey background*/
          linear-gradient(to right, #00fff0, #0085ff) border-box;
      > button:first-child {
        color: #0085ff;
      }
    `}
  opacity: 0;
  transform: rotateY(90deg) translateY(-100px);
  overflow: hidden;
  ${({ $isShow }) =>
    $isShow &&
    css`
      opacity: 1;
      transform: rotateY(0) translateY(0);
    `}
  ${getTransition(300, ["opacity", "transform"], "ease-out")}
  @media screen and ${({ theme }) => theme.media.sm} {
    max-height: 100vh;
    margin: 0 3rem 0;
    padding: 1em 2em;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    min-height: 50vh;
  }
  @media screen and (min-width: 2100px) {
    min-height: 40vh;
  }
`;
