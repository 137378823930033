import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import { useLayoutEffect } from "react";
import { Wrapper } from "../agreement/Styles";
import { Helmet } from "react-helmet-async";
import { PageHeading } from "../../Styles/components";
import { GuaranteesBody } from "./GuaranteesBody/GuaranteesBody";

export const Guarantees = () => {
  useReloadScrollTop();
  useLayoutEffect(() => {
    document.title = `Brawl Cases | Оплата, гарантии и возврат`;
  }, []);
  return (
    <Wrapper>
      <Helmet prioritizeSeoTags>
        <title>{`Brawl Cases | Оплата, гарантии и возврат`}</title>
        <meta
          name="description"
          content="Оплата, гарантии и возврат для внутренних покупок на сайте."
        />
      </Helmet>
      <PageHeading>Оплата, гарантии и возврат</PageHeading>
      <GuaranteesBody />
    </Wrapper>
  );
};
