import * as S from "../Footer/Styles";
import pumpkin from "../../../../assets/imgs/hell/pumpkin.png";
import { useToggleVisible } from "../../../../hooks/useToggleVisible";
import { QuestionModal } from "./QuestionModal/QuestionModal";

type PumpkinProps = {
  pumpkinCount: number;
};
export const Pumpkin = ({ pumpkinCount }: PumpkinProps) => {
  const [isOpen, isShow, toggle] = useToggleVisible(300);

  const onClick = () => {
    toggle();
  };
  return (
    <>
      <S.TopDivider>
        <div>Вы собрали:</div>
        <S.Question onClick={onClick}>
          <div>?</div>
        </S.Question>
      </S.TopDivider>
      <S.BottomDivider>
        {[...Array(3)].map((_, i) => (
          <S.Pumpkin
            key={i}
            src={pumpkin}
            alt={"Тыква"}
            $isActive={pumpkinCount > i}
          />
        ))}
      </S.BottomDivider>
      <QuestionModal isOpen={isOpen} isShow={isShow} toggle={toggle} />
    </>
  );
};
