import styled, { css } from "styled-components";
import { getTransition } from "../../Styles/utilites";
import { SocialType } from "./SocialLinks";

type WrapperProps = {
  $isFooter?: boolean;
  $isBonus?: boolean;
};
export const Wrapper = styled.ul<WrapperProps>`
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ $isBonus }) => ($isBonus ? "8rem" : "10rem")};
  ${({ $isFooter }) =>
    $isFooter &&
    css`
      width: 240px;
      @media screen and ${({ theme }) => theme.media.sm} {
        margin-left: 1em;
      }
      @media screen and ${({ theme }) => theme.media.md} {
        width: 200px;
      }
      @media screen and ${({ theme }) => theme.media.lg} {
        width: 240px;
      }
    `}
  svg {
    transform: ${({ $isFooter }) => ($isFooter ? "scale(1)" : "scale(1.25)")};
  }
  ${({ $isFooter }) =>
    !$isFooter &&
    css`
      @media screen and ${({ theme }) => theme.media.lg} {
        margin: auto;
      }
    `}
  ${({ $isFooter, $isBonus }) =>
    !$isFooter &&
    !$isBonus &&
    css`
      @media screen and (max-width: 380px) {
        width: auto;
      }
    `}
`;

type SocialProps = {
  $isFooter?: boolean;
  $isBonus?: boolean;
  type: SocialType;
};
export const Social = styled.li<SocialProps>`
  ${({ $isFooter, type, theme }) => {
    if (!$isFooter) return;
    const colors: { [key in SocialType]: string } = {
      vk: `${theme.colors.brightBlue}`,
      tg: `${theme.colors.tgColor}`,
      tik: `${theme.colors.revert}`,
      youtube: `${theme.colors.red}`,
      discord: `${theme.colors.epic}`
    };
    return css`
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      //padding: 5px;
      background-color: ${colors[type]};
      border-radius: 50%;
      svg {
        position: relative;
        top: -2px;
        height: 24px;
        width: 24px;
      }
      @media screen and ${({ theme }) => theme.media.lg} {
        padding: 3px;
      }
    `;
  }}
  cursor: ${({ $isBonus }) => ($isBonus ? "initial" : "pointer")};
  path {
    fill: ${({ theme }) => theme.colors.font};
    ${getTransition(300, "fill")}
  }
  ${({ $isBonus, $isFooter, theme }) =>
    !$isBonus &&
    css`
      &:hover {
        path {
          fill: ${$isFooter
            ? `hsla(${theme.colors.fontValue}, .6)`
            : theme.colors.grey58};
        }
      }
    `}
`;
