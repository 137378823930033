import { Heading, Paragraph, Text } from "../../agreement/AgreementBody/Styles";
import * as S from "../Styles";
import { paymentsData } from "../../../data/paymentsData";

export const GuaranteesBody = () => {
  return (
    <Text>
      <Heading>Правила оплаты</Heading>
      <Paragraph>
        К оплате принимаются платежные карты: VISA Inc, MasterCard WorldWide,
        МИР.
      </Paragraph>
      <S.Payments>
        {paymentsData.map(({ img, name }) => (
          <S.PayElt key={name}>
            <img src={img} alt={name} />
          </S.PayElt>
        ))}
      </S.Payments>
      <Paragraph>
        Для оплаты товара банковской картой при оформлении заказа в
        интернет-магазине выберите способ оплаты: банковской картой.
      </Paragraph>
      <Paragraph>
        При оплате заказа банковской картой, обработка платежа происходит на
        авторизационной странице банка, где Вам необходимо ввести данные Вашей
        банковской карты:{" "}
      </Paragraph>
      <ol>
        <li>тип карты</li>
        <li>номер карты</li>
        <li>срок действия карты (указан на лицевой стороне карты)</li>
        <li>
          Имя держателя карты (латинскими буквами, точно также как указано на
          карте)
        </li>
        <li>CVC2/CVV2 код</li>
      </ol>
      <Paragraph>
        Если Ваша карта подключена к услуге 3D-Secure, Вы будете автоматически
        переадресованы на страницу банка, выпустившего карту, для прохождения
        процедуры аутентификации. Информацию о правилах и методах дополнительной
        идентификации уточняйте в Банке, выдавшем Вам банковскую карту.
      </Paragraph>
      <Paragraph>
        При оплате заказа банковской картой, обработка платежа (включая ввод
        номера карты) происходит на защищенной странице процессинговой системы,
        которая прошла международную сертификацию. Это значит, что Ваши
        конфиденциальные данные (реквизиты карты, регистрационные данные и др.)
        не поступают в интернет-магазин, их обработка полностью защищена и
        никто, в том числе наш интернет-магазин terra812.ru, не может получить
        персональные и банковские данные клиента.
      </Paragraph>
      <Paragraph>
        При работе с карточными данными применяется стандарт защиты информации,
        разработанный международными платёжными системами Visa и MasterCard -
        Payment Card Industry Data Security Standard (PCI DSS), что обеспечивает
        безопасную обработку реквизитов Банковской карты Держателя. Применяемая
        технология передачи данных гарантирует безопасность по сделкам с
        Банковскими картами путем использования протоколов TLS (Transport Layer
        Security), Verified by Visa, Secure Code, MIR Accept и закрытых
        банковских сетей, имеющих высшую степень защиты.
      </Paragraph>
      <Paragraph>
        <strong>
          В случае возврата, денежные средства возвращаются на ту же карту, с
          которой производилась оплата.
        </strong>
      </Paragraph>
      <Heading>
        Советы и рекомендации по необходимым мерам безопасности проведения
        платежей с использованием банковской карты:
      </Heading>
      <ol>
        <li>
          берегите свои пластиковые карты так же, как бережете наличные деньги.
          Не забывайте их в машине, ресторане, магазине и т.д.;
        </li>
        <li>
          никогда не передавайте полный номер своей кредитной карты по телефону
          каким-либо лицам или компаниям;
        </li>
        <li>
          всегда имейте под рукой номер телефона для экстренной связи с банком,
          выпустившим вашу карту, и в случае ее утраты немедленно свяжитесь с
          банком;
        </li>
        <li>
          вводите реквизиты карты только при совершении покупки. Никогда не
          указывайте их по каким-то другим причинам.
        </li>
      </ol>
      <Heading>Политика возврата</Heading>
      <Paragraph>Общие положения:</Paragraph>
      <ul>
        <li>
          Настоящая политика возврата регулирует порядок и условия возврата
          гемов, приобретенных на нашем сайте, а так же виртуальной валюты сайта
        </li>
      </ul>
      <Paragraph>Условия возврата:</Paragraph>
      <ul>
        <li>
          Возврат гемов и средств возможен только при технических неполадках,
          невозможности их использования на нашем сайте.
        </li>
        <li>
          Возврат возможен при наличии документов подтверждающих покупку на
          нашем сайте в течение 14 дней с даты покупки.
        </li>
      </ul>
      <Paragraph>Процедура возврата:</Paragraph>
      <ul>
        <li>
          Чтобы начать процесс возврата, необходимо связаться с нашей службой
          поддержки, предоставив все необходимые детали о покупке.
        </li>
        <li>
          После проверки и подтверждения информации, вам будет предоставлена
          инструкция по возврату средств.
        </li>
      </ul>
      <Paragraph>Исключения:</Paragraph>
      <ul>
        <li>
          Гемы и валюта сайта, потраченные или активированные в игре, возврату
          не подлежат.
        </li>
        <li>
          Если были выявлены попытки мошенничества или нарушения условий
          пользования, возврат может быть отклонен.
        </li>
      </ul>
      <Paragraph>Сроки и форма возврата:</Paragraph>
      <ul>
        <li>
          Возврат средств осуществляется в течение 14 рабочих дней после
          подтверждения возврата.
        </li>
        <li>
          Средства возвращаются тем же способом, которым была произведена
          оплата.
        </li>
      </ul>
      <Heading>Гарантийные обязательства</Heading>
      <Paragraph>Общие положения:</Paragraph>
      <ul>
        <li>
          Настоящие гарантийные обязательства предоставляются всем покупателям
          гемов и пользователям на нашем сайте и гарантируют правильность и
          качество предоставляемых услуг.
        </li>
      </ul>
      <Paragraph>Срок гарантии:</Paragraph>
      <ul>
        <li>Гарантийный срок составляет 30 дней с даты покупки гемов.</li>
      </ul>
      <Paragraph>Условия гарантии:</Paragraph>
      <ul>
        <li>
          В случае, если гемы не были доставлены на ваш игровой аккаунт или были
          ошибочно переведены на другой аккаунт, либо произошли технические
          неполадки на сайте, мы гарантируем их повторную доставку или возврат
          средств на сайте
        </li>
        <li>
          Если гемы или валюта сайта были потрачены или активированы в игре и на
          сайте гарантийные обязательства не распространяются.
        </li>
      </ul>
      <Paragraph>Процедура реализации гарантийных обязательств:</Paragraph>
      <ul>
        <li>
          Для того чтобы воспользоваться гарантийными обязательствами, свяжитесь
          с нашей службой поддержки и предоставьте все необходимые детали о
          покупке.
        </li>
        <li>
          После проверки и подтверждения информации, мы примем все необходимые
          меры для исправления ситуации.
        </li>
      </ul>
      <Paragraph>Исключения:</Paragraph>
      <ul>
        <li>
          Гарантийные обязательства не действуют в случае, если вы нарушили
          условия пользования нашего сайта или игры "Brawl Stars".
        </li>
        <li>
          Если были выявлены попытки мошенничества, гарантия аннулируется.
        </li>
      </ul>
    </Text>
  );
};
