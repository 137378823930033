import { PageWrapper } from "../../Styles/components";
import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import * as S from "./Styles";
import { gemsData } from "../../data/gemsData";
import _ from "lodash";
import { GemCase } from "./GemCase/GemCase";
import { Help } from "./Help/Help";
import { Helmet } from "react-helmet-async";
import { BrawlPass } from "./BrawlPass/BrawlPass";
import { useLayoutEffect } from "react";

// type MarketplaceProps = {};
const Marketplace = () => {
  useLayoutEffect(() => {
    document.title =
      "Купить гемы бравл старс, купить brawl pass, гемы brawl stars";
  }, []);
  useReloadScrollTop();
  return (
    <PageWrapper>
      <Helmet prioritizeSeoTags>
        <title>
          Купить гемы бравл старс, купить brawl pass, гемы brawl stars
        </title>
        <meta
          name="description"
          content="Купить гемы brawl stars, купить brawl pass, быстрое пополнение баланса бравл старс. Торговая площадка гемов - быстрый вывод, низкие цены."
        />
        <link rel="canonical" href="https://brawlcases.com/marketplace" />
        <meta
          property="og:title"
          content="Покупка гемов бравл старс на сайте brawlcases.com! Легко и быстро пополняйте баланс Brawl Stars!"
        />
      </Helmet>
      <S.Heading>Торговая площадка</S.Heading>
      <S.Wrapper>
        {_(gemsData)
          .chunk(2)
          .value()
          .map(([a, b], i) => (
            <S.PairContainer key={i}>
              <GemCase gem={a} isMargin={true} />
              <GemCase gem={b} />
            </S.PairContainer>
          ))}
        <BrawlPass />
      </S.Wrapper>
      <Help />
    </PageWrapper>
  );
};
export default Marketplace;
