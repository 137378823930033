import { PropsWithChildren, useEffect } from "react";
import * as S from "./Styles";
import { Sidebar } from "./Sidebar/Sidebar";
import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";
import { Toastify } from "../Toastify2/Toastify";
import { useQuery } from "@apollo/client";
import { GET_ME, GET_STATS } from "../../graphql/Query";
import { useToggleVisible } from "../../hooks/useToggleVisible";
import { OrderModal } from "../OrderModal/OrderModal";
import { ModalContainer } from "../SignComponent/ModalContainer/ModalContainer";
import { useReferral } from "./ModalReferral/useReferral";
import { BonusModal } from "./ModalBonus/BonusModal";
import { ReferralModal } from "./ModalReferral/ReferralModal";

export const Layout = ({ children }: PropsWithChildren) => {
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  const [isOpenBonus, isShowBonus, toggleBonus] = useToggleVisible(300);
  const [isOpenOrder, isShowOrder, toggleOrder] = useToggleVisible(300);
  const [isOpenAuth, isShowAuth, toggleAuth] = useToggleVisible(300);

  const { data: me } = useQuery(GET_ME, { fetchPolicy: "cache-first" });
  const { data } = useQuery(GET_STATS, {
    pollInterval: 4000
  });

  useReferral(toggleAuth, toggleBonus, toggle);

  useEffect(() => {
    //console.log(me);
    if (me && me.getMe && me.getMe.orderStatus.status && !isOpenOrder) {
      toggleOrder();
    }
  }, [me, toggleOrder]);

  return (
    <S.Wrapper>
      <Sidebar online={data && data.statistic ? data.statistic.online : 0} />
      <S.Container>
        <Header
          gems={data && data.statistic ? data.statistic.gems : 0}
          users={data && data.statistic ? data.statistic.users : 0}
          cases={data && data.statistic ? data.statistic.cases : 0}
        />
        <main>{children}</main>
        <Footer />
      </S.Container>
      <Toastify />
      <ReferralModal isOpen={isOpen} isShow={isShow} toggle={toggle} />
      <BonusModal
        isOpen={isOpenBonus}
        isShow={isShowBonus}
        toggle={toggleBonus}
      />
      <OrderModal
        isShow={isShowOrder}
        isOpen={isOpenOrder}
        toggle={toggleOrder}
      />
      <ModalContainer
        isOpen={isOpenAuth}
        isShow={isShowAuth}
        toggle={toggleAuth}
      />
    </S.Wrapper>
  );
};
