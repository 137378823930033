import styled, { css } from "styled-components";
import { Button } from "../Styles";
import { FormInput } from "../../../Styles/components";
import { getTransition } from "../../../Styles/utilites";

const column = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;
export const Wrapper = styled.div`
  ${column};
  margin-top: 1rem;
  @media screen and ${({ theme }) => theme.media.md} {
    flex-direction: row;
    margin-top: 2rem;
  }
`;

export const SuccessDivider = styled.div`
  ${column};
  //max-width: 500px;
  margin-top: 2rem;
`;

export const Describe = styled.div`
  margin-bottom: 1rem;
  @media screen and ${({ theme }) => theme.media.md} {
    margin-bottom: 2rem;
  }
`;

export const MoneyDivider = styled.div`
  ${column};
  > img {
    margin-bottom: 1rem;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    padding: 0 4rem;
  }
`;

export const Link = styled.a`
  font-size: 1rem;
  &:hover {
    color: ${({ theme }) => theme.colors.simple};
  }
  ${getTransition()}
`;
export const Info = styled.div`
  font-size: 0.85rem;
`;

export const SumText = styled.div`
  color: #fad200;
  font-size: 2rem;
  font-weight: 800;
`;

export const PrimoDivider = styled.div`
  position: relative;
  ${column};
  padding: 2rem;

  @media screen and ${({ theme }) => theme.media.md} {
    padding: 0 4rem;
  }
`;

export const PrizeBtn = styled(Button)`
  padding: calc(0.5em - 1px) calc(1.5em - 1px);
  width: 100%;
  margin-top: 1rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    max-width: 250px;
  }
`;

export const Input = styled(FormInput)`
  background: #1e222f;
`;
