import * as S from "./styles";
import { useFormInput } from "../../../hooks/useFormInput";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorNotification } from "../../../Components/InputModal/Main/Styles";
import { ErrorDivider } from "./styles";
import { IS_LOGGED_IN } from "../../../graphql/Query";
import { useApolloClient } from "@apollo/client";

export const Form = () => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [values, reset] = useFormInput({
    username: "",
    password: ""
  });
  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    console.log(values.value);
    const body: {
      login: string;
      password: string;
    } = {
      login: values.value.username,
      password: values.value.password
    };
    try {
      setLoading(true);
      const resp = await fetch(process.env.REACT_APP_URI + "login", {
        method: "post",
        credentials: "include",
        body: JSON.stringify(body),
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          clientId: localStorage.getItem("clientId") || "",
          clientIp: localStorage.getItem("clientIp") || ""
        }
      });
      //  const data = await resp.json();
      console.log(resp);
      if (resp.ok) {
        localStorage.setItem("isAuth", "true");
        client.writeQuery({ query: IS_LOGGED_IN, data: { isLoggedIn: true } });
        navigate("/");
        reset();
      } else {
        setError("Проверьте введенные данные");
      }
    } catch (err) {
      console.log(err);
      setError("Произошла ошибка");
    } finally {
      setLoading(false);
    }
  };
  return (
    <S.Form onSubmit={onSubmit}>
      <label htmlFor={"username"}>Логин:</label>
      <S.Input
        name={"username"}
        id={"username"}
        type={"text"}
        value={values.value.username}
        placeholder={"Введите логин"}
        onChange={values.onChange}
      />
      <label htmlFor={"password"}>Пароль:</label>
      <S.Input
        name={"password"}
        id={"password"}
        type={"password"}
        value={values.value.password}
        placeholder={"Введите пароль"}
        onChange={values.onChange}
      />
      <S.ConfirmBtn
        disabled={!values.value.username || !values.value.password || loading}
      >
        {loading ? "Загрузка" : "Отправить"}
      </S.ConfirmBtn>
      <S.ErrorDivider>
        <ErrorNotification $show={!!error}>
          {!!error && <div>{error}</div>}
        </ErrorNotification>
      </S.ErrorDivider>
    </S.Form>
  );
};
