import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import { Wrapper } from "../agreement/Styles";
import { PageHeading } from "../../Styles/components";
import * as S from "./Styles";
import { Accordion, Items } from "../../Components/Accordion/Accordion";
import { items as refItems } from "../Referral/BottomContainer/BottomContainer";
import { Helmet } from "react-helmet-async";
import { useLayoutEffect } from "react";

const items: Items = [
  {
    title: "Я пополнил баланс, но деньги не пришли. Что делать?",
    body: "Внимание! Ваш платеж может поступить с задержкой в 5-10 минут. Если деньги не пришли в течение часа, пожалуйста, свяжитесь со службой поддержки, указав детали (ваш аккаунт (id или имя в соц.сети), ID заявки (из письма)): support@brawlcases.com"
  },
  {
    title: "Могу ли я открывать более одного бесплатного кейса в день?",
    body: "Да, у вас есть возможность открыть неограниченное количество бесплатных кейсов в день. После выполнения условия открытия бесплатного кейса устанавливается таймер на 60 минут, и по их истечению вы можете открыть кейс. Как только вы откроете один из доступных бесплатных кейсов, вам нужно будет заново выполнить условия для открытия любого из бесплатных кейсов. Все текущие таймеры на всех бесплатных кейсах при этом также сбрасываются."
  },
  {
    title: "Почему я не могу открыть бесплатный кейс?",
    body: "Возможно, вы не выполнили все необходимые для этого условия. Прочитайте инструкции еще раз и попробуйте снова. Если проблема сохраняется, обратитесь в нашу службу поддержки."
  },
  {
    title: "Почему я не могу открыть фарм-кейс?",
    body: "Фарм-кейсы возможно открыть только после совершения хотя бы одного пополнения счета на нашем сайте."
  },
  {
    title: "Я собрал всю коллекцию предметов, как получить награду?",
    body: "Поздравляем! Вам нужно написать нам в поддержку - support@brawlcases.com, для получения вознаграждения."
  },
  {
    title: "Почему я не могу получить стартовый бонус?",
    body: "Стартовый бонус доступен только пользователям, его не активировавшим, в том числе и альтернативными способами - через промокод или специальную бонусную ссылку."
  },
  {
    title: "Как мне получить бонус за приглашение?",
    body: "Бонусы начисляются автоматически, когда приглашенный вами пользователь пополняет баланс на сайте."
  },
  ...refItems
];
const Faq = () => {
  useLayoutEffect(() => {
    document.title = "Brawl Cases | FAQ";
  }, []);
  useReloadScrollTop();
  return (
    <Wrapper>
      <Helmet prioritizeSeoTags>
        <title>Brawl Cases | FAQ</title>
        <meta
          name="description"
          content="Помощь по сайту, частые вопросы по кейсам, выводу и сотрудничеству."
        />
        <link rel="canonical" href="https://brawlcases.com/faq" />
        <meta
          property="og:title"
          content="FAQ - часто задаваемые вопросы по сайту."
        />
      </Helmet>
      <PageHeading>FAQ</PageHeading>
      <S.SubTitle>Часто задаваемые вопросы</S.SubTitle>
      <S.QuestionsContainer>
        <Accordion items={items} />
      </S.QuestionsContainer>
    </Wrapper>
  );
};
export default Faq;
