import styled from "styled-components";
import { Wrapper } from "../TopContainer/Styles";

export const SecondWrapper = styled(Wrapper)`
  margin-top: 1rem;
  > div {
    height: 26rem;
  }
  > div:first-child {
    height: 36rem;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    > div:first-child {
      overflow-x: hidden;
      overflow-y: scroll;
      height: 26rem;
      &::-webkit-scrollbar {
        width: 0px;
        height: 0rem;
      }

      &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.brightPurple};
        border-radius: 1px;
      }

      > div {
        height: 36rem;
        justify-content: flex-start;
      }
    }
  }
  @media screen and (max-width: 460px) {
    > div:first-child {
      height: 46rem;
    }
  }
  @media screen and (max-width: 360px) {
    > div:first-child {
      height: 50rem;
    }
  }
  // @media screen and ${({ theme }) => theme.media.lg} {
  //   > div:first-child,
  //   > div {
  //     height: 44rem;
  //   }
  // }
`;

export const ErrorInfo = styled.div`
  margin-top: 1rem;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.red};
`;

export const BtnContainer = styled.div`
  margin-top: 1rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin-top: 3rem;
  }
`;
