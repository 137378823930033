import { ModalContainerProps } from "../../../pages/Marketplace/Help/ModalContainer/ModalContainer";
import { Modal } from "../../Modal/Modal";
import * as S from "./Styles";
import { ModalDescription, ModalHeading } from "../../../Styles/components";
import { GradButton } from "../../../Styles/StyleComponents/GradButton/GradButton";

export const BonusModal = ({ isOpen, isShow, toggle }: ModalContainerProps) => {
  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        <ModalHeading>Бонус активирован!</ModalHeading>
        <ModalDescription>
          Вы получили специальный бонус на удвоение шансов во всех кейсах! Он
          будет действовать на 10 любых кейсов, в течении 24 часов! Открывайте
          кейсы и выигрывайте!
        </ModalDescription>
        <S.ActionContainer>
          <GradButton onClick={toggle} $variant={"green"}>
            Отлично
          </GradButton>
        </S.ActionContainer>
      </S.Wrapper>
    </Modal>
  ) : null;
};
