import * as S from "../AgreementBody/Styles";
import { admin } from "../../../constants/organization";

export const PrivacyBody = () => {
  return (
    <>
      <S.Text>
        <S.Paragraph>Обновлено от 03.08.2023</S.Paragraph>
        <S.Heading>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</S.Heading>
        <S.Paragraph>
          В настоящей Политике конфиденциальности используются следующие
          термины:
        </S.Paragraph>
        <S.Paragraph>
          1.1. Сайт – Интернет-ресурс, состоящий из совокупности всей информации
          (текстовой и графической), функций, программ для ЭВМ и размещенного в
          них контента (имеющих электронную форму данных, информации,
          материалов, документов, файлов, сообщений, изображений и т.п.),
          расположенный по сетевому адресу (доменному имени){" "}
          {process.env.REACT_APP_URI}, включая все внутренние страницы данного
          адреса.
        </S.Paragraph>
        <S.Paragraph>
          1.2. Администрация Сайта – {admin}, владеющее и управляющее Сайтом.
        </S.Paragraph>
        <S.Paragraph>
          1.3. Пользователь Сайта (далее – Пользователь) – физическое лицо,
          осуществляющее пользование Сайтом. Юридическое лицо осуществляет
          функции Пользователя через своего представителя.
        </S.Paragraph>
        <S.Paragraph>
          1.4. Персональные данные – любая информация, относящаяся к прямо или
          косвенно определенному или определяемому физическому лицу (субъекту
          персональных данных).
        </S.Paragraph>
        <S.Paragraph>
          1.5. Обработка персональных данных – любое действие (операция) или
          совокупность действий (операций), совершаемых с использованием средств
          автоматизации или без использования таких средств с персональными
          данными, включая сбор, запись, систематизацию, накопление, хранение,
          уточнение (обновление, изменение), извлечение, использование, передачу
          (распространение, предоставление, доступ), обезличивание,
          блокирование, удаление, уничтожение персональных данных.
        </S.Paragraph>
        <S.Paragraph>
          1.6. Конфиденциальность персональных данных – обязательное для
          соблюдения Администрацией Сайта, Пользователем или иным получившим
          доступ к персональным данным лицом требование не допускать их
          распространения без согласия субъекта персональных данных или наличия
          иного законного основания.
        </S.Paragraph>
        <S.Heading>2. ОБЩИЕ ПОЛОЖЕНИЯ</S.Heading>
        <S.Paragraph>
          2.1. Настоящая политика обработки персональных данных составлена в
          соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ
          «О персональных данных» (далее - Закон о персональных данных) и
          определяет порядок обработки персональных данных и меры по обеспечению
          безопасности персональных данных, предпринимаемые ОБЩЕСТВО С
          ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ MRK GROUP (далее – Администрация Сайта).
        </S.Paragraph>
        <S.Paragraph>
          2.2. Администрация Сайта ставит своей важнейшей целью и условием
          осуществления своей деятельности соблюдение прав и свобод человека и
          гражданина при обработке его персональных данных, в том числе защиты
          прав на неприкосновенность частной жизни, личную и семейную тайну.
        </S.Paragraph>
        <S.Paragraph>
          2.3. Настоящая политика Администрации Сайта в отношении обработки
          персональных данных (далее – Политика) применяется ко всей информации,
          которую Администрация Сайта может получить о посетителях сайта
          {process.env.REACT_APP_URI}
        </S.Paragraph>
        <S.Paragraph>
          2.4. Настоящая Политика конфиденциальности персональных данных (далее
          – Политика конфиденциальности) действует в отношении всей информации,
          которую Сайт может получить о Пользователе, во время использования
          последним Сайта и каких-либо функций, предложенных на Сайте.
        </S.Paragraph>
        <S.Paragraph>
          2.5. Использование Пользователем Сайта означает согласие с настоящей
          Политикой конфиденциальности и условиями обработки персональных данных
          Пользователя.
        </S.Paragraph>
        <S.Paragraph>
          2.6. В случае несогласия с условиями Политики конфиденциальности
          Пользователь обязан прекратить использование Сайта.
        </S.Paragraph>
        <S.Paragraph>
          2.7. Настоящая Политика конфиденциальности непосредственно применяется
          только к Сайту. Администрация Сайта не контролирует и не несет
          ответственность за сайты третьих лиц, на которые Пользователь может
          перейти по ссылкам, доступным на Сайте.
        </S.Paragraph>
        <S.Paragraph>
          2.8. Администрация Сайта не проверяет и не отвечает за достоверность
          персональных данных, предоставляемых Пользователем Сайта.
        </S.Paragraph>
        <S.Heading>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</S.Heading>
        <S.Paragraph>
          3.1. Настоящая Политика конфиденциальности устанавливает обязательства
          Администрации Сайта по неразглашению и обеспечению режима защиты
          конфиденциальности персональных данных, которые Пользователь
          предоставляет при регистрации на Сайте.
        </S.Paragraph>
        <S.Paragraph>
          3.2. Персональные данные, разрешённые к обработке в рамках настоящей
          Политики конфиденциальности, предоставляются Пользователем путём
          добровольного заполнения одной или нескольких форм обратной связи,
          регистрации или при использовании других функций, предложенных на
          Сайте.
        </S.Paragraph>
        <S.Paragraph>
          3.3. Любая иная персональная информация, не оговоренная выше, подлежит
          надежному хранению и нераспространению, за исключением случаев,
          предусмотренных в п. 5.2. и п. 5.3. настоящей Политики
          конфиденциальности.
        </S.Paragraph>
        <S.Heading>
          4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ АДМИНИСТРАЦИЕЙ САЙТА
        </S.Heading>
        <S.Paragraph>
          Администрация Сайта может использовать персональные данные
          Пользователя в целях:
        </S.Paragraph>
        <S.Paragraph>
          4.1. Идентификации Пользователя, для оформления заказа и (или)
          заключения договора на оказание услуг, продажу товаров или их оплату.
        </S.Paragraph>
        <S.Paragraph>
          4.2. Предоставления Пользователю доступа к персонализированной
          информации.
        </S.Paragraph>
        <S.Paragraph>
          4.3. Установления с Пользователем обратной связи, включая: направление
          уведомлений, запросов, касающихся использования Сайта, оказание услуг,
          продажу товаров, оплату, обработку запросов и заявок от Пользователя.
        </S.Paragraph>
        <S.Paragraph>
          4.4. Обработки и получения платежей, подтверждения налога или
          налоговых льгот, оспаривания (возврата) платежа;
        </S.Paragraph>
        <S.Paragraph>
          4.5. Предоставления Пользователю клиентской и технической поддержки
          при возникновении проблем, связанных с использованием Сайта.
        </S.Paragraph>
        <S.Paragraph>
          4.6. Предоставления Пользователю, с его согласия, обновлений
          ассортимента товаров и услуг, специальных предложений, информации о
          ценах, новостной рассылки и иных сведений от имени Администрации Сайта
          или от имени партнеров Администрации Сайта.
        </S.Paragraph>
        <S.Paragraph>
          4.7. Предоставления доступа Пользователю на сайты или функции
          партнеров Администрации Сайта с целью получения продуктов, обновлений,
          товаров и услуг.
        </S.Paragraph>
        <S.Paragraph>
          4.8. Администрация Сайта осуществляет сбор, запись, систематизацию,
          накопление, хранение, уточнение (обновление, изменение), извлечение,
          использование, передачу (распространение, предоставление, доступ),
          обезличивание, блокирование, удаление и уничтожение персональных
          данных.
        </S.Paragraph>
        <S.Paragraph>
          4.9. Администрация Сайта осуществляет автоматизированную обработку
          персональных данных с получением и/или передачей полученной информации
          по информационно-телекоммуникационным сетям или без таковой.
        </S.Paragraph>
        <S.Paragraph>
          4.10. Обезличенные данные Пользователей, собираемые с помощью сервисов
          интернет-статистики, служат для сбора информации о действиях
          Пользователей на сайте, улучшения качества сайта и его содержания.
        </S.Paragraph>
        <S.Heading>
          5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ
        </S.Heading>
        <S.Paragraph>
          5.1. Обработка персональных данных Пользователя осуществляется без
          ограничения срока, любым законным способом, в том числе в
          информационных системах персональных данных с использованием средств
          автоматизации или без использования таких средств.
        </S.Paragraph>
        <S.Paragraph>
          5.2. Пользователь соглашается с тем, что Администрация Сайта вправе
          передавать персональные данные третьим лицам, в частности, курьерским
          службам, почтовым сервисам, операторам электросвязи и другим сервисам,
          в целях выполнения заказов, заявок и просьб Пользователя.
        </S.Paragraph>
        <S.Paragraph>
          5.3. При утрате или разглашении персональных данных Администрация
          Сайта информирует Пользователя об утрате или разглашении персональных
          данных.
        </S.Paragraph>
        <S.Paragraph>
          5.4. Администрация Сайта принимает необходимые организационные и
          технические меры для защиты персональной информации Пользователя от
          неправомерного или случайного доступа, уничтожения, изменения,
          блокирования, копирования, распространения, а также от иных
          неправомерных действий третьих лиц.
        </S.Paragraph>
        <S.Paragraph>
          5.5. Администрация Сайта совместно с Пользователем принимает все
          необходимые меры по предотвращению убытков или иных отрицательных
          последствий, вызванных разглашением персональных данных Пользователя.
        </S.Paragraph>
        <S.Heading>6. ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</S.Heading>
        <S.Paragraph>
          6.1. Обработка персональных данных осуществляется на законной и
          справедливой основе.
        </S.Paragraph>
        <S.Paragraph>
          6.2. Обработка персональных данных ограничивается достижением
          конкретных, заранее определенных и законных целей. Не допускается
          обработка персональных данных, несовместимая с целями сбора
          персональных данных.
        </S.Paragraph>
        <S.Paragraph>
          6.3. Не допускается объединение баз данных, содержащих персональные
          данные, обработка которых осуществляется в целях, несовместимых между
          собой.
        </S.Paragraph>
        <S.Paragraph>
          6.4. Обработке подлежат только персональные данные, которые отвечают
          целям их обработки.
        </S.Paragraph>
        <S.Paragraph>
          6.5. Содержание и объем обрабатываемых персональных данных
          соответствуют заявленным целям обработки. Не допускается избыточность
          обрабатываемых персональных данных по отношению к заявленным целям их
          обработки.
        </S.Paragraph>
        <S.Paragraph>
          6.6. При обработке персональных данных обеспечивается точность
          персональных данных, их достаточность, а в необходимых случаях и
          актуальность по отношению к целям обработки персональных данных.
          Администрация Сайта принимает необходимые меры и/или обеспечивает их
          принятие по удалению или уточнению неполных или неточных данных.
        </S.Paragraph>
        <S.Paragraph>
          6.7. Хранение персональных данных осуществляется в форме, позволяющей
          определить субъекта персональных данных, не дольше, чем этого требуют
          цели обработки персональных данных, если срок хранения персональных
          данных не установлен федеральным законом, договором, стороной
          которого, выгодоприобретателем или поручителем по которому является
          субъект персональных данных. Обрабатываемые персональные данные
          уничтожаются либо обезличиваются по достижении целей обработки или в
          случае утраты необходимости в достижении этих целей, если иное не
          предусмотрено федеральным законом.
        </S.Paragraph>
        <S.Heading>7. ОБЯЗАТЕЛЬСТВА СТОРОН</S.Heading>
        <S.Paragraph>7.1. Администрация Сайта обязана:</S.Paragraph>
        <S.Paragraph>
          7.1.1. Использовать полученную информацию исключительно для целей,
          указанных в п. 4 настоящей Политики конфиденциальности.
        </S.Paragraph>
        <S.Paragraph>
          7.1.2. Обеспечить хранение конфиденциальной информации в тайне, не
          разглашать без предварительного письменного разрешения Пользователя, а
          также не осуществлять продажу, обмен, опубликование, либо разглашение
          иными возможными способами переданных персональных данных
          Пользователя, за исключением п. 5.2. настоящей Политики
          Конфиденциальности.
        </S.Paragraph>
        <S.Paragraph>
          7.1.3. Принимать меры предосторожности для защиты конфиденциальности
          персональных данных Пользователя согласно порядку, обычно
          используемого для защиты такого рода информации в существующем деловом
          обороте.
        </S.Paragraph>
        <S.Heading>8. ОТВЕТСТВЕННОСТЬ СТОРОН</S.Heading>
        <S.Paragraph>
          8.1. Администрация Сайта, в случае неисполнения своих обязательств,
          несёт ответственность за убытки, понесённые Пользователем в связи с
          неправомерным использованием персональных данных, в соответствии с
          законодательством Российской Федерации, за исключением случаев,
          предусмотренных п. 5.2. и п. 8.2. настоящей Политики
          Конфиденциальности.
        </S.Paragraph>
        <S.Paragraph>
          8.2. В случае утраты или разглашения Конфиденциальной информации
          Администрация Сайта не несёт ответственности, если данная
          конфиденциальная информация:
        </S.Paragraph>
        <S.Paragraph>
          8.2.1. Стала публичным достоянием до её утраты или разглашения.
        </S.Paragraph>
        <S.Paragraph>
          8.2.2. Была получена от третьей стороны до момента её получения
          Администрацией Сайта.
        </S.Paragraph>
        <S.Paragraph>
          8.2.3. Была разглашена с согласия Пользователя.
        </S.Paragraph>
        <S.Paragraph>
          8.2.4. Была разглашена сторонними сайтами и сервисами, ссылки на
          использование которых присутствуют на Сайте, в случае использования
          таких ссылок и сервисов Пользователем.
        </S.Paragraph>
        <S.Paragraph>
          8.3. Пользователь самостоятельно несет ответственность за возможные
          последствия в случае представления недостоверных и/или неполных
          персональных данных.
        </S.Paragraph>
        <S.Heading>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</S.Heading>
        <S.Paragraph>
          9.1. Администрация Сайта вправе вносить изменения в настоящую Политику
          конфиденциальности без согласия и уведомления Пользователя.
        </S.Paragraph>
        <S.Paragraph>
          9.2. Изменения Политики конфиденциальности вступают в силу с момента
          их размещения на Сайте.
        </S.Paragraph>
        <S.Paragraph>
          9.3. Действующая Политика конфиденциальности размещена на Сайте по
          адресу {process.env.REACT_APP_URI}privacy-policy
        </S.Paragraph>
        <S.Paragraph>
          9.4. Пользователь может получить любые разъяснения по интересующим
          вопросам, касающимся обработки его персональных данных, обратившись к
          Администрации Сайта с помощью электронной почты support@brawlcases.com
        </S.Paragraph>
        <S.Heading>
          ПОЛИТИКА БЕЗОПАСНОСТИ ПРИ ОПЛАТЕ БАНКОВСКОЙ КАРТОЙ
        </S.Heading>
        <S.Paragraph>
          При оплате банковской картой обработка платежа (включая ввод номера
          карты) происходит на защищенной странице процессинговой системы,
          которая прошла международную сертификацию. Это значит, что
          конфиденциальные данные (реквизиты карты, регистрационные данные и
          др.) полностью защищены и никто, в том числе наш ресурс, не может
          получить персональные и банковские данные клиента. При работе с
          карточными данными применяется стандарт защиты информации,
          разработанный международными платёжными системами Visa и MasterCard —
          Payment Card Industry Data Security Standard (PCI DSS), что
          обеспечивает безопасную обработку реквизитов Банковской карты
          Держателя. Применяемая технология передачи данных гарантирует
          безопасность по сделкам с банковскими картами путем использования
          протоколов Secure Sockets Layer (SSL), Verified by Visa, Secure Code и
          закрытых банковских сетей, имеющих высшую степень защиты.
        </S.Paragraph>
      </S.Text>
    </>
  );
};
