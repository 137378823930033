import { createGlobalStyle } from "styled-components";
import { getTransition, scrollBar } from "./utilites";

export default createGlobalStyle`
:root {
  box-sizing: border-box;
  font-size: calc(0.8rem + 0.25vw);
  letter-spacing: 0.02rem;
  }

html {
  font-family: Montserrat, Blanco, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  color: ${({ theme }) => theme.colors.font};
  background-color: ${({ theme }) => theme.colors.bgColor};

  //плавный сколл
  scroll-behavior: smooth; 
}

body {
  overflow-x: hidden; 
  overflow-y: auto; 
}

${scrollBar}

nav ol,
nav ul {
  list-style: none;
}

hr {
  margin: 1rem 0;
  border: 0;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey37};
}

a {
 // text-decoration: none;
  color: ${({ theme }) => theme.colors.font};
  cursor: pointer;
  ${getTransition()};
}

a:focus,
a:hover {
 // color: ${({ theme }) => theme.colors.grey58};
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    // -webkit-box-shadow: 0 0 0 30px hsl(0, 0%, 92%) inset !important;
    // -webkit-text-fill-color: ${({ theme }) => theme.colors.revert};
}

//input[type="checkbox"] {
//  margin: 0 0.4em 0 0;
//}

input:focus-visible {
  //border-color: inherit;
  //outline: none;
}

// textarea:focus-visible{
//     outline: -webkit-focus-ring-color auto 0px;
// }
// `;
