/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation SendUserData($clientId: String!, $clientIp: String!) {\n    sentUserData(clientId: $clientId, clientIp: $clientIp)\n  }\n": types.SendUserDataDocument,
    "\n  mutation OpenCase($id: ID!, $multby: Int!) {\n    opencase(id: $id, multby: $multby) {\n      id\n      image\n      price\n      name\n      type\n    }\n  }\n": types.OpenCaseDocument,
    "\n  mutation GetVkBonus {\n    vk_bonus\n  }\n": types.GetVkBonusDocument,
    "\n  mutation GetYoutubeBonus {\n    youtube_bonus\n  }\n": types.GetYoutubeBonusDocument,
    "\n  mutation GetTelegramBonus {\n    telegram_bonus\n  }\n": types.GetTelegramBonusDocument,
    "\n  mutation GetDiscordBonus {\n    discord_bonus\n  }\n": types.GetDiscordBonusDocument,
    "\n  mutation SellAllItems {\n    sellAllItems\n  }\n": types.SellAllItemsDocument,
    "\n  mutation SellLastItems($multBy: Int) {\n    sellAllItems(multby: $multBy)\n  }\n": types.SellLastItemsDocument,
    "\n  mutation SetPromocode($code: String!) {\n    promocode(code: $code)\n  }\n": types.SetPromocodeDocument,
    "\n  mutation SetPromobonus($code: String!) {\n    promoBonus(code: $code)\n  }\n": types.SetPromobonusDocument,
    "\n  mutation AcceptReferral($code: String!) {\n    checkInviteCode(code: $code)\n  }\n": types.AcceptReferralDocument,
    "\n  mutation AcceptBonusCode($code: String!) {\n    ref_bonus(code: $code)\n  }\n": types.AcceptBonusCodeDocument,
    "\n  mutation ConvertRefBalance {\n    convertRefBalance\n  }\n": types.ConvertRefBalanceDocument,
    "\n  mutation SendOutputData($email: String!, $sum: Int!) {\n    outputEmailSum(email: $email, sum: $sum)\n  }\n": types.SendOutputDataDocument,
    "\n  mutation SendOutputTag($tag: String!, $sum: Int!) {\n    outputTagSum(tag: $tag, sum: $sum)\n  }\n": types.SendOutputTagDocument,
    "\n  mutation SendOutputCode($code: String!) {\n    outputCode(code: $code)\n  }\n": types.SendOutputCodeDocument,
    "\n  mutation CancelOrder {\n    cancelOutput\n  }\n": types.CancelOrderDocument,
    "\n  mutation MakePayout($card: String!, $sum: String!) {\n    makePayout(card: $card, sum: $sum)\n  }\n": types.MakePayoutDocument,
    "\n  mutation GetPrimoPrize {\n    getElPrimo\n  }\n": types.GetPrimoPrizeDocument,
    "\n  mutation SnowPrize($snow: Int!) {\n    getSnowPrize(snow: $snow)\n  }\n": types.SnowPrizeDocument,
    "\n  query IsLoggedIn @client {\n    isLoggedIn\n  }\n": types.IsLoggedInDocument,
    "\n  query GetAllCases {\n    getallCase {\n      ...CaseFragment\n    }\n  }\n": types.GetAllCasesDocument,
    "\n  query GetCase($id: ID!) {\n    case(id: $id) {\n      ...CaseFragment\n      items {\n        ...ItemFragment\n      }\n    }\n  }\n": types.GetCaseDocument,
    "\n  query GetSumToOpenCase {\n    getSumOpenedCases\n  }\n": types.GetSumToOpenCaseDocument,
    "\n  query GetAllItems {\n    getallItems {\n      ...ItemFragment\n    }\n  }\n": types.GetAllItemsDocument,
    "\n  query GetTotalPrice {\n    getAllPrice\n  }\n": types.GetTotalPriceDocument,
    "\n  fragment CaseFragment on Case {\n    id\n    price\n    name\n    type\n    image\n  }\n": types.CaseFragmentFragmentDoc,
    "\n  fragment ItemFragment on CardData {\n    id\n    price\n    name\n    type\n    image\n  }\n": types.ItemFragmentFragmentDoc,
    "\n  fragment UserFragment on User {\n    id\n    first_name\n    avatar\n  }\n": types.UserFragmentFragmentDoc,
    "\n  query GetMe {\n    getMe {\n      ...UserFragment\n      ref_balance\n      invite_code\n      balance\n      bonus_one {\n        vk\n        youtube\n        discord\n        tiktok\n        telegram\n        elPrimo\n      }\n      timer {\n        first_case\n        second_case\n        third_case\n        fourth_case\n      }\n      isOld\n      isDoubleProbTimer\n      bonus500\n      isPayment\n      orderStatus {\n        status\n      }\n      snow\n      pumpkin\n    }\n  }\n": types.GetMeDocument,
    "\n  query GetOrderStatus {\n    checkStatusOutput\n  }\n": types.GetOrderStatusDocument,
    "\n  query GetUser($id: ID!) {\n    getinfoUser(id: $id) {\n      ...UserFragment\n      best_drop {\n        ...ItemFragment\n      }\n      favorite_case {\n        ...CaseFragment\n      }\n    }\n  }\n": types.GetUserDocument,
    "\n  query GetUserItems($id: ID!, $cursor: Int) {\n    getItemsUser(id: $id, cursor: $cursor) {\n      cursor\n      hasNextPage\n      items {\n        ...ItemFragment\n      }\n    }\n  }\n": types.GetUserItemsDocument,
    "\n  query GetCollection($id: ID!) {\n    uniqueItems(id: $id)\n  }\n": types.GetCollectionDocument,
    "\n  query GetLastItems($flow: String!) {\n    latestItems(flow: $flow) {\n      date\n      item {\n        ...ItemFragment\n      }\n      case {\n        image\n      }\n      user {\n        id\n        first_name\n      }\n    }\n  }\n": types.GetLastItemsDocument,
    "\n  query GetOrderTimer {\n    getOrderTimer\n  }\n": types.GetOrderTimerDocument,
    "\n  query GetNews {\n    getNews {\n      id\n      date\n      title\n      description\n      link\n      linkName\n    }\n  }\n": types.GetNewsDocument,
    "\n  query GetStats {\n    statistic {\n      cases\n      gems\n      online\n      users\n    }\n  }\n": types.GetStatsDocument,
    "\n  query GetPromotion {\n    getPromotion\n  }\n": types.GetPromotionDocument,
    "\n  query GetMyRefs {\n    myReff {\n      firstname\n      get\n      in\n    }\n  }\n": types.GetMyRefsDocument,
    "\n  query GetEventSum {\n    getSumPaymentsPumpkin\n  }\n": types.GetEventSumDocument,
    "\n  subscription NewItems($flow: String!) {\n    caseItemWon(flow: $flow) {\n      date\n      type\n      name\n      price\n      image\n      first_name\n      userId\n      caseImage\n    }\n  }\n": types.NewItemsDocument,
    "\n  subscription OrderStatus {\n    sourceStatus {\n      status\n    }\n  }\n": types.OrderStatusDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SendUserData($clientId: String!, $clientIp: String!) {\n    sentUserData(clientId: $clientId, clientIp: $clientIp)\n  }\n"): (typeof documents)["\n  mutation SendUserData($clientId: String!, $clientIp: String!) {\n    sentUserData(clientId: $clientId, clientIp: $clientIp)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OpenCase($id: ID!, $multby: Int!) {\n    opencase(id: $id, multby: $multby) {\n      id\n      image\n      price\n      name\n      type\n    }\n  }\n"): (typeof documents)["\n  mutation OpenCase($id: ID!, $multby: Int!) {\n    opencase(id: $id, multby: $multby) {\n      id\n      image\n      price\n      name\n      type\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation GetVkBonus {\n    vk_bonus\n  }\n"): (typeof documents)["\n  mutation GetVkBonus {\n    vk_bonus\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation GetYoutubeBonus {\n    youtube_bonus\n  }\n"): (typeof documents)["\n  mutation GetYoutubeBonus {\n    youtube_bonus\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation GetTelegramBonus {\n    telegram_bonus\n  }\n"): (typeof documents)["\n  mutation GetTelegramBonus {\n    telegram_bonus\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation GetDiscordBonus {\n    discord_bonus\n  }\n"): (typeof documents)["\n  mutation GetDiscordBonus {\n    discord_bonus\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SellAllItems {\n    sellAllItems\n  }\n"): (typeof documents)["\n  mutation SellAllItems {\n    sellAllItems\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SellLastItems($multBy: Int) {\n    sellAllItems(multby: $multBy)\n  }\n"): (typeof documents)["\n  mutation SellLastItems($multBy: Int) {\n    sellAllItems(multby: $multBy)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetPromocode($code: String!) {\n    promocode(code: $code)\n  }\n"): (typeof documents)["\n  mutation SetPromocode($code: String!) {\n    promocode(code: $code)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetPromobonus($code: String!) {\n    promoBonus(code: $code)\n  }\n"): (typeof documents)["\n  mutation SetPromobonus($code: String!) {\n    promoBonus(code: $code)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AcceptReferral($code: String!) {\n    checkInviteCode(code: $code)\n  }\n"): (typeof documents)["\n  mutation AcceptReferral($code: String!) {\n    checkInviteCode(code: $code)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AcceptBonusCode($code: String!) {\n    ref_bonus(code: $code)\n  }\n"): (typeof documents)["\n  mutation AcceptBonusCode($code: String!) {\n    ref_bonus(code: $code)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ConvertRefBalance {\n    convertRefBalance\n  }\n"): (typeof documents)["\n  mutation ConvertRefBalance {\n    convertRefBalance\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SendOutputData($email: String!, $sum: Int!) {\n    outputEmailSum(email: $email, sum: $sum)\n  }\n"): (typeof documents)["\n  mutation SendOutputData($email: String!, $sum: Int!) {\n    outputEmailSum(email: $email, sum: $sum)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SendOutputTag($tag: String!, $sum: Int!) {\n    outputTagSum(tag: $tag, sum: $sum)\n  }\n"): (typeof documents)["\n  mutation SendOutputTag($tag: String!, $sum: Int!) {\n    outputTagSum(tag: $tag, sum: $sum)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SendOutputCode($code: String!) {\n    outputCode(code: $code)\n  }\n"): (typeof documents)["\n  mutation SendOutputCode($code: String!) {\n    outputCode(code: $code)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CancelOrder {\n    cancelOutput\n  }\n"): (typeof documents)["\n  mutation CancelOrder {\n    cancelOutput\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation MakePayout($card: String!, $sum: String!) {\n    makePayout(card: $card, sum: $sum)\n  }\n"): (typeof documents)["\n  mutation MakePayout($card: String!, $sum: String!) {\n    makePayout(card: $card, sum: $sum)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation GetPrimoPrize {\n    getElPrimo\n  }\n"): (typeof documents)["\n  mutation GetPrimoPrize {\n    getElPrimo\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SnowPrize($snow: Int!) {\n    getSnowPrize(snow: $snow)\n  }\n"): (typeof documents)["\n  mutation SnowPrize($snow: Int!) {\n    getSnowPrize(snow: $snow)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query IsLoggedIn @client {\n    isLoggedIn\n  }\n"): (typeof documents)["\n  query IsLoggedIn @client {\n    isLoggedIn\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAllCases {\n    getallCase {\n      ...CaseFragment\n    }\n  }\n"): (typeof documents)["\n  query GetAllCases {\n    getallCase {\n      ...CaseFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCase($id: ID!) {\n    case(id: $id) {\n      ...CaseFragment\n      items {\n        ...ItemFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCase($id: ID!) {\n    case(id: $id) {\n      ...CaseFragment\n      items {\n        ...ItemFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetSumToOpenCase {\n    getSumOpenedCases\n  }\n"): (typeof documents)["\n  query GetSumToOpenCase {\n    getSumOpenedCases\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAllItems {\n    getallItems {\n      ...ItemFragment\n    }\n  }\n"): (typeof documents)["\n  query GetAllItems {\n    getallItems {\n      ...ItemFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetTotalPrice {\n    getAllPrice\n  }\n"): (typeof documents)["\n  query GetTotalPrice {\n    getAllPrice\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CaseFragment on Case {\n    id\n    price\n    name\n    type\n    image\n  }\n"): (typeof documents)["\n  fragment CaseFragment on Case {\n    id\n    price\n    name\n    type\n    image\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ItemFragment on CardData {\n    id\n    price\n    name\n    type\n    image\n  }\n"): (typeof documents)["\n  fragment ItemFragment on CardData {\n    id\n    price\n    name\n    type\n    image\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UserFragment on User {\n    id\n    first_name\n    avatar\n  }\n"): (typeof documents)["\n  fragment UserFragment on User {\n    id\n    first_name\n    avatar\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMe {\n    getMe {\n      ...UserFragment\n      ref_balance\n      invite_code\n      balance\n      bonus_one {\n        vk\n        youtube\n        discord\n        tiktok\n        telegram\n        elPrimo\n      }\n      timer {\n        first_case\n        second_case\n        third_case\n        fourth_case\n      }\n      isOld\n      isDoubleProbTimer\n      bonus500\n      isPayment\n      orderStatus {\n        status\n      }\n      snow\n      pumpkin\n    }\n  }\n"): (typeof documents)["\n  query GetMe {\n    getMe {\n      ...UserFragment\n      ref_balance\n      invite_code\n      balance\n      bonus_one {\n        vk\n        youtube\n        discord\n        tiktok\n        telegram\n        elPrimo\n      }\n      timer {\n        first_case\n        second_case\n        third_case\n        fourth_case\n      }\n      isOld\n      isDoubleProbTimer\n      bonus500\n      isPayment\n      orderStatus {\n        status\n      }\n      snow\n      pumpkin\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetOrderStatus {\n    checkStatusOutput\n  }\n"): (typeof documents)["\n  query GetOrderStatus {\n    checkStatusOutput\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetUser($id: ID!) {\n    getinfoUser(id: $id) {\n      ...UserFragment\n      best_drop {\n        ...ItemFragment\n      }\n      favorite_case {\n        ...CaseFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUser($id: ID!) {\n    getinfoUser(id: $id) {\n      ...UserFragment\n      best_drop {\n        ...ItemFragment\n      }\n      favorite_case {\n        ...CaseFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetUserItems($id: ID!, $cursor: Int) {\n    getItemsUser(id: $id, cursor: $cursor) {\n      cursor\n      hasNextPage\n      items {\n        ...ItemFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUserItems($id: ID!, $cursor: Int) {\n    getItemsUser(id: $id, cursor: $cursor) {\n      cursor\n      hasNextPage\n      items {\n        ...ItemFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCollection($id: ID!) {\n    uniqueItems(id: $id)\n  }\n"): (typeof documents)["\n  query GetCollection($id: ID!) {\n    uniqueItems(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetLastItems($flow: String!) {\n    latestItems(flow: $flow) {\n      date\n      item {\n        ...ItemFragment\n      }\n      case {\n        image\n      }\n      user {\n        id\n        first_name\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetLastItems($flow: String!) {\n    latestItems(flow: $flow) {\n      date\n      item {\n        ...ItemFragment\n      }\n      case {\n        image\n      }\n      user {\n        id\n        first_name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetOrderTimer {\n    getOrderTimer\n  }\n"): (typeof documents)["\n  query GetOrderTimer {\n    getOrderTimer\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetNews {\n    getNews {\n      id\n      date\n      title\n      description\n      link\n      linkName\n    }\n  }\n"): (typeof documents)["\n  query GetNews {\n    getNews {\n      id\n      date\n      title\n      description\n      link\n      linkName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetStats {\n    statistic {\n      cases\n      gems\n      online\n      users\n    }\n  }\n"): (typeof documents)["\n  query GetStats {\n    statistic {\n      cases\n      gems\n      online\n      users\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPromotion {\n    getPromotion\n  }\n"): (typeof documents)["\n  query GetPromotion {\n    getPromotion\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMyRefs {\n    myReff {\n      firstname\n      get\n      in\n    }\n  }\n"): (typeof documents)["\n  query GetMyRefs {\n    myReff {\n      firstname\n      get\n      in\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetEventSum {\n    getSumPaymentsPumpkin\n  }\n"): (typeof documents)["\n  query GetEventSum {\n    getSumPaymentsPumpkin\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription NewItems($flow: String!) {\n    caseItemWon(flow: $flow) {\n      date\n      type\n      name\n      price\n      image\n      first_name\n      userId\n      caseImage\n    }\n  }\n"): (typeof documents)["\n  subscription NewItems($flow: String!) {\n    caseItemWon(flow: $flow) {\n      date\n      type\n      name\n      price\n      image\n      first_name\n      userId\n      caseImage\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription OrderStatus {\n    sourceStatus {\n      status\n    }\n  }\n"): (typeof documents)["\n  subscription OrderStatus {\n    sourceStatus {\n      status\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;