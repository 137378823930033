import * as S from "./Styles";
import { useQuery } from "@apollo/client";
import { GET_PROMOTION } from "../../../../graphql/Query";
import Countdown from "react-countdown";
import {
  CountdownDisplay,
  CountdownDisplayText
} from "../../../../Components/CountdownDisplay/CountdownDisplay";
import { BonusStar } from "../../../../Components/BonusStar/BonusStar";

export const Body = () => {
  const { data: promo } = useQuery(GET_PROMOTION);
  return (
    <S.Wrapper>
      <S.Container>
        Собери 3 бравл тыквы и забери золотого эль примо, либо 10 000р себе на
        карту
      </S.Container>
      <S.TimerDivider>
        {promo && promo.getPromotion && (
          <>
            <Countdown
              date={
                new Date(
                  new Date(promo.getPromotion).getTime() + 60 * 60 * 1000 * 3
                )
              }
              renderer={CountdownDisplayText}
            />
          </>
        )}
      </S.TimerDivider>
    </S.Wrapper>
  );
};
