import React from "react";
import ReactDOM from "react-dom/client";
import "./Styles/font/style.css";
import "./Styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import { baseTheme } from "./Styles/theme";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  split
} from "@apollo/client";
import { createClient } from "graphql-ws";
import { Cache, errorWSCountVar } from "./graphql/Cache";
import { IS_LOGGED_IN } from "./graphql/Query";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { HelmetProvider } from "react-helmet-async";
export const URI =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_URI_PROD
    : process.env.REACT_APP_URI;
const uri = `${URI}graphql/`;
const ws = `${
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_WS_PROD
    : process.env.REACT_APP_WS
}graphql/`;

const httpLink = createHttpLink({ uri, credentials: "include" });

let countWSRetry = 0;
const wsClient = createClient({
  url: ws,
  on: {
    connected: () => {
      countWSRetry = 0;
      errorWSCountVar(0);
      console.log("Connection successes.");
    },
    error: () => {
      countWSRetry += 1;
      errorWSCountVar(countWSRetry);
      console.log("Error count: ", countWSRetry);
    }
  },
  shouldRetry: () => true
  // connectionParams: () => {
  //   return {
  //     authorization:
  //       localStorage.getItem("token") || sessionStorage.getItem("token") || ""
  //   };
  // }
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: localStorage.getItem("token") || "",
      clientId: localStorage.getItem("clientId") || "",
      clientIp: localStorage.getItem("clientIp") || ""
    }
  };
});

const httpWithHeaders = authLink.concat(httpLink);

const wsLink = () => new GraphQLWsLink(wsClient);
const requestLink = ({
  httpLinkQuery,
  subscriptionLink
}: {
  httpLinkQuery: ApolloLink;
  subscriptionLink: GraphQLWsLink;
}) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    subscriptionLink,
    httpLinkQuery
  );

const client = new ApolloClient({
  link: requestLink({
    httpLinkQuery: httpWithHeaders,
    subscriptionLink: wsLink()
  }),
  cache: Cache
});

client.cache.writeQuery({
  query: IS_LOGGED_IN,
  data: {
    isLoggedIn: localStorage.getItem("isAuth") === "true"
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <StyleSheetManager enableVendorPrefixes={true}>
      <ThemeProvider theme={baseTheme}>
        <ErrorBoundary>
          <ApolloProvider client={client}>
            <HelmetProvider>
              <Router>
                <App />
              </Router>
            </HelmetProvider>
          </ApolloProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </StyleSheetManager>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
